import React from "react";
import { Alert } from "react-bootstrap";
import { Dialog } from "@material-ui/core";

const Message = (props) => {
  if (!props.fixed) {
    setTimeout(function() {
      props.setMsg({});
    }, 5000);
  }

  const setShow = () => {
    props.setMsg({});
  };

  return (
    <Dialog title="" modal="true" open={true} maxWidth="xs" fullWidth>
      <Alert
        style={{ marginBottom: "0px" }}
        variant={props.status === 200 ? "success" : "danger"}
        onClose={() => setShow()}
        dismissible
      >
        <p style={{ fontWeight: 500 }}>{props.msg}</p>
      </Alert>
    </Dialog>
  );
};

export default Message;
