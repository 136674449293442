import React, { useEffect } from "react";
import $ from "jquery"; // Import jQuery
import htmlContent from "./indexhtml.js";
import animateMin from "../website/css/animate.min.css";
import animationsMin from "../website/css/animations.min.css";
import bootstrapMin from "../website/css/bootstrap.min.css";
// import carousel from "../website/css/carousel.css"
import frontendLite from "../website/css/frontend-lite.min.css";
import hoverMin from "../website/css/hover-min.css";
import index from "../website/css/index.css";
// import lineaMin from "../website/css/linea.min.css"
import loadersMin from "../website/css/loaders.min.css";
import meanMenu from "../website/css/meanmenu.min.css";
import niceSelectMin from "../website/css/nice-select.min.css";
import pageNavi from "../website/css/pagenavi-css.css";
import post4354 from "../website/css/post-4354.css";
import post4586 from "../website/css/post-4586.css";
import post4589 from "../website/css/post-4589.css";
import responsive from "../website/css/responsive.css";
import simpleBanner from "../website/css/simple-banner.css";
import style from "../website/css/style.css";
import styleMin from "../website/css/style.min.css";
import style1 from "../website/css/style1.css";
// import stylesCss from "../website/css/styles.css"
// import themeifyIcons from "../website/css/themify-icons.min.css"

const replacements = [
  { placeholder: "logoImage", path: require("../website/Images/logo.png") },
  {
    placeholder: "connection4",
    path: require("../website/Images/connection4.png"),
  },
  {
    placeholder: "croppedWebpnetresizeimage32x32",
    path: require("../website/Images/cropped-Webp.net-resizeimage-32x32.png"),
  },
  {
    placeholder: "croppedWebpnetresizeimage180x180",
    path: require("../website/Images/cropped-Webp.net-resizeimage-180x180.png"),
  },
  {
    placeholder: "croppedWebpnetresizeimage192x192",
    path: require("../website/Images/cropped-Webp.net-resizeimage-192x192.png"),
  },
  {
    placeholder: "croppedWebpnetresizeimage270x270",
    path: require("../website/Images/cropped-Webp.net-resizeimage-270x270.png"),
  },
  { placeholder: "group18", path: require("../website/Images/Group-18.png") },
  { placeholder: "group68", path: require("../website/Images/Group-68.png") },
  {
    placeholder: "LendCRMLogoscaled",
    path: require("../website/Images/LendCRM_Logo-scaled.jpg"),
  },
  {
    placeholder: "LendCRMLogo",
    path: require("../website/Images/LendCRM_Logo.png"),
  },
  {
    placeholder: "management1",
    path: require("../website/Images/management1.png"),
  },
  {
    placeholder: "maskgroup",
    path: require("../website/Images/Mask-group.png"),
  },
  {
    placeholder: "moneybag2",
    path: require("../website/Images/money-bag2.png"),
  },
  { placeholder: "rating3", path: require("../website/Images/rating3.png") },
  { placeholder: "style", path: style },
  { placeholder: "simpleBanner", path: simpleBanner },
  { placeholder: "meanMenu", path: meanMenu },
  { placeholder: "animateMin", path: animateMin },
  { placeholder: "animationsMin", path: animationsMin },
  { placeholder: "bootstrapMin", path: bootstrapMin },
  // { placeholder: 'carousel', path: carousel },
  { placeholder: "frontendLite", path: frontendLite },
  { placeholder: "hoverMin", path: hoverMin },
  { placeholder: "index", path: index },
  // { placeholder: 'lineaMin', path: lineaMin },
  { placeholder: "loadersMin", path: loadersMin },
  { placeholder: "niceSelectMin", path: niceSelectMin },
  { placeholder: "pageNavi", path: pageNavi },
  { placeholder: "post4354", path: post4354 },
  { placeholder: "post4586", path: post4586 },
  { placeholder: "post4589", path: post4589 },
  { placeholder: "responsive", path: responsive },
  { placeholder: "styleMin", path: styleMin },
  { placeholder: "style1", path: style1 },
];

const LandingPage = () => {
  useEffect(() => {
    $(document).ready(function() {
      $.getJSON("https://api.ipify.org?format=json", function(data) {
        $("#ipAddress").val(data.ip);
      });
    });

    // Code for form submission
    $("#myForm").submit(function(event) {
      event.preventDefault();
      var form = document.getElementById("myForm");
      var formData = new FormData(form);

      $.ajax({
        url: process.env.REACT_APP_BACKEND_API_URL + "public/schedule_demo",
        method: "POST",
        data: JSON.stringify(Object.fromEntries(formData)),
        processData: false,
        contentType: "application/json",
        success: function(response) {
          console.log("Success");
          var redirectUrl = "https://lendcrm.com/";
          window.location.href = redirectUrl;
        },
        error: function(xhr, status, error) {
          alert("Something went wrong! Please submit again");
          console.error(error);
        },
      });
    });
  }, []);

  const replacedHtmlContent = replacements.reduce(
    (content, { placeholder, path }) => {
      return content.replace(placeholder, path.default || path); // Handle default export for require()
    },
    htmlContent
  );

  return <div dangerouslySetInnerHTML={{ __html: replacedHtmlContent }} />;
};

export default LandingPage;
