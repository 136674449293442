import React from "react";

const Sort = (props) => {
  const onClickHandler = (data) => {
    props.actions.sort(data);
    props.actions.load(true);
  };

  return (
    <span style={{ textAlign: "right" }} className="ml-3">
      <i
        title="Descending"
        className={
          "fa fa-arrow-up mr-1 " +
          (props.data.field === props.field && props.data.order === -1
            ? "text-danger"
            : "text-primary")
        }
        onClick={() => onClickHandler({ field: props.field, order: -1 })}
      />
      <i
        title="Ascending"
        className={
          "fa fa-arrow-down mr-1 " +
          (props.data.field === props.field && props.data.order === 1
            ? "text-danger"
            : "text-primary")
        }
        onClick={() => onClickHandler({ field: props.field, order: 1 })}
      />
    </span>
  );
};

export default Sort;
