import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";

import { backendApiUrl } from "../../utils";
import { removeCSSClass } from "../../../_metronic/utils/utils";
import * as builder from "../../../_metronic/ducks/builder";
import KTMenu from "../../../_metronic/_assets/js/menu";
import KTUtil from "../../../_metronic/_assets/js/util";

import ToolsSection from "./ToolsSection";
import ToolsItemSeparator from "./ToolsItemSeparator";
import ToolsItem from "./ToolsItem";

class BuilderTools extends React.Component {
  asideMenuRef = React.createRef();
  insideTm;
  outsideTm;
  state = this.props.menuConfig;

  componentDidMount() {
    this.initMenu(); // By ID
    const ktMenu = new KTMenu(this.asideMenuRef.current, options); // eslint-disable-line
    const options = this.setupMenuOptions();

    let data = {};
    if (window.location.pathname === "/builders/webforms/form") {
      data = {
        module: this.props.webformBuilderConfig.module,
        products: this.props.webformBuilderConfig.products,
        programs: this.props.webformBuilderConfig.programs,
        builder: "webforms",
      };
    }

    if (window.location.pathname === "/builders/opportunity/form") {
      data = {
        products: this.props.opportunityBuilderConfig.products,
        programs: this.props.opportunityBuilderConfig.programs,
      };
    }

    axios
      .post(backendApiUrl("forms/library"), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.forms.length) {
          var libraries = [];
          var moduleFields = [];

          const generateLibraries = (configs, formId) => {
            configs &&
              configs.forEach((config) => {
                if (config.type === "section") {
                  let temp = {
                    title: config.title,
                    isDrag: true,
                    root: true,
                    element: config,
                  };
                  if (config.childs.length) {
                    moduleFields = [];
                    temp.submenu = getModuleFields(config.childs, formId);
                  }

                  if (libraries.length) {
                    libraries = [...libraries, temp];
                  } else {
                    libraries = [temp];
                  }
                } else {
                  generateLibraries(config.childs, formId);
                }
              });
          };

          const getModuleFields = (configs, formId) => {
            configs.forEach((config) => {
              if (
                config.type !== "container" &&
                config.type !== "grid" &&
                config.type !== "item" &&
                config.type !== "tab"
              ) {
                // config.id = formId + "---" + config.id;

                let temp = {
                  title: config.title,
                  isDrag: true,
                  element: config,
                };

                if (moduleFields.length) {
                  moduleFields = [...moduleFields, temp];
                } else {
                  moduleFields = [temp];
                }
              } else {
                if (config.childs.length)
                  getModuleFields(config.childs, formId);
              }
            });
            return moduleFields;
          };

          let asideItems = [];
          if (window.location.pathname === "/builders/webforms/form") {
            asideItems = [
              this.state.aside.items[0],
              this.state.aside.items[1],
              { section: "libraries" },
            ];
          } else {
            asideItems = [...this.state.aside.items, { section: "libraries" }];
          }

          let formId = "";
          switch (window.location.pathname) {
            case "/builders/modules/form":
              formId = this.props.moduleBuilderConfig._id;
              break;
            case "/builders/opportunity/form":
              formId = this.props.opportunityBuilderConfig._id;
              break;
            case "/builders/webforms/form":
              formId = this.props.webformBuilderConfig._id;
              break;
            case "/builders/libraries/form":
              formId = this.props.libraryBuilderConfig._id;
              break;
            default:
              break;
          }

          let history = [];
          let std_libraries = [];
          res.data.forms.forEach((form) => {
            if (
              form.content === null ||
              (form.content.length > 0 && form.content[0] === null)
            ) {
              form.content = [];
            }
            // string to json
            if (form.content && typeof form.content[0] === "string") {
              form.content = JSON.parse(form.content[0]);
            }
            // string to json
            // if (form.history && typeof form.history[0] === "string") {
            //   form.history = JSON.parse(form.history[0]);
            // }
            form.history = [];
            libraries = [];
            moduleFields = [];

            // Form History
            if (form.history && form._id === formId) {
              form.history.forEach((f) => {
                f.from = "deactive";
                if (!!f.title) {
                  history = [
                    ...history,
                    {
                      title: f.title + " (" + f.type + ")",
                      isDrag: true,
                      root: true,
                      element: f,
                    },
                  ];
                }
              });
            }

            if (!(form.title === "Groups")) {
              generateLibraries(form.content, form._id);
              if (form.type === "library") {
                std_libraries = [
                  ...std_libraries,
                  {
                    title: !!form.custom_title ? form.custom_title : form.title,
                    root: true,
                    submenu: libraries,
                  },
                ];
              } else {
                let title = !!form.custom_title
                  ? form.custom_title
                  : form.title;
                if (form.type === "opportunity") {
                  !!form.custom_title
                    ? (title =
                        `${form.custom_title} (` +
                        (form.products[0] ? form.products[0].title : "") +
                        " - " +
                        (form.programs[0] ? form.programs[0].title : "") +
                        ")")
                    : (title =
                        "Opportunity (" +
                        (form.products[0] ? form.products[0].title : "") +
                        " - " +
                        (form.programs[0] ? form.programs[0].title : "") +
                        ")");
                }

                asideItems = [
                  ...asideItems,
                  {
                    title,
                    root: true,
                    submenu: libraries,
                  },
                ];
              }
            }
          });

          if (window.location.pathname !== "/builders/webforms/form") {
            let aut =
              window.location.pathname === "/builders/opportunity/form"
                ? {
                    title: "Auto Increment",
                    isDrag: true,
                    root: true,
                    element: {
                      id: "auto_increment",
                      title: "Auto Increment",
                      type: "auto_increment",
                    },
                  }
                : {};

            asideItems = [
              ...asideItems,
              { section: "Admin Info" },
              {
                title: "Status",
                isDrag: true,
                root: true,
                element: { id: "status", title: "Status", type: "status" },
              },
              aut,
              ...std_libraries,
            ];
          }
          asideItems = [
            ...asideItems,
            {
              title: "Source",
              isDrag: true,
              root: true,
              element: { id: "source", title: "Source", type: "source" },
            },
            ...std_libraries,
          ];

          // Deactive elements
          if (history.length > 0) {
            asideItems = [
              ...asideItems,
              { section: "Deactivated Elements" },
              ...history,
            ];
          }

          this.setState({
            aside: { items: asideItems },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    let asideItems = [...this.state.aside.items];

    asideItems[1].submenu = [
      ...asideItems[1].submenu,
      {
        title: "Tab",
        icon: "flaticon-folder-4",
        isDrag: true,
        type: "tab",
      },
    ];
    this.setState({
      aside: { items: asideItems },
    });
  }

  initMenu() {
    const { config } = this.props;
    if (
      objectPath.get(config, "aside.menu.dropdown") !== "true" &&
      objectPath.get(config, "aside.self.fixed")
    ) {
      this.asideMenuRef.current.setAttribute("data-ktmenu-scroll", "1");
    }

    if (objectPath.get(config, "aside.menu.dropdown") === "true") {
      this.asideMenuRef.current.setAttribute("data-ktmenu-dropdown", "1");
      this.asideMenuRef.current.setAttribute(
        "data-ktmenu-dropdown-timeout",
        objectPath.get(config, "aside.menu.submenu.dropdown.hover-timeout")
      );
    }
  }

  setupMenuOptions() {
    let menuOptions = {
      // vertical scroll
      scroll: null,
      // submenu setup
      submenu: {
        desktop: {
          // by default the menu mode set to accordion in desktop mode
          default: "dropdown",
        },
        tablet: "accordion", // menu set to accordion in tablet mode
        mobile: "accordion", // menu set to accordion in mobile mode
      },
      // accordion setup
      accordion: {
        expandAll: false, // allow having multiple expanded accordions in the menu
      },
    };

    // init aside menu
    let menuDesktopMode = "accordion";
    const dataKtmenuDropdown = this.asideMenuRef.current.getAttribute(
      "data-ktmenu-dropdown"
    );
    if (dataKtmenuDropdown === "1") {
      menuDesktopMode = "dropdown";
    }

    if (typeof objectPath.get(menuOptions, "submenu.desktop") === "object") {
      menuOptions.submenu.desktop.default = menuDesktopMode;
    }

    return menuOptions;
  }

  get currentUrl() {
    return this.props.location.pathname.split(/[?#]/)[0];
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseEnter = (e) => {
    // check if the left aside menu is fixed
    if (document.body.classList.contains("kt-aside--fixed")) {
      if (this.outsideTm) {
        clearTimeout(this.outsideTm);
        this.outsideTm = null;
      }

      this.insideTm = setTimeout(() => {
        // if the left aside menu is minimized
        if (
          document.body.classList.contains("kt-aside--minimize") &&
          // eslint-disable-next-line no-undef
          KTUtil.isInResponsiveRange("desktop")
        ) {
          removeCSSClass(document.body, "kt-aside--minimize");
          document.body.classList.add("kt-aside--minimize-hover");
        }
      }, 50);
    }
  };

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseLeave = (e) => {
    if (document.body.classList.contains("kt-aside--fixed")) {
      if (this.insideTm) {
        clearTimeout(this.insideTm);
        this.insideTm = null;
      }

      this.outsideTm = setTimeout(() => {
        // if the left aside menu is expand
        /* eslint-disable-next-line  */
        const kUtilIsResponsiveRange = KTUtil.isInResponsiveRange("desktop");
        const hasMinimizeHover = document.body.classList.contains(
          "kt-aside--minimize-hover"
        );
        if (
          hasMinimizeHover &&
          // eslint-disable-next-line no-undef
          kUtilIsResponsiveRange
        ) {
          // hide back the left aside menu
          removeCSSClass(document.body, "kt-aside--minimize-hover");
          document.body.classList.add("kt-aside--minimize");
        }
      }, 100);
    }
  };

  render() {
    const {
      ulClasses,
      layoutConfig,
      asideMenuAttr,
      asideClassesFromConfig,
      location: { pathname },
    } = this.props;

    return (
      <div
        id="kt_aside"
        className={`kt-aside kt-aside-light kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop`}
      >
        <div
          id="kt_aside_menu_wrapper"
          className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
        >
          <PerfectScrollbar
            options={{ wheelSpeed: 2, wheelPropagation: false }}
            style={{ height: "100%", position: "fixed", width: "265px" }}
          >
            <div
              id="kt_aside_menu"
              // style={{ height: "100%" }}
              ref={this.asideMenuRef}
              onMouseEnter={this.mouseEnter}
              onMouseLeave={this.mouseLeave}
              data-ktmenu-vertical="1"
              className={clsx("kt-aside-menu", asideClassesFromConfig)}
              {...asideMenuAttr}
            >
              <br />
              <ul className={clsx("kt-menu__nav", ulClasses)}>
                {this.state.aside.items.map((child, index) => (
                  <React.Fragment key={`menuList${index}`}>
                    {child.section && <ToolsSection item={child} />}
                    {child.separator && <ToolsItemSeparator item={child} />}
                    {child.title && (
                      <ToolsItem
                        item={child}
                        currentUrl={pathname}
                        layoutConfig={layoutConfig}
                      />
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  menuConfig: store.builder.menuConfig,
  layoutConfig: store.builder.layoutConfig,
  headerLogo: builder.selectors.getLogo(store),
  asideMenuAttr: builder.selectors.getAttributes(store, { path: "aside_menu" }),
  disableAsideSelfDisplay:
    builder.selectors.getConfig(store, "aside.self.display") === false,

  ulClasses: builder.selectors.getClasses(store, {
    path: "aside_menu_nav",
    toString: true,
  }),

  asideClassesFromConfig: builder.selectors.getClasses(store, {
    path: "aside_menu",
    toString: true,
  }),

  moduleBuilderConfig: store.builder.moduleBuilderConfig,
  opportunityBuilderConfig: store.builder.opportunityBuilderConfig,
  webformBuilderConfig: store.builder.webformBuilderConfig,
  libraryBuilderConfig: store.builder.libraryBuilderConfig,
});

export default withRouter(connect(mapStateToProps)(BuilderTools));
