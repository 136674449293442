import React from "react";
import FormGroup from "./utils/FormGroup";
import Select from "react-select";

const MultiSelect = (props) => {
  let options = [];
  if (props.element.options) {
    props.element.options.forEach((option) => {
      option.value = option.id;
      option.label = option.text;
      options = [...options, option];
    });
  } else {
    options = [
      { value: 1, label: "Option 1" },
      { value: 2, label: "Option 2" },
    ];
  }
  return (
    <FormGroup element={props.element} module={props.module}>
      {props.module.permissions.update ? (
        <Select
          className={"kt-width-full" + props.element.class}
          id={props.element.id}
          options={options}
          value={props.element.value ? props.element.value : []}
          onChange={(e) => props.events.change(e, props.element.id)}
          isMulti
          isDisabled={props.element.readonly ? true : false}
          placeholder="Please select"
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
        />
      ) : (
        <h6 className="text-primary">
          {props.element.value &&
            props.element.value.map((v, i) =>
              i === 0 ? v.label : ", " + v.label
            )}
        </h6>
      )}
    </FormGroup>
  );
};

export default MultiSelect;
