import React from "react";
import FormGroup from "./utils/FormGroup";
import InputMask from "react-input-mask";

const Zip = (props) => {
  return (
    <FormGroup element={props.element} module={props.module}>
      {props.module.permissions.update ? (
        <InputMask
          mask="99999"
          type="text"
          id={props.element.id}
          onChange={props.events.change}
          disabled={props.element.readonly ? true : false}
          value={props.element.value}
          className={"form-control " + props.element.class}
        />
      ) : (
        // <input
        //   placeholder={props.element.placeholder || props.element.title}
        //   type="text"
        //   maxLength="5"
        //   id={props.element.id}
        //   onChange={props.events.change}
        //   disabled={props.element.readonly ? true : false}
        //   value={props.element.value}
        //   className={"form-control " + props.element.class}
        // />
        <h6 className="text-primary">{props.element.value}</h6>
      )}
    </FormGroup>
  );
};

export default Zip;
