import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import CreatableSelect from "react-select/creatable";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import { connect } from "react-redux";
import Message from "../../utils/Message";
import { Spinner } from "react-bootstrap";

import { backendApiUrl } from "../../utils";
import Docs from "./Docs";

const Compose = (props) => {
  const [requiredFields, setRequiredFields] = useState([]);
  const [info, setInfo] = useState({
    load: true,
    msg: { show: false },
    showCC: false,
    showBCC: false,
    users: [],
    files: [],
    templates: [],
    data: {
      recipients: props.info.recipients ? props.info.recipients : [],
      subject: "",
      template: null,
      docs: [],
      contracts: [],
      files: [],
    },
  });

  const selectStyle = {
    control: (provided, state) => ({
      ...provided,
      borderTop: "0px",
      borderLeft: "0px",
      borderRight: "0px",
    }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
  };

  const ccAndBccClickHandler = (type) => {
    let tInfo = { ...info };
    if (type === "CC") {
      tInfo.showCC = true;
    } else {
      tInfo.showBCC = true;
    }
    setInfo(tInfo);
  };

  const recipientOnChangeHandler = (recipients) => {
    let tInfo = { ...info };
    tInfo.data.recipients = recipients;
    setInfo(tInfo);
  };

  const ccOnChangeHandler = (recipients) => {
    let tInfo = { ...info };
    tInfo.data.cc = recipients;
    setInfo(tInfo);
  };

  const bccOnChangeHandler = (recipients) => {
    let tInfo = { ...info };
    tInfo.data.bcc = recipients;
    setInfo(tInfo);
  };

  const templateOnChangeHandler = (template) => {
    let tInfo = { ...info };
    tInfo.data.subject = template.subject;

    if (template.content.search("{{") !== -1 && !!props.info.owner) {
      let data = {
        module: props.module,
        owner: props.info.owner,
        body: template.content,
        from: props.info.from,
      };

      axios
        .post(backendApiUrl("emails/populate_tags"), data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          tInfo.data.body = res.data;
          tInfo.data.template = template;
          setInfo(tInfo);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      tInfo.data.template = template;
      tInfo.data.body = template.content;
      setInfo(tInfo);
    }
  };

  const onChangeHandler = (event) => {
    let tInfo = { ...info };
    tInfo.data[event.target.id] = event.target.value;
    setInfo(tInfo);
  };

  const handleEditorChange = (content, editor) => {
    let tInfo = { ...info };
    tInfo.data.body = content;
    setInfo(tInfo);
  };

  const setActionMsg = (msg) => {
    let tInfo = { ...info };
    tInfo.msg = msg;
    setInfo(tInfo);
  };

  const getCheckedAttachment = (classn) => {
    let rs = [];
    var checkboxes = document.getElementsByClassName(classn);
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i] && checkboxes[i].checked) {
        let obj = checkboxes[i].value.split(" | ");
        rs = [...rs, { _id: obj[0], title: obj[1] }];
      }
    }
    return rs;
  };

  const sendOnClickHandler = (event, draft) => {
    event.persist();
    let tInfo = { ...info };

    if (info.data.subject === "") {
      setRequiredFields(["subject"]);
      return true;
    }

    let data = { ...info.data };
    data.module = props.module;
    if (data && data.module === "Leads") {
      data.from = "Leads";
    } else {
      data.from = props.info.from;
    }

    let docs = getCheckedAttachment("attachment_docs");
    if (docs && docs.length > 0) data.docs = docs;
    let contracts = getCheckedAttachment("attachment_contracts");
    if (contracts && contracts.length > 0) data.contracts = contracts;

    if (!!tInfo.email_from) data.email_from = tInfo.email_from;
    if (!!props.info.owner) data.owner = props.info.owner;
    if (props.info.Ids) data.Ids = props.info.Ids; // Mass Actions - Selected Ids
    if (props.filters) data.filters = props.filters; // Mass Actions - Filter results
    if (draft === true) data.draft = true;
    if (data.template) data.template = data.template.value;

    axios
      .post(props.info.url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setActionMsg({
          status: 200,
          msg: "Email sent successfully.",
          show: true,
        });

        setTimeout(() => {
          if (props.actions.setLoad) props.actions.setLoad(true);
          if (props.actions.setActionInfo) props.actions.setActionInfo({});
        }, 3000);
      })
      .catch((err) => {
        setActionMsg({
          status: 500,
          msg: "Something went wrong, unable to sent email.",
          show: true,
        });
      });
  };

  useEffect(() => {
    if (info.load) {
      let tInfo = { ...info };
      axios
        .post(
          backendApiUrl("users/recipients"),
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let d = { ...res.data };

          let users = [];
          let u = [];
          users = [
            {
              label: "Company",
              options: [
                {
                  label: d.company.name + " (" + d.company.email + ")",
                  value: d.company._id,
                  type: "Company",
                  email: d.company.email,
                },
              ],
            },
          ];

          d.workforces.forEach((w) => {
            let t = {};
            if (w.info && w.info.first_name) {
              t.label =
                w.info.first_name +
                " " +
                w.info.last_name +
                " (" +
                w.info.email +
                ")";
              t.email = w.info.email;
            } else {
              t.label = "workforce - " + w._id;
            }
            t.value = w._id;
            t.type = "Workforces";
            u = [...u, t];
          });
          users = [...users, { label: "Workforces", options: u }];

          let uc = [];
          d.contacts.forEach((c) => {
            let t = {};
            if (c.info && c.info.first_name) {
              t.label =
                c.info.first_name +
                " " +
                c.info.last_name +
                " (" +
                c.info.email +
                ")";
              t.email = c.info.email;
            } else {
              t.label = "contact";
            }

            t.value = c._id;
            t.type = "Contacts";
            uc = [...uc, t];
          });
          users = [...users, { label: "Contacts", options: uc }];

          let up = [];
          d.prospects.forEach((p) => {
            let t = {};
            if (p.info && p.info.first_name) {
              t.label =
                p.info.first_name +
                " " +
                p.info.last_name +
                " (" +
                p.info.email +
                ")";
              t.email = p.info.email;
            } else {
              t.label = "Prospect";
            }

            t.value = p._id;
            t.type = "prospects";
            up = [...up, t];
          });
          users = [...users, { label: "Prospects", options: up }];

          let templates = [];
          d.templates.forEach((t) => {
            templates = [
              ...templates,
              {
                label: t.title,
                value: t._id,
                content:
                  t.content && t.content.html ? t.content.html : t.content,
                subject: t.subject,
              },
            ];
          });

          tInfo.templates = templates;
          tInfo.users = users;
          tInfo.load = false;

          let email_whitelabeling = [];
          d.company.email_whitelabeling &&
            d.company.email_whitelabeling.forEach((ce) => {
              ce.type &&
                ce.type.forEach((ct) => {
                  if (!!ct.label) {
                    email_whitelabeling = [
                      ...email_whitelabeling,
                      { value: ce.email, label: ct.label },
                    ];
                  }
                });
            });
          tInfo.email_whitelabeling = email_whitelabeling;
          setInfo(tInfo);
        })
        .catch((err) => {
          tInfo.load = false;
          setInfo(tInfo);
        });
    }
  }, [info, info.load, info.openPopup]);

  const setFromEmail = (e) => {
    let ti = { ...info };
    ti.email_from = e.target.value;
    setInfo(ti);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
        let tinfo = { ...info };
        tinfo.data.files = [
          ...tinfo.data.files,
          {
            encode: fileReader.result,
            type: file.type,
            name: file.name,
          },
        ];
        setInfo(tinfo);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileRead = (efiles) => {
    efiles.forEach((file) => {
      convertBase64(file);
    });
  };

  const deleteFile = (file) => {
    let files = [];
    info.data.files.forEach((f) => {
      if (f.name !== file.name) {
        files = [...files, f];
      }
    });
    let tInfo = { ...info };
    tInfo.data.files = files;
    setInfo(tInfo);
  };

  return (
    <Modal
      show={true}
      size="xl"
      onHide={() => props.actions.setActionInfo({})}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Compose Email{" "}
          {!!props.info.from && !props.info.from === "(undefined)"
            ? " - " + props.info.from
            : ""}
        </Modal.Title>
      </Modal.Header>

      {info.load ? (
        <div style={{ textAlign: "center", margin: "10px" }}>
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <Fragment>
          <Modal.Body>
            <p className="text-danger">
              If the logged-in user has verified their email, our system will
              use the logged-in user's email to send all types of email
              triggers.
            </p>
            {info.msg.show && (
              <Message
                status={info.msg.status}
                setMsg={setActionMsg}
                msg={info.msg.msg}
              />
            )}
            <div
              style={{
                maxHeight: "600px",
                overflowX: "hidden",
                overflowY: "scroll",
              }}
            >
              {info.email_whitelabeling && info.email_whitelabeling.length > 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <select
                      className="form-control"
                      style={{
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                      }}
                      onChange={setFromEmail}
                      value={!!info.email_from ? info.email_from : ""}
                    >
                      <option value="" disabled>
                        Select From (optional)
                      </option>
                      {info.email_whitelabeling.map((ew, i) => (
                        <option value={ew.value} key={i}>
                          {ew.label}
                        </option>
                      ))}
                    </select>
                  </Grid>
                  <Grid item xs={4}>
                    <input
                      type="text"
                      className="form-control"
                      name="from"
                      style={{
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                      }}
                      placeholder="Please select from (optional)"
                      readOnly={true}
                      value={!!info.email_from ? info.email_from : ""}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={2}>
                <Grid item xs={11}>
                  <CreatableSelect
                    isMulti
                    id="recipients"
                    onChange={recipientOnChangeHandler}
                    placeholder="Recipients"
                    isDisabled={
                      props.info.from === "Send Mail To Selected" ||
                      props.info.from === "Send Mail To All"
                        ? true
                        : false
                    }
                    className="kt-width-full"
                    options={info.users}
                    value={info.data.recipients}
                    styles={selectStyle}
                  />
                </Grid>
                <Grid item xs={1}>
                  <br />
                  <span
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => ccAndBccClickHandler("CC")}
                  >
                    CC
                  </span>
                  <span className="text-primary">&nbsp;|&nbsp;</span>
                  <span
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => ccAndBccClickHandler("BCC")}
                  >
                    BCC
                  </span>
                </Grid>

                {info.showCC && (
                  <Grid item xs={11}>
                    <CreatableSelect
                      isMulti
                      onChange={ccOnChangeHandler}
                      id="cc"
                      value={info.data.cc}
                      placeholder="CC"
                      options={info.users}
                      styles={selectStyle}
                      className="kt-width-full"
                    />
                  </Grid>
                )}

                {info.showBCC && (
                  <Grid item xs={11}>
                    <CreatableSelect
                      isMulti
                      onChange={bccOnChangeHandler}
                      options={info.users}
                      id="bcc"
                      value={info.data.bcc}
                      placeholder="BCC"
                      styles={selectStyle}
                      className="kt-width-full"
                    />
                  </Grid>
                )}

                {info.templates.length > 0 && props.module !== "Profile" && (
                  <Grid item xs={11}>
                    <Select
                      className="kt-width-full"
                      id="template"
                      styles={selectStyle}
                      options={info.templates}
                      value={info.data.template}
                      onChange={templateOnChangeHandler}
                      placeholder="Please select template (optional)"
                    />
                  </Grid>
                )}

                <Grid item xs={11}>
                  <input
                    type="text"
                    id="subject"
                    value={info.data.subject}
                    onChange={onChangeHandler}
                    className={
                      "form-control" +
                      (requiredFields.includes("subject") ? " is-invalid" : "")
                    }
                    style={{
                      borderTop: "0px",
                      borderLeft: "0px",
                      borderRight: "0px",
                    }}
                    placeholder="Subject"
                  />
                  {requiredFields.includes("subject") && (
                    <div className="invalid-feedback">
                      Please enter the subject
                    </div>
                  )}
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={1}>
                <Grid item xs={12} className="mr-2">
                  <div className="form-group">
                    <label className="form-label" htmlFor="Editor">
                      Email Content
                    </label>
                    <Editor
                      apiKey="g1m4bae3io2j3jr8quzt4asbp8b4qyz8whah22qmv77se89f"
                      id="body"
                      value={info.data.body ? info.data.body : info}
                      onEditorChange={handleEditorChange}
                      init={{
                        relative_urls: false,
                        document_base_url: "https://www.lendcrm.io/",
                        plugins: "link image code",
                        skin:
                          props.menuConfig.personalization &&
                          props.menuConfig.personalization.mode === "Dark"
                            ? "oxide-dark"
                            : "",
                        content_css:
                          props.menuConfig.personalization &&
                          props.menuConfig.personalization.mode === "Dark"
                            ? "dark"
                            : "",
                        toolbar:
                          "undo redo | bold italic | alignleft aligncenter alignright | code",
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} className="mr-2">
                  <Docs
                    module={props.module}
                    setState={setInfo}
                    state={info}
                    owner={props.info.owner}
                    handleFileRead={handleFileRead}
                    deleteFile={deleteFile}
                  />
                </Grid>
              </Grid>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => props.actions.setActionInfo({})}
            >
              Close
            </Button>
            {/* {props.from !== "Send Mail To Selected" &&
              props.from !== "Send Mail To All" && (
                <Button
                  variant="primary"
                  onClick={(e) => sendOnClickHandler(e, true)}
                >
                  Save To Draft
                </Button>
              )} */}
            <Button variant="primary" onClick={(e) => sendOnClickHandler(e)}>
              Send Email
            </Button>
          </Modal.Footer>
        </Fragment>
      )}
    </Modal>
  );
};

const mapStateToProps = (store) => ({
  menuConfig: store.builder.menuConfig,
});

export default connect(mapStateToProps)(Compose);
