import React, { useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import update from "immutability-helper";
import { Tooltip } from "@material-ui/core";
import DraggableList from "../utils/DraggableList";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";

// List select all checkbox
export const SelectAllRow = (e, cls, massAction) => {
  var inputs = document.getElementsByClassName(cls);
  let info = { active: true, Ids: [] };
  for (var ii = 0; ii < inputs.length; ii++) {
    if (inputs[ii].type === "checkbox") {
      inputs[ii].checked = e.target.checked;
    }
    if (e.target.checked) info.Ids = [...info.Ids, inputs[ii].value];
  }
  massAction(info);
};

// List select one row
export const SelectOneRow = (e, massAction, info) => {
  let temp = { ...info };
  if (e.target.checked) {
    if (temp.Ids) {
      temp.Ids = [...temp.Ids, e.target.value];
    } else {
      temp.Ids = [e.target.value];
    }
  } else {
    let data = [];
    temp.Ids.forEach((d) => {
      if (d !== e.target.value) data = [...data, d];
    });
    temp.Ids = data;
  }
  massAction(temp);
};

const ListSettings = (props) => {
  const [open, setPopupOpen] = useState(false);
  const [fields, setFields] = useState(props.fields);
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = fields[dragIndex];
      let options = update(fields, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });
      setFields(options);
    },
    [fields]
  );

  const getListShowFields = (event) => {
    var dFields = document.getElementsByName("fields[]");
    let selFields = [];
    for (var i = 0, n = dFields.length; i < n; i++) {
      if (dFields[i].checked) {
        selFields = [...selFields, dFields[i].value];
      }
    }
    let tempFields = [];
    fields.forEach((field, i) => {
      if (selFields.includes(field.id)) {
        field.listShowField = true;
      } else {
        field.listShowField = false;
      }
      field.order = i;
      tempFields = [...tempFields, field];
    });
    setFields(tempFields);
  };

  const onSaveClickHandler = () => {
    let cnt = 1;
    let showColumns = [];
    fields.forEach((field) => {
      let tempFields = { ...field };
      if (field.listShowField) {
        tempFields.order = cnt;
        showColumns = [...showColumns, tempFields];
        cnt++;
      }
    });
    props.save(showColumns);
    setPopupOpen(false);
  };

  /** Model open or close */
  return (
    <>
      <Tooltip title="List settings" arrow>
        <button
          className="btn btn-sm btn-primary mr-2"
          onClick={() => setPopupOpen(true)}
        >
          List Settings
        </button>
      </Tooltip>
      <Modal
        show={open}
        size="lg"
        onHide={() => setPopupOpen(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>{props.title}</Modal.Header>
        <Modal.Body>
          <p className="text-success">
            <b>
              Hide & show the list columns and also order the list columns here!
            </b>
          </p>
          <DndProvider backend={Backend}>
            <div style={{ maxHeight: "300px", overflow: "auto" }}>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th nowrap="true">Field Name</th>
                    <th nowrap="true">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((field, i) => (
                    <DraggableList
                      key={i}
                      index={i}
                      id={field.id}
                      getListShowFields={getListShowFields}
                      check={field.listShowField ? true : false}
                      moveCard={moveCard}
                      text={field.title}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <div style={{ maxHeight: "200px", overflow: "auto" }}></div>
          </DndProvider>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => setPopupOpen(false)}
          >
            Close
          </button>
          <button
            className="btn btn-sm btn-primary"
            onClick={onSaveClickHandler}
          >
            Update Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ListSettings;
