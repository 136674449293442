import React from "react";
import { Link } from "react-router-dom";

const AssignedTasks = (props) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th nowrap="true">Created Date</th>
            <th nowrap="true">Created By</th>
            <th nowrap="true">Updated Date</th>
            <th nowrap="true">Updated By</th>
            <th nowrap="true">Actions</th>
          </tr>
        </thead>
        <tbody>
          {props.data.length ? (
            props.data.map((d, i) => (
              <tr key={i}>
                <td>{1 + i}</td>
                <td>
                  {d.info ? (!!d.info.first_name ? d.info.first_name : "") + " " + (!!d.info.last_name ? d.info.last_name : "") : ""}
                </td>
                <td>{d.info ? d.info.email : ""}</td>
                <td>{d.info ? d.info.phone : ""}</td>
                <td>{d.createdAt}</td>
                <td>{d.created_by.user}</td>
                <td>{d.updatedAt}</td>
                <td>{d.updated_by.user}</td>
                <td nowrap="true">
                  <Link
                    to={"/prospects/edit/" + d._id}
                    className="fa fa-pencil-alt fa-lg text-primary"
                    style={{ cursor: "pointer" }}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>
                <h5 className="text-primary text-center">
                  No prospect available, Create one!
                </h5>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AssignedTasks;
