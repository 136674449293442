import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { connect } from "react-redux";

import FormGroup from "../utils/FormGroup";
import { backendApiUrl } from "../../utils";

const Source = (props) => {
  const { user, element, module, events } = props;
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const company = params.get("ac") ?? user?.company?._id;

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(element.value);

  useEffect(() => {
    const fetchSources = async () => {
      try {
        const response = await axios.post(
          backendApiUrl("public/source"),
          { company },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setOptions(response.data.sources);

        // Set selected sources if module.opportunity.source exists
        if (!element.value & module?.opportunity?.source) {
          const filteredSources = response.data.sources.filter((opp) =>
            module.opportunity.source.includes(opp.value)
          );
          setSelected(filteredSources);
        }
      } catch (error) {
        console.error("Error fetching sources:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, company]);

  return (
    <FormGroup element={element} module={module}>
      {module.permissions.update ? (
        <Select
          className={`kt-width-full ${element.class}`}
          id={element.id}
          options={options}
          value={selected}
          onChange={(e) => {
            setSelected(e); // Update selected state when options change
            events.change(e, element.id);
          }}
          isMulti
          placeholder="Please select"
        />
      ) : (
        <h6 className="text-primary">
          {element.value &&
            element.value.map((v, i) => (i === 0 ? v.title : `, ${v.title}`))}
        </h6>
      )}
    </FormGroup>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Source);
