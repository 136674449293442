import React from "react";
import { Link } from "react-router-dom";

const RecentLeads = (props) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th nowrap="true">Webform</th>
            <th nowrap="true">Webform URL</th>
            <th nowrap="true">Created Date</th>
            <th nowrap="true">Updated Date</th>
            <th nowrap="true">Updated By</th>
            <th nowrap="true">Actions</th>
          </tr>
        </thead>
        <tbody>
          {props.data.length ? (
            props.data.map((lead, i) => (
              <tr key={i}>
                <td>{1 + i}</td>
                <td>{lead.form && lead.form.title}</td>
                <td>
                  <Link
                    to={"/leads/" + lead._id}
                    className="fa fa-link fa-lg"
                  />
                </td>
                <td>{lead.createdAt}</td>
                <td>{lead.updatedAt}</td>
                <td>{lead.updated_by.user}</td>
                <td nowrap="true">
                  <Link
                    to={"/leads/edit/" + lead._id}
                    className="fa fa-pencil-alt fa-lg text-primary"
                    style={{ cursor: "pointer" }}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6}>
                <h5 className="text-primary text-center">
                  No leads available, Create one!
                </h5>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RecentLeads;
