import React from "react";
import { Link } from "react-router-dom";

const RecentOpportunities = (props) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th nowrap="true">{props.prospectLabel}</th>
            <th nowrap="true">Product</th>
            <th nowrap="true">Program</th>
            <th nowrap="true">Status</th>
            <th nowrap="true">Assigned {props.workforceLabel}</th>
            <th nowrap="true">Assigned {props.contactLabel}</th>
            <th nowrap="true">Created Date</th>
            <th nowrap="true">Created By</th>
            <th nowrap="true">Updated Date</th>
            <th nowrap="true">Updated By</th>
            <th nowrap="true">Actions</th>
          </tr>
        </thead>
        <tbody>
          {props.data.length > 0 ? (
            props.data.map((op, i) => (
              <tr key={i}>
                <td>{1 + i}</td>
                <td>
                  {op.prospect &&
                    op.prospect.info &&
                    (!!op.prospect.info.first_name
                      ? op.prospect.info.first_name
                      : "") +
                      " " +
                      (!!op.prospect.info.last_name
                        ? op.prospect.info.last_name
                        : "") +
                      " | " +
                      (!!op.prospect.info.email ? op.prospect.info.email : "")}
                </td>
                <td>{op.product !== null && op.product.title}</td>
                <td>{op.program !== null && op.program.title}</td>
                <td>
                  {op.status &&
                    op.status.map((s, i) => (
                      <span key={i}>
                        {s ? s.title : ""}
                        <br />
                      </span>
                    ))}
                </td>
                <td>
                  {op.workforces.map(
                    (u, i) =>
                      u.user && (
                        <span key={i} className="mb-1">
                          {u.user
                            ? u.user.info.first_name +
                              " " +
                              u.user.info.last_name +
                              " (" +
                              (u.role ? u.role.title : "") +
                              ")"
                            : " "}
                          <br />
                        </span>
                      )
                  )}
                </td>
                <td>
                  {op.contacts.map(
                    (u, i) =>
                      u.user && (
                        <span key={i} className="mb-1">
                          {u.user
                            ? u.user.info.first_name +
                              " " +
                              u.user.info.last_name +
                              " (" +
                              (u.role ? u.role.title : "") +
                              ")"
                            : " "}
                          <br />
                        </span>
                      )
                  )}
                </td>
                <td>{op.createdAt}</td>
                <td>{op.created_by.user}</td>
                <td>{op.updatedAt}</td>
                <td>{op.updated_by.user}</td>

                <td nowrap="true">
                  <Link
                    to={"/loans/edit/" + op._id}
                    className="fa fa-pencil-alt fa-lg text-primary"
                    style={{ cursor: "pointer" }}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={12}>
                <h5 className="text-primary text-center">
                  No loans available, Create one!
                </h5>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RecentOpportunities;
