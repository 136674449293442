import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Form } from "react-bootstrap";

const style = {
  marginBottom: ".5rem",
  cursor: "move",
};

const Card = ({ id, index, text, moveCard, getListShowFields, check }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "Card",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: "Card", id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <tr ref={ref} style={{ ...style, opacity }}>
      <td>
        <Form.Check
          custom
          inline
          label={text}
          type="checkbox"
          name="fields[]"
          value={id}
          id={id}
          checked={check}
          onChange={getListShowFields}
        />
      </td>
      <td>
        <i
          style={{ marginRight: "10px" }}
          className="fas fa-arrows-alt fa-lg text-primary"
        />
      </td>
    </tr>
  );
};
export default Card;
