import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import { Spinner } from "react-bootstrap";
import { Badge } from "react-bootstrap";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../partials/content/Portlet";
import { backendApiUrl } from "../../utils";
import Message from "../../utils/Message";
import store from "../../store/store";

const CustomDocs = (props) => {
  const {
    auth: { authToken },
  } = store.getState();

  const [document, setDocument] = useState({});

  const [state, setState] = useState({
    load: true,
    info: {},
    data: {
      form: props.MI.form._id,
      owner: props.MI.MDID,
    },
    msg: {},
    listData: [],
    requiredFields: [],
  });

  const setMsg = (msg) => {
    let st = { ...state };
    st.msg = msg;
    setState(st);
  };

  const setInfo = (data) => {
    let st = { ...state };
    st.info = data;
    setState(st);
  };

  const onChangeHandler = (e) => {
    let st = { ...state };
    st.data[e.target.name] = e.target.value;
    setState(st);
  };

  const onFileChangeHandler = (e) => {
    setDocument(e.target.files[0]);
  };

  useEffect(() => {
    if (state.load) {
      axios
        .post(
          backendApiUrl("custom_docs"),
          {
            form: props.MI.form._id,
            owner: props.MI.MDID,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let st = { ...state };
          st.listData = res.data.data;
          st.load = false;
          setState(st);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state, props.MI.MDID, props.MI.form._id]);

  const onSubmitHandler = () => {
    let st = { ...state };
    let validDoc = true;
    let reqFields = [];
    let errorMsg = "";
    let fileSizeKiloBytes = "";
    //let MAX_FILE_SIZE = 1024; //1MB
    let MAX_FILE_SIZE = 46080; // 45MB is the max size of file

    if (state.data.title === undefined || state.data.modules === null) {
      reqFields = [...reqFields, "title"];
    }
    if (!(document && !!document.name)) reqFields = [...reqFields, "document"];

    if (reqFields.length > 0) {
      st.requiredFields = reqFields;
      setState(st);
      return true;
    }
    if (document && document.type !== "application/pdf") {
      validDoc = false;
      errorMsg = "Upload only PDF format";
    } else {
      fileSizeKiloBytes = document.size / 1024;
      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        validDoc = false;
        errorMsg = "File size is greater than maximum limit ( 45MB ).";
      }
    }

    const data = new FormData();
    data.append("title", st.data.title);
    data.append("form", st.data.form);
    data.append("owner", st.data.owner);
    if (st.data.description) data.append("description", st.data.description);
    data.append("file", document);
    if (validDoc) {
      axios
        .post(backendApiUrl("custom_docs/create"), data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (res) => {
          st.msg = {
            status: 200,
            msg: "Custom docs created/updated successfully.",
            show: true,
          };
          st.load = true;
          st.info = {};
          setState(st);
        })
        .catch((err) => {
          st.msg = {
            status: 500,
            msg: "Something went wrong, unable to create template.",
            show: true,
          };
          setState(st);
          window.scrollTo(0, 0);
        });
    } else {
      st.msg = {
        status: 500,
        msg: errorMsg,
        show: true,
      };
      setState(st);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Portlet>
      <PortletHeader
        title="Custom Docs"
        toolbar={
          <PortletHeaderToolbar>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => setInfo({ open: true })}
            >
              Create/Upload Docs
            </button>
          </PortletHeaderToolbar>
        }
      />
      <PortletBody>
        {state.msg && state.msg.show && (
          <Message
            status={state.msg.status}
            setMsg={setMsg}
            msg={state.msg.msg}
          />
        )}

        {state.load ? (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th nowrap="true">Title</th>
                    <th nowrap="true">Description</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {state.listData &&
                    state.listData.map((f, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{f.title}</td>
                        <td>{f.description}</td>
                        <td>
                          <a
                            href={
                              process.env.REACT_APP_DOC_URL +
                              "document/" +
                              f._id +
                              "?m=" +
                              props.MI.MDID +
                              "&f=" +
                              props.MI.form._id +
                              "&t=" +
                              authToken +
                              "&custom=1&url=" +
                              window.location.href
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Badge variant="success">
                              <i className="fa fa-paper-plane fa-lg mr-2" />Click to generate contract
                            </Badge>
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {state.info.open && (
                <Form
                  requiredFields={[]}
                  state={state}
                  onChange={onChangeHandler}
                  onFileChange={onFileChangeHandler}
                  setInfo={setInfo}
                  onSubmit={onSubmitHandler}
                />
              )}
            </div>
          </>
        )}
      </PortletBody>
    </Portlet>
  );
};

const Form = (props) => {
  let state = {};

  return (
    <Modal
      show={true}
      size="lg"
      onHide={() => props.setInfo({})}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Custom Docs</Modal.Title>
      </Modal.Header>
      {state.load ? (
        <div style={{ textAlign: "center" }} className="p-3">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <Modal.Body>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="form-group">
                  <label className="form-label" htmlFor="title">
                    Title <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value={props.state.data.title ? props.state.data.title : ""}
                    onChange={props.onChange}
                    className={
                      "form-control" +
                      (props.requiredFields.includes("title")
                        ? " is-invalid"
                        : "")
                    }
                  />
                  {props.requiredFields.includes("title") && (
                    <div className="invalid-feedback">
                      Please provide a valid title
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-group">
                  <label className="form-label" htmlFor="document">
                    Document <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    name="document"
                    className={
                      "form-control" +
                      (props.requiredFields.includes("document")
                        ? " is-invalid"
                        : "")
                    }
                    accept="application/pdf"
                    onChange={props.onFileChange}
                  />
                  <span style={{ color: "red" }}><small>Select only PDF Format & Maximum Uploading file size is 45MB.</small></span>
                  {props.requiredFields.includes("document") && (
                    <div className="invalid-feedback">
                      Please select document
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-group">
                  <label className="form-label" htmlFor="description">
                    Description
                  </label>
                  <textarea
                    name="description"
                    className="form-control"
                    value={
                      props.state.data.description
                        ? props.state.data.description
                        : ""
                    }
                    onChange={props.onChange}
                  />
                </div>
              </Grid>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={() => props.setInfo({})}
            >
              Close
            </button>
            <button className="btn btn-primary" onClick={props.onSubmit}>
              Create/Upload
            </button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default CustomDocs;
