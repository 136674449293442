import React from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { connect } from "react-redux";

const DialogBox = (props) => {
  return (
    <Dialog
      open={true}
      onClose={() => props.setStatus(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        className={
          props.menuConfig.personalization &&
          props.menuConfig.personalization.mode === "Dark"
            ? "modal-dark"
            : ""
        }
        id="alert-dialog-title"
      >
        Confirmation
      </DialogTitle>
      <DialogContent
        className={
          props.menuConfig.personalization &&
          props.menuConfig.personalization.mode === "Dark"
            ? "modal-dark"
            : ""
        }
      >
        <DialogContentText
          className={
            props.menuConfig.personalization &&
            props.menuConfig.personalization.mode === "Dark"
              ? "modal-dark"
              : ""
          }
          id="alert-dialog-description"
        >
          {props.msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        className={
          props.menuConfig.personalization &&
          props.menuConfig.personalization.mode === "Dark"
            ? "modal-dark"
            : ""
        }
      >
        <Button onClick={() => props.setStatus(true)} color="primary">
          Yes
        </Button>
        <Button
          onClick={() => props.setStatus(false)}
          color="primary"
          autoFocus
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (store) => ({
  menuConfig: store.builder.menuConfig,
});

export default connect(mapStateToProps)(DialogBox);
