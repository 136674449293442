import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { connect } from "react-redux";
import objectPath from "object-path";

import Header from "./header/Header";
import SubHeader from "./sub-header/SubHeader";
import HeaderMobile from "./header/HeaderMobile";
import ScrollTop from "../../app/partials/layout/ScrollTop";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import MenuConfig from "./MenuConfig";
import LayoutInitializer from "./LayoutInitializer";
import QuickPanel from "../../app/partials/layout/QuickPanel";
import KtContent from "./KtContent";
import PublicContent from "./PublicContent";
//import StickyToolbar from "../partials/layout/StickyToolbar";
import "./assets/Base.scss";

import BuilderTools from "../builders/tools/BuilderTools";
import Footer from "../builders/Footer";
import ModuleFooter from "./ModuleFooter";
import Message from "../utils/Message";
import { metronic } from "../../_metronic";
import { useDispatch } from "react-redux";
import { getObjectVal } from "../utils";

import AsideLeft from "./aside/AsideLeft";
import UserNotifications from "../partials/layout/UserNotifications";
import QuickPanelToggler from "../layout/header/QuickPanelToggle";
import * as builder from "../../_metronic/ducks/builder";

const htmlClassService = new HTMLClassService();

export const dropDownStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#1e1e1e",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#1e1e1e",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#1e1e1e",
    margin: 0,
  }),
};

const DarkMode = ({ menuConfig }) => {
  return (
    <style>
      {`
        body {
          background: #121212 !important;
          color: #fff !important;
        }
        .MuiTablePagination-root:last-child{
          color: #fff;
        }
        .fa {
          color: #fff;
        }
        .kt-portlet {
          background-color: #1e1e1e !important;
        }
        .kt-quick-panel {
          background-color: #1e1e1e !important;
        }
        .kt-portlet .kt-portlet__head, .card, .card-header{
          background-color: ${getObjectVal(
            menuConfig.personalization,
            "sectionHeaderColor",
            "#49839f"
          )} !important;
        }
        table, label {
          color: #ffffff !important;
        }
        .table tbody tr:hover td {
          background: #333;
          color: #fff;
        }
        .kt-sticky-toolbar {
           background-color: #1e1e1e !important;
         }
        .kt-portlet .kt-portlet__head {
          background-color: ${getObjectVal(
            menuConfig.personalization,
            "sectionHeaderColor",
            "#49839f"
          )} !important;
        }
        .kt-header, .kt-header-mobile {
          background-color: ${getObjectVal(
            menuConfig.personalization,
            "menuColor",
            "#061F2B"
          )} !important;
        }
        .nav-pills .nav-item .nav-link:active, .nav-pills .nav-item .nav-link.active, .nav-pills .nav-item .nav-link.active:hover {
          background-color: #1e1e1e !important;
        }
        .luxury-vertical-nav .nav-link:hover {
          background-color: #1e1e1e !important;
        }
        .btn-primary {
          background-color: #121212 !important;
          border: 1px solid #121212 !important;
        }
        .btn-dark {
          background-color: #1e1e1e !important;
          border: 1px solid #fff !important;
        }
        .tooltip-dark {
          background-color: #1e1e1e !important;
          color: #fff !important;
        }
        .text-primary {
          color: #fff !important;
        }
        .tabs-style-bar nav a.active {
          background-color: #121212 !important;
        }
        .kt-aside, .lc-aside-menu {
        background-color: ${getObjectVal(
          menuConfig.personalization,
          "menuColor",
          "#49839f"
        )} !important;
        }
        .kt-footer {
          background-color: #1e1e1e !important;
        }
        .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading, .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link {
          background-color: ${getObjectVal(
            menuConfig.personalization,
            "sectionHeaderColor",
            "#49839f"
          )} !important;
          border: 1px solid #1e1e1e !important;
        }
        .form-control {
          background-color: rgb(30, 30, 30); color: rgb(255, 255, 255);
        }
        .form-control:disabled{
          background-color: rgb(30, 30, 30); color: rgb(255, 255, 255);
        }
        .input-group-text {
          background-color: rgb(30, 30, 30); color: rgb(255, 255, 255);
        }
        .dropzone {
          background-color: rgb(30, 30, 30); color: rgb(255, 255, 255);
        }
        .kt-width-full > div, select option   {
          background-color: rgb(30, 30, 30)!important;
          color: #fff!important;
        }
        .MuiIconButton-root {
          color: #fff;
        }
        .kt-aside-menu .kt-menu__nav .kt-menu__item .kt-menu__toggle:hover {
          background-color: ${getObjectVal(
            menuConfig.personalization,
            "sectionHeaderColor",
            "#49839f"
          )} !important;
          border: 1px solid #1e1e1e !important;    
        }
        .modal-content {
          background-color: rgb(30, 30, 30);
          color: #fff;
        }
        .modal-title {
          color: #fff!important;
        }
        .modal-dark {
          background-color: #1e1e1e;
          color: #fff;
        }
        .profile-drop {
          background-color: rgb(30, 30, 30);
          color: #fff;
        }
        .react-datepicker__header {
          background-color: rgb(30, 30, 30);
        }
        .react-datepicker {
          background-color: rgb(30, 30, 30);
          color: #ffffff!important;
          border: 1px solid #fff;
          border-radius: 5;
        }
        .kt-aside-light .kt-aside-menu {
          background-color: #061F2B!important;
        }
        .kt-menu__link-text {
          color: #fff!important;
        }
        .dropdown-menu-fit, .MuiAccordion-root, .tooltip-inner {
          background-color: #061F2B!important;
          color: #fff;
        }
        .badge-inprogress {
          background-color: ${getObjectVal(
            menuConfig.personalization,
            "menuColor",
            "#061F2B"
          )} !important;
          color:#fff;
        }
        [class$="-singleValue"] {
          color:#fff;
        }
        input {
          color-scheme: dark;
        }
        ::-webkit-scrollbar {
          width: 15px;
        }
        ::-webkit-scrollbar-thumb {
          background: #061F2B;
          border-radius: 4px;
        }
        .kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--fluid{
          background-color:#121212 !important;
        }
      `}
    </style>
  );
};

function Layout({ children, selfLayout, layoutConfig, user }) {
  const currentUrl = window.location.pathname;
  const dispatch = useDispatch();
  const [msg, setMsg] = useState({});

  const { alertMsg, menuConfig } = useSelector(
    ({ builder }) => ({
      alertMsg: builder.alertMsg,
      menuConfig: builder.menuConfig,
    }),
    shallowEqual
  );

  const setAlertMsg = (msg) => {
    dispatch(metronic.builder.actions.setAlertMsg(msg));
  };

  htmlClassService.setConfig(layoutConfig);

  useEffect(() => {
    setMsg(alertMsg);
  }, [alertMsg]);

  useEffect(() => {
    dispatch(
      builder.actions.setMenuConfig({ ...menuConfig, date: new Date() })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl]);

  return selfLayout !== "blank" &&
    !window.location.pathname.includes("/forms/") &&
    !window.location.pathname.includes("/public/") &&
    !window.location.pathname.includes("/doc/view") &&
    !window.location.pathname.includes("/email/unsubscribe") ? (
    <LayoutInitializer
      menuConfig={MenuConfig}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      {getObjectVal(menuConfig.personalization, "mode", "Light") === "Dark" ? (
        <DarkMode menuConfig={menuConfig} />
      ) : (
        <style>
          {`
          .kt-portlet .kt-portlet__head {
            background-color: ${getObjectVal(
              menuConfig.personalization,
              "sectionHeaderColor",
              "#49839f"
            )} !important;
          }
          .kt-header, .kt-header-mobile {
            background-color: ${getObjectVal(
              menuConfig.personalization,
              "menuColor",
              "#061F2B"
            )} !important;
            margin-left: ${
              getObjectVal(
                menuConfig.personalization,
                "layout",
                "Horizontal"
              ) === "Vertical"
                ? "265px"
                : "0px"
            }
          }
          .custom-control-input:checked ~ .custom-control-label::before
          {
            background-color: ${getObjectVal(
              menuConfig.personalization,
              "menuColor",
              "#061F2B"
            )}!important;
            border-color: ${getObjectVal(
              menuConfig.personalization,
              "menuColor",
              "#061F2B"
            )}!important;
          }
          .nav-pills .nav-item .nav-link:active, .nav-pills .nav-item .nav-link.active, .nav-pills .nav-item .nav-link.active:hover, .btn-primary {
            background-color: ${getObjectVal(
              menuConfig.personalization,
              "menuColor",
              "#061F2B"
            )} !important;
            border: 1px solid ${getObjectVal(
              menuConfig.personalization,
              "menuColor",
              "#061F2B"
            )} !important;
          }
          .luxury-vertical-nav .nav-link:hover {
            background-color: ${getObjectVal(
              menuConfig.personalization,
              "menuColor",
              "#061F2B"
            )} !important;
          }
          .text-primary {
            color: ${getObjectVal(
              menuConfig.personalization,
              "menuColor",
              "#061F2B"
            )} !important;
          }
          .tabs-style-bar nav a.active {
            background-color: ${getObjectVal(
              menuConfig.personalization,
              "menuColor",
              "#061F2B"
            )} !important;
          }
          .kt-aside, .lc-aside-menu {
            background-color: ${getObjectVal(
              menuConfig.personalization,
              "menuColor",
              "#061F2B"
            )} !important;
          }
          .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading, .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link {
            background-color: ${getObjectVal(
              menuConfig.personalization,
              "sectionHeaderColor",
              "#49839f"
            )} !important;
            border: 1px solid #1e1e1e !important;
          }
          .kt-aside-menu .kt-menu__nav .kt-menu__item .kt-menu__toggle:hover {
              background-color: ${getObjectVal(
                menuConfig.personalization,
                "sectionHeaderColor",
                "#49839f"
              )} !important;
            border: 1px solid #1e1e1e !important;    
          }
          .dropdown-menu-fit, .dropdown-menu-fit a {
            color: #000!important;
          }
          .modal-header {
            background-color: ${getObjectVal(
              menuConfig.personalization,
              "sectionHeaderColor",
              "#49839f"
            )} !important;
          }
          ::-webkit-scrollbar {
            width: 15px;
          }
          ::-webkit-scrollbar-thumb {
            background: #D6D9DC;
            border-radius: 4px;
          }
        `}
        </style>
      )}
      <style>
        {`
          body {
            font-family: "Lexend Deca",Helvetica,Arial,sans-serif !important;
          }
        `}
      </style>
      <HeaderMobile />

      <div className="kt-grid kt-grid--hor kt-grid--root">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          {menuConfig.personalization &&
            menuConfig.personalization.layout === "Vertical" && <AsideLeft />}
          {(window.location.pathname === "/builders/modules/form" ||
            window.location.pathname === "/builders/opportunity/form" ||
            window.location.pathname === "/builders/webforms/form" ||
            window.location.pathname === "/builders/libraries/form") && (
            <BuilderTools />
          )}

          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
            id="kt_wrapper"
          >
            {(menuConfig.personalization === undefined ||
              (menuConfig.personalization &&
                menuConfig.personalization.layout !== "Vertical")) && (
              <Header />
            )}
            {menuConfig.personalization &&
              menuConfig.personalization.layout === "Vertical" && (
                <div
                  className="hrztl-icon"
                  style={{
                    top: "15px",
                    position: "absolute",
                    right: "0px",
                    zIndex: "999",
                  }}
                >
                  <div className="d-flex">
                    <div className="col-md-4 mt-2">
                      <QuickPanelToggler />
                    </div>
                    <div className="col-md-4 ml-2 mt-2">
                      <UserNotifications />
                    </div>
                  </div>
                </div>
              )}

            <SubHeader />
            {msg && msg.show && (
              <Message status={msg.status} setMsg={setAlertMsg} msg={msg.msg} />
            )}
            <div
              id="kt_content"
              className={`kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
            >
              <KtContent>{children}</KtContent>
            </div>
            {window.location.pathname.includes("/form") && <Footer />}
            {window.location.pathname.includes("/edit") ||
            window.location.pathname.includes("/create") ||
            window.location.pathname.includes("/leads/") ? (
              <ModuleFooter />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <QuickPanel />
      {/* {menuConfig.personalization &&
        menuConfig.personalization.layout === "Vertical" && <StickyToolbar />} */}
      <ScrollTop />
    </LayoutInitializer>
  ) : (
    // BLANK LAYOUT
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div
          id="kt_content"
          className={`kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
          style={{
            height: window.innerHeight - 141 + "px",
            overflowY: "scroll",
          }}
        >
          <PublicContent>{children}</PublicContent>
        </div>
        <ModuleFooter />
      </div>
    </div>
  );
}

const mapStateToProps = ({ builder: { layoutConfig }, auth }) => ({
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, "self.layout"),
  subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
  desktopHeaderDisplay: objectPath.get(
    layoutConfig,
    "header.self.fixed.desktop"
  ),
  contentContainerClasses: "",
  user: auth.user,
});

export default connect(mapStateToProps)(Layout);
