import React, { useCallback, useEffect, useState } from "react";
import { PlaidLink } from "react-plaid-link";
import { backendApiUrl } from "../../utils";
import { Accordion, AccordionDetails, AccordionSummary, Typography, FormControl, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from "axios";
import { sendEmail } from "./services";
import NumberFormat from "react-number-format";
import { Modal, Button } from "react-bootstrap";
export default function Ach({ sumPayments, props, formatDollar, loadPayments, paymentsData, updateMultipleFormData }) {
  const [token, setToken] = useState("");
  // console.log(props.company.enable_ach_payments);
  const onSuccess = (publicToken, metadata) => {
    updateMultipleFormData([
      { key: "loader", value: true },
    ]);
    console.log(publicToken);
    console.log(metadata);
    const data = {
      public_token: publicToken,
      account_id: metadata.accounts.length ? metadata.accounts[0].id : "",
      metadata: metadata,
      prospectId: props.opportunity.prospect._id,
      stripeId: paymentsData.formData.stripeConnectedAcc,
      // amount: sumPayments(paymentsData.formData.selectedPayments.payments),
      // selectedPayments: paymentsData.formData.selectedPayments
    }
    axios
      .post(backendApiUrl("payments/link_bank_account"), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        updateMultipleFormData([
          { key: "showPaymentMethod", value: false },
          { key: "loader", value: false },
        ])
      })
      .catch((err) => {
        updateMultipleFormData([
          { key: "msg", subKey: "show", value: true },
          { key: "loader", value: false },
          { key: "msg", subKey: "msg", value: err.message },
          { key: "msg", subKey: "status", value: 500 },
        ])
      });
  }

  const onEvent = (eventName, metadata) => {
    console.log(eventName, metadata);
  }
  const onExit = () => {
    console.log("Exited");
  }
  const getPlaidLinkToken = useCallback(() => {
    if (props.company.enable_ach_payments) {
      axios
        .get(backendApiUrl("payments/get_plaid_link_token"))
        .then((res) => {
          console.log(res.data.linkToken);
          setToken(res.data.linkToken);
        })
        .catch((err) => {
          updateMultipleFormData([
            { key: "msg", subKey: "show", value: true },
            { key: "msg", subKey: "msg", value: "plaid link failed please refresh page to link accounts." },
            { key: "msg", subKey: "status", value: 500 },
          ])
        });
    }
  }, [props, updateMultipleFormData]);


  useEffect(() => {
    getPlaidLinkToken();
  }, [getPlaidLinkToken]);

  const paymentSource = (d, e) => {
    // e.preventDefault();
    const { id } = d;
    updateMultipleFormData([
      { key: "selectedSource", value: id },
    ])
  }

  const confirmAchPayment = () => {
    updateMultipleFormData([
      { key: "loader", value: true },
    ])
    const data = {
      customerId: paymentsData.formData.data.stripeCusId,
      amount: sumPayments(paymentsData.formData.selectedPayments.payments) * 100,
      selectedPayments: paymentsData.formData.selectedPayments,
      stripeAccId: paymentsData.formData.stripeConnectedAcc,
      source: paymentsData.formData.selectedSource,
    }
    axios
      .post(backendApiUrl("payments/charge_ach_payment"), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setTimeout(() => {
          loadPayments();
          updateMultipleFormData([
            { key: "achConfirmPopup", value: false },
            { key: "showPaymentMethod", value: false },
            { key: "loader", value: false },
            { key: "msg", subKey: "show", value: true },
            { key: "msg", subKey: "show", value: true },
            { key: "msg", subKey: "msg", value: "Payment processed successfully" },
            { key: "msg", subKey: "status", value: 200 },
          ])
          sendEmail({ paymentsData, amount: sumPayments(paymentsData.formData.selectedPayments.payments), paymentType: "ACH" });
        }, 3000);

      })
      .catch((err) => {
        updateMultipleFormData([
          { key: "msg", subKey: "show", value: true },
          { key: "loader", value: false },
          { key: "msg", subKey: "msg", value: err.message },
          { key: "msg", subKey: "status", value: 500 },
        ])
      });
  }

  const StyledRadio = (props) => {

    return (
      <Radio
        disableRipple
        color="default"
        {...props}
      />
    );
  }

  const linkBankAccount = (e) => {
    updateMultipleFormData([
      { key: "loader", value: true },
    ]);
    const data = {
      customerId: paymentsData.formData.data.stripeCusId,
      stripeAccId: paymentsData.formData.stripeConnectedAcc,
      bank_account_name: paymentsData.formData.bank_account_name,
      bank_account_number: paymentsData.formData.bank_account_number,
      bank_routing_number: paymentsData.formData.bank_routing_number,
      prospectId: props.opportunity.prospect._id,
    }
    axios
      .post(backendApiUrl("payments/stripe_link_bank_account"), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        loadPayments();
        updateMultipleFormData([
          { key: "loader", value: false },
          { key: "showPaymentMethod", value: true },
        ]);
      })
      .catch((err) => {
        updateMultipleFormData([
          { key: "msg", subKey: "show", value: true },
          { key: "loader", value: false },
          { key: "msg", subKey: "msg", value: err.message },
          { key: "msg", subKey: "status", value: 500 },
        ])
      });
  }

  const bankAccount = paymentsData.formData.data?.payments?.[0]?.prospect?.stripe_info?.bank_account;
  return (
    <div className="bank-section">

      <Modal
        show={paymentsData.formData.showStripeBankAccountModal}
        size="xl"
        onHide={() => updateMultipleFormData([{ key: "showStripeBankAccountModal", value: false }])}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add Bank Account Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="form-label" htmlFor="bank_account_name">
              Bank Name
            </label>
            <input
              placeholder={"Bank Account Name"}
              type="text"
              id="bank_account_name"
              value={paymentsData.formData.bank_account_name}
              onChange={(e) => updateMultipleFormData([{ key: e.target.id, value: e.target.value }])}
              className={"form-control"}
            />
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="bank_routing_number">
              Bank Routing Number
            </label>
            <NumberFormat
              placeholder={"Bank Routing Number"}
              id="bank_routing_number"
              value={paymentsData.formData.bank_routing_number}
              onChange={(e) => updateMultipleFormData([{ key: e.target.id, value: e.target.value }])}
              className={"form-control"}
            />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="bank_account_number">
              Bank Account Number
            </label>
            <NumberFormat
              placeholder={"Bank Account Number"}
              id="bank_account_number"
              value={paymentsData.formData.bank_account_number}
              onChange={(e) => updateMultipleFormData([{ key: e.target.id, value: e.target.value }])}
              className={"form-control"}
            />
          </div>
          <div className="form-group">
            <button
              onClick={e => linkBankAccount(e)}
              type="button"
              style={{ width: "100%", background: "rgb(45 50 111)", color: "white" }}
            >
              <span>Link Now</span>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary"
            onClick={() => updateMultipleFormData([{ key: "showStripeBankAccountModal", value: false }])}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Payment Summary <span>{formatDollar(sumPayments(paymentsData.formData.selectedPayments.payments))}</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Item</th>
                <th nowrap="true">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {
                paymentsData.formData.selectedPayments.payments.map((n, i) => (
                  <tr key={i}>
                    <td>{n.item}</td>
                    <td>{formatDollar(n.amount.replace(/,/, ""))}</td>
                  </tr>
                ))
              }
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td>{formatDollar(sumPayments(paymentsData.formData.selectedPayments.payments))}</td>
              </tr>
            </tfoot>
          </table>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Saved Bank Accounts</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: "block" }}>
          {bankAccount.length ?
            (
              <>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="Bank Accounts" name="customized-radios">
                    {bankAccount.map((d, i) => (
                      d.status === "verified" && <FormControlLabel
                        key={i} value={d.id}
                        onChange={e => paymentSource(d, e)}
                        control={<StyledRadio />}
                        label={`**** **** **** ${d.last4}`}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <div className="col-6">
                  <button type="button" onClick={(e) => confirmAchPayment(e)}>Pay Now</button>
                </div>
              </>
            ) : (
              <div>Saved bank accounts are empty add a new bank account below to make ach payment.</div>
            )
          }
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Add New Bank Account</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {
            props.company.enable_ach_payments ? (

              <PlaidLink
                token={token}
                onSuccess={onSuccess}
                onEvent={onEvent}
                onExit={onExit}
                style={{ width: "100%", background: "rgb(45 50 111)", color: "white" }}
              >
                <span>Link Now</span>
              </PlaidLink>
            ) : (
              <button
                onClick={e => updateMultipleFormData([
                  // { key: "showPaymentMethod", value: false },
                  { key: "showStripeBankAccountModal", value: true },
                ])}
                type="button"
                style={{ width: "100%", background: "rgb(45 50 111)", color: "white" }}
              >
                <span>Add Bank Accounts</span>
              </button>
            )
          }
        </AccordionDetails>
      </Accordion>
    </div>
  );
}