import React, { useState } from "react";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { Modal, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { ProgressBar } from "react-bootstrap";

import FormGroup from "./utils/FormGroup";
import MIME_TYPE from "../config/MIME_TYPE";
import { Deactivate } from "../utils/ActivateAndDeactivate";
import Notes from "../services/notes";
import { backendApiUrl, formatBytes } from "../utils";

import "./dropzone.css";
import { ImAttachment } from "react-icons/im";
import { FaInfoCircle } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const FilePicker = (props) => {
  if (!!props.module?.MDID) {
    return Update(props);
  } else {
    return Create(props);
  }
};

const Create = (props) => {
  const [showNote, setShowNote] = useState(false);
  const [showFiles, setFiles] = useState(false);

  let MI = { ...props.module };

  const [doc, setDoc] = useState(
    MI.uploadedDocs && MI.uploadedDocs[props.element.id]
      ? MI.uploadedDocs[props.element.id]
      : {}
  );

  let mime_types = "";
  if (props.element.file_type) {
    props.element.file_type.forEach((f) => {
      mime_types += !!mime_types ? ", " + f.value : f.value;
    });
  } else {
    MIME_TYPE.forEach((f) => {
      mime_types += !!mime_types ? ", " + f.value : f.value;
    });
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: mime_types,
    multiple:
      props.element.isMultiple || props.element.isMultiple === undefined
        ? true
        : false,
    onDrop: (acceptedFiles) => {
      let d = {
        docType: props.element.id,
        files: acceptedFiles,
        status: "Awaiting Review",
        module: props.module.from,
        owner: props.module.MDID,
      };

      if (MI.files) {
        if (MI.files[props.element.id]) {
          MI.files[props.element.id].files = [
            ...MI.files[props.element.id].files,
            ...acceptedFiles,
          ];
        } else {
          MI.files[props.element.id] = d;
        }
      } else {
        MI.files = {
          [props.element.id]: d,
        };
      }

      props.events.setModuleInfo(MI);
    },
  });

  const deleteNewFile = (elm, file) => {
    let files = [];
    MI.files[elm].files.forEach((f) => {
      if (f.name !== file.name) {
        files = [...files, f];
      }
    });
    MI.files[elm].files = files;
    props.events.setModuleInfo(MI);
  };

  let new_files = "";
  if (
    MI.files &&
    MI.files[props.element.id] &&
    MI.files[props.element.id].files
  ) {
    new_files = MI.files[props.element.id].files.map((file) => (
      <li key={file.path}>
        <span className="mr-2">
          {file.path} - {formatBytes(file.size, 2)}&nbsp;
          <i
            className="fa fa-trash fa-lg text-danger"
            title="Deactivate"
            onClick={() => deleteNewFile(props.element.id, file)}
          />
        </span>
      </li>
    ));
  }

  const statusOnChangeHandler = (e) => {
    if (MI.files && MI.files[props.element.id]) {
      MI.files[props.element.id] = {
        ...MI.files[props.element.id],
        status: e.target.value,
      };
    } else {
      let d = {
        docType: props.element.id,
        status: e.target.value,
        module: props.module.from,
        owner: props.module.MDID,
      };

      if (MI.files) {
        MI.files[props.element.id] = d;
      } else {
        MI.files = {
          [props.element.id]: d,
        };
      }
    }
    props.events.setModuleInfo(MI);
  };

  const previewOnClickHandler = (id) => {
    axios
      .get(backendApiUrl("auth/file/view/" + id), {
        responseType: "blob",
      })
      .then((res) => {
        const file = new Blob([res.data], {
          type: res.headers["content-type"],
        });

        let extension =
          res.headers["content-type"] === "text/csv; charset=UTF-8"
            ? "csv"
            : "";
        MIME_TYPE.forEach((m) => {
          if (res.headers["content-type"] === m.value) extension = m.label;
        });
        //Build a URL from the file
        const url = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        if (["docx", "doc", "csv"].includes(extension))
          link.setAttribute("download", "file." + extension); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDeleteHandler = (msg) => {
    if (!!msg._id) {
      let files = [];
      doc.files.forEach((f) => {
        if (msg._id !== f._id) files = [...files, f];
      });
      let d = { ...doc };
      d.files = files;
      setDoc(d);
      props.events.setMsg(msg);
    }
  };

  let fileExists = 0;
  const old_files = doc.files
    ? doc.files.reverse().map((f, z) => {
        let obj = f.name.split(".");
        let filename =
          props.element.title +
          "_" +
          (doc.files.length - z) +
          "." +
          obj[obj.length - 1];
        if (MI.dynamicFieldData && MI.dynamicFieldData.auto_increment) {
          filename = MI.dynamicFieldData.auto_increment + "_" + filename;
        }

        if (f.active || f.active === undefined) fileExists++;
        return (
          (f.active || f.active === undefined) && (
            <li key={f._id}>
              <span
                className="mr-2"
                onClick={() => previewOnClickHandler(f._id)}
              >
                {filename} - {formatBytes(f.size, 2)}&nbsp;
                <i
                  className="fa fa-eye fa-lg text-success"
                  aria-hidden="true"
                />
              </span>
              {allowToEdit && (
                <Deactivate
                  url={backendApiUrl("docs/file/" + f._id)}
                  id={f._id}
                  actions={{
                    msg: onDeleteHandler,
                  }}
                  title={f.name}
                />
              )}
            </li>
          )
        );
      })
    : "";

  // Permissions
  let allowToEdit = false;
  let permissions = {};
  if ((props.user && props.user.role.admin) || MI.MDID === "") {
    permissions = { add: true, update: true, delete: true };
  } else if (MI.permissions && MI.permissions.docs) {
    permissions = MI.permissions.docs;
  }

  if ((permissions.add && doc._id === undefined) || permissions.update) {
    allowToEdit = true;
  }

  // March 17 2023 - As per sam and rajesh request
  if (props.user && props.user.type === "Prospects") {
    allowToEdit = false;
  }
  let status =
    MI.files && MI.files[props.element.id] && MI.files[props.element.id]
      ? MI.files[props.element.id].status
      : doc.status;

  let bgcolor = "";
  if (status === "Not Uploaded" || status === undefined) bgcolor = "notUpload";
  if (status === "Awaiting Review") bgcolor = "awaitingReview";
  if (status === "Awaiting Reupload") bgcolor = "awaitingReupload";
  if (status === "Approved") bgcolor = "approved";
  if (status === "N/A") bgcolor = "NA";

  let DocStatus = [
    "Not Uploaded",
    "Awaiting Review",
    "Awaiting Reupload",
    "Approved",
  ];
  if (
    props.user &&
    props.user.company._id.toString() === "61014b8959c5142ac2ab2180"
  ) {
    DocStatus = [...DocStatus, "N/A"];
  }

  return (
    <FormGroup element={props.element} module={props.module}>
      <div className="row">
        <div className="col-md-12">
          <div
            {...getRootProps({ className: "dropzone" + props.element.class })}
          >
            <input
              {...getInputProps()}
              name={props.element.id}
              id={props.element.id}
            />
            <h6 className="text-primary">Drag & Drop files here</h6>
          </div>
        </div>

        {props.user !== undefined && (
          <div className="col-md-7" style={{ marginTop: "10px" }}>
            {allowToEdit ? (
              <select
                id="doc_status_color"
                className={bgcolor}
                value={status}
                onChange={statusOnChangeHandler}
                style={{ width: "100%", fontWeight: "bold" }}
              >
                {DocStatus.map((t, i) => (
                  <option value={t} key={i}>
                    {t === "Awaiting Review" ? "In Review" : t}
                  </option>
                ))}
              </select>
            ) : (
              <span className={bgcolor}>
                <b>{status}</b>
              </span>
            )}
          </div>
        )}
        <div className="col-md-5 mt-2" style={{ marginTop: "10px" }}>
          {!!doc._id && doc.notes && doc.notes.length > 0 ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  <table>
                    <thead>
                      <th>Notes</th>
                      <th>Type</th>
                    </thead>
                    {doc.notes && doc.notes.length > 0
                      ? doc.notes.map((n, i) => (
                          <tbody key={i}>
                            <tr key={i}>
                              <td>{n.content}</td>
                              <td>{n.type}</td>
                            </tr>
                          </tbody>
                        ))
                      : ""}
                  </table>
                </Tooltip>
              }
            >
              <i
                className="fa fa-comments mr-2 fa-lg green-color"
                style={{ color: "Green" }}
                onClick={() => setShowNote(true)}
              />
            </OverlayTrigger>
          ) : !!doc._id && doc.notes && doc.notes.length === 0 ? (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>No Notes available...</Tooltip>}
            >
              <i
                className="fa fa-comments mr-2 fa-lg"
                style={{ color: "red" }}
                onClick={() => setShowNote(true)}
              />
            </OverlayTrigger>
          ) : (
            ""
          )}
          {!!old_files && fileExists > 0 && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Click here to View Existing Files..</Tooltip>}
            >
              <span>
                <ImAttachment
                  className="mr-2"
                  style={{ color: "green", width: "24px", height: "24px" }}
                  onClick={() => setFiles(true)}
                  placeholder="Click here to View Existing Files.."
                />
                <span
                  style={{
                    borderRadius: "50%", // Set border-radius to create a round shape
                    width: "20px", // Adjust width and height as needed
                    height: "20px",
                    backgroundColor: "lightblue", // Set background color
                    padding: "5px",
                    textAlign: "center",
                    fontWeight: "bold", // Make the count text bold
                  }}
                >
                  <b>{fileExists}</b>
                </span>
              </span>
            </OverlayTrigger>
          )}
        </div>
        <div className="col-md-12 mt-2">
          {!!new_files && (
            <aside className="mt-2" name={props.element.id}>
              <h6>New Files</h6>
              <ul>{new_files}</ul>
            </aside>
          )}
        </div>
      </div>

      {showNote && (
        <Modal show={true} size="lg" backdrop="static" keyboard={false}>
          <Notes
            module="docs"
            permissions={{ notes: { add: true, update: true } }}
            owner={doc._id}
            MI={MI}
          />
          <Modal.Footer>
            <button
              className="btn btn primary"
              onClick={() => setShowNote(false)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}

      {showFiles && (
        <Modal show={true} size="md" backdrop="static" keyboard={false}>
          <Modal.Header>
            <Modal.Title>Existing Files</Modal.Title>
          </Modal.Header>
          <Modal.Body>{!!old_files && fileExists > 0 && old_files}</Modal.Body>
          <Modal.Footer>
            <button className="btn btn primary" onClick={() => setFiles(false)}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </FormGroup>
  );
};

const Update = (props) => {
  const MI = { ...props.module };
  const [showNote, setShowNote] = useState(false);
  const [showFiles, setFiles] = useState(false);
  const [doc, setDoc] = useState(MI.uploadedDocs?.[props.element.id] ?? {});
  const [uploadProgress, setUploadProgress] = useState();
  const [loaderStatus, setLoaderStatus] = useState();
  const [openReason, setReasonModal] = useState(false);
  const [reason, setReason] = useState("");
  const [changedStatus, setReuploadSatus] = useState("");
  const [openReasonError, setReasonError] = useState(false);
  const [reasonLoader, setReasonLoader] = useState(false);
  const [dlud, setDLUD] = useState("");
  const [openFileStatus, setFileStatus] = useState("");
  const [clickedFile, setClickedFile] = useState("");
  const [clickedElmId, setClickedElmId] = useState("");

  const mime_types = (props.element.file_type
    ? props.element.file_type
    : MIME_TYPE
  )
    .map((f) => f.value)
    .join(", ");

  let docsLastUpdatedDate = "";
  const onAgreeClickHandler = async (file) => {
    if (changedStatus === "Awaiting Reupload" && reason === "") {
      setReasonError(true);
      return true;
    } else if (changedStatus === "Awaiting Reupload" && reason !== "") {
      setReasonError(false);
      setReasonLoader(true);
      const data = new FormData();
      data.append(
        "status--" + props.element.id,
        changedStatus || "Awaiting Review"
      ); // Document status
      data.append("owner", props.module.MDID); // Document owner id
      data.append("module", props.module.from); // Document module id
      data.append("form", MI.form._id); // Form id
      data.append("loanNumber", MI.opportunity.info.auto_increment); // loan number
      data.append("reupload_reason", reason); // loan number
      data.append("uploaded_item", props.element.title); // uploaded item
      await axios
        .post(backendApiUrl("docs/upload"), data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((ul) => {
          if (ul.data.document) {
            setDoc(ul.data.document);
            setReasonLoader(false);
            setLoaderStatus(false);
            setReasonModal(false);
            MI.msg = {
              status: 200,
              msg: "Email Sent Successfully",
              show: true,
            };
            props.events.setModuleInfo(MI);
          } else {
            setReasonLoader(false);
            setLoaderStatus(false);
            setReasonModal(false);
            MI.msg = {
              status: 200,
              msg: "Something went wrong, could not sent email.",
              show: true,
            };
            props.events.setModuleInfo(MI);
          }
        });
    }
  };

  const cancellReasonPopup = () => {
    setReasonModal(false);
    setLoaderStatus(false);
  };

  const cancellFileStatusChange = () => {
    setFileStatus(false);
  };

  const onAgreeFileStatusChangeHandler = (fid) => {
    let d = { ...doc };
    let files = [];
    doc.files.reverse().forEach((f) => {
      if (f._id === clickedFile) f.isViewed = false;
      files = [...files, f];
    });
    d.files = files;
    setDoc(d);
    // Updating module info
    MI.uploadedDocs[clickedElmId].files = files;
    previewOnClickHandler(clickedFile, false);
  };

  const handleFileView = (elmId, fid, fsts, opt) => {
    // Updating internal state
    if (fsts === true && opt === "viewOnly") {
      fileViewHandler(fid);
    } else {
      if (fsts === true) {
        setFileStatus(true);
        setClickedFile(fid);
        setClickedElmId(elmId);
      } else {
        let d = { ...doc };
        let files = [];
        doc.files.reverse().forEach((f) => {
          if (f._id === fid) f.isViewed = fsts ? false : true;
          files = [...files, f];
        });
        d.files = files;
        setDoc(d);
        previewOnClickHandler(fid, fsts ? false : true);

        // Updating module info
        MI.uploadedDocs[elmId].files = files;
        props.events.setModuleInfo(MI);
      }
    }
  };

  const handleDocumentManagerStatusUpload = async (files, status) => {
    const data = new FormData();
    if (files.length > 0) {
      // Display the upload progress bar exclusively for the upload process.
      setUploadProgress(randomIntFromInterval(10, 80));
      files.forEach((file) => {
        data.append(
          "file",
          file,
          `${props.module.from}&$&$&$&${props.module.MDID}&$&$&$&${props.element.id}&$&$&$&${file.name}&$&$&$&${file.size}`
        );
      });
    } else {
      if (
        status === "Awaiting Reupload" &&
        props.user &&
        props.user.company._id.toString() === "61014b8959c5142ac2ab2180"
      ) {
        setReasonModal(true);
        setReuploadSatus(status);
        setLoaderStatus(true);
        return true;
      }
      setLoaderStatus(true);
    }

    data.append("status--" + props.element.id, status || "Awaiting Review"); // Document status
    data.append("owner", props.module.MDID); // Document owner id
    data.append("module", props.module.from); // Document module id
    data.append("form", MI.form._id); // Form id
    data.append("loanNumber", MI.opportunity.info.auto_increment); // loan number

    await axios
      .post(backendApiUrl("docs/upload"), data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((ul) => {
        setDoc(ul.data.document);
        if (ul.data.document) {
          if (ul.data.document.updatedAt !== undefined) {
            docsLastUpdatedDate =
              ul.data.document.updatedAt !== ""
                ? ul.data.document.updatedAt
                : ul.data.document.createdAt !== ""
                ? ul.data.document.createdAt
                : "";
            setDLUD(docsLastUpdatedDate);
          }
          // Display the upload progress bar exclusively for the upload process.
          if (files.length > 0) {
            setUploadProgress(100);

            setTimeout(() => {
              setUploadProgress(0);
            }, 2000);
            MI.msg = {
              status: 200,
              msg: "Document Successfully Uploaded.",
              show: true,
            };
            props.events.setModuleInfo(MI);
          } else {
            setLoaderStatus(false);
          }
        } else {
          setLoaderStatus(false);
          MI.msg = {
            status: 200,
            msg: "Something went wrong, could not upload the document.",
            show: true,
          };
          props.events.setModuleInfo(MI);
        }
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: mime_types,
    multiple:
      props.element.isMultiple || props.element.isMultiple === undefined
        ? true
        : false,
    onDrop: async (acceptedFiles) => {
      handleDocumentManagerStatusUpload(acceptedFiles, doc.status);
    },
  });

  const previewOnClickHandler = (id, status) => {
    axios
      .get(backendApiUrl("auth/file/view/" + id), {
        responseType: "blob",
      })
      .then((res) => {
        const file = new Blob([res.data], {
          type: res.headers["content-type"],
        });
        if (status !== false) {
          let extension =
            res.headers["content-type"] === "text/csv; charset=UTF-8"
              ? "csv"
              : "";
          MIME_TYPE.forEach((m) => {
            if (res.headers["content-type"] === m.value) extension = m.label;
          });
          //Build a URL from the file
          const url = URL.createObjectURL(file);
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          if (["docx", "doc", "csv"].includes(extension))
            link.setAttribute("download", "file." + extension); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        let notificationMsg = status === false ? "Not Viewed" : "Viewed";
        MI.msg = {
          status: 200,
          msg: "File Marked As " + notificationMsg + " Successfully",
          show: true,
        };
        setFileStatus(false);
        props.events.setModuleInfo(MI);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fileViewHandler = (id) => {
    axios
      .get(backendApiUrl("auth/file/fileView/" + id), {
        responseType: "blob",
      })
      .then((res) => {
        const file = new Blob([res.data], {
          type: res.headers["content-type"],
        });
        let extension =
          res.headers["content-type"] === "text/csv; charset=UTF-8"
            ? "csv"
            : "";
        MIME_TYPE.forEach((m) => {
          if (res.headers["content-type"] === m.value) extension = m.label;
        });
        //Build a URL from the file
        const url = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        if (["docx", "doc", "csv"].includes(extension))
          link.setAttribute("download", "file." + extension); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDeleteHandler = (msg) => {
    if (!!msg._id) {
      let files = [];
      doc.files.forEach((f) => {
        if (msg._id !== f._id) files = [...files, f];
      });
      let d = { ...doc };
      d.files = files;
      setDoc(d);
      props.events.setMsg(msg);
    }
  };

  // Permissions
  let allowToEdit = false;
  let permissions = {};
  if ((props.user && props.user.role.admin) || MI.MDID === "") {
    permissions = { add: true, update: true, delete: true };
  } else if (MI.permissions && MI.permissions.docs) {
    permissions = MI.permissions.docs;
  }

  if ((permissions.add && doc._id === undefined) || permissions.update) {
    allowToEdit = true;
  }

  // March 17 2023 - As per sam and rajesh request
  if (props.user && props.user.type === "Prospects") {
    allowToEdit = false;
  }

  let fileExists = 0;
  const old_files = doc.files
    ? doc.files.reverse().map((f, z) => {
        let obj = f.name.split(".");
        let filename =
          props.element.title +
          "_" +
          (doc.files.length - z) +
          "." +
          obj[obj.length - 1];
        if (MI.dynamicFieldData && MI.dynamicFieldData.auto_increment) {
          filename = MI.dynamicFieldData.auto_increment + "_" + filename;
        }

        if (f.active || f.active === undefined) fileExists++;
        return (
          (f.active || f.active === undefined) && (
            <li key={f._id}>
              <span className="mr-2">
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() =>
                    handleFileView(
                      props.element.id,
                      f._id,
                      f.isViewed,
                      "viewOnly"
                    )
                  }
                >
                  {filename} - {formatBytes(f.size, 2)}&nbsp;
                </span>
                {f.isViewed === true ? (
                  <FaEye
                    style={{
                      width: "24px",
                      height: "24px",
                      marginLeft: "5px",
                      color: "green",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleFileView(
                        props.element.id,
                        f._id,
                        f.isViewed,
                        "change"
                      )
                    }
                  />
                ) : (
                  <FaEyeSlash
                    style={{
                      width: "24px",
                      height: "24px",
                      marginLeft: "5px",
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleFileView(
                        props.element.id,
                        f._id,
                        f.isViewed,
                        "change"
                      )
                    }
                  />
                )}
              </span>
              {allowToEdit && (
                <Deactivate
                  url={backendApiUrl("docs/file/" + f._id)}
                  id={f._id}
                  actions={{
                    msg: onDeleteHandler,
                  }}
                  title={f.name}
                />
              )}
            </li>
          )
        );
      })
    : "";

  let status =
    MI.files && MI.files[props.element.id] && MI.files[props.element.id]
      ? MI.files[props.element.id].status
      : doc.status;
  if (MI.uploadedDocs[props.element.id] !== undefined) {
    docsLastUpdatedDate = MI.uploadedDocs[props.element.id]
      ? MI.uploadedDocs[props.element.id].updatedAt
      : MI.uploadedDocs[props.element.id].createdAt
      ? MI.uploadedDocs[props.element.id].createdAt
      : "";
  }
  if (dlud !== "") {
    docsLastUpdatedDate = dlud;
  }
  let bgcolor = "";
  if (status === "Not Uploaded" || status === undefined) bgcolor = "notUpload";
  if (status === "Awaiting Review") bgcolor = "awaitingReview";
  if (status === "Awaiting Reupload") bgcolor = "awaitingReupload";
  if (status === "Approved") bgcolor = "approved";
  if (status === "N/A") bgcolor = "NA";

  let DocStatus = [
    "Not Uploaded",
    "Awaiting Review",
    "Awaiting Reupload",
    "Approved",
  ];
  if (
    props.user &&
    props.user.company._id.toString() === "61014b8959c5142ac2ab2180"
  ) {
    DocStatus = [...DocStatus, "N/A"];
  }

  return (
    <FormGroup element={props.element} module={props.module}>
      <div className="row">
        {uploadProgress > 0 && (
          <div className="col-md-12 mb-2">
            Uploading... please wait
            <ProgressBar animated variant="success" now={uploadProgress} />
          </div>
        )}
        <div className="col-md-12">
          <div
            {...getRootProps({ className: "dropzone" + props.element.class })}
          >
            <input
              {...getInputProps()}
              name={props.element.id}
              id={props.element.id}
            />
            <h6 className="text-primary">Drag & Drop files here</h6>
          </div>
        </div>

        {props.user !== undefined && (
          <div className="col-md-7" style={{ marginTop: "10px" }}>
            {allowToEdit ? (
              <select
                id="doc_status_color"
                className={bgcolor}
                value={status}
                onChange={(e) =>
                  handleDocumentManagerStatusUpload([], e.target.value)
                }
                style={{ width: "100%", fontWeight: "bold" }}
              >
                {DocStatus.map((t, i) => (
                  <option value={t} key={i}>
                    {t === "Awaiting Review" ? "In Review" : t}
                  </option>
                ))}
              </select>
            ) : (
              <span className={bgcolor}>
                <b>{status}</b>
              </span>
            )}
          </div>
        )}
        <div className="col-md-5 mt-2" style={{ marginTop: "10px" }}>
          {loaderStatus && <Spinner animation="border" variant="primary" />}
          {doc.notes && doc.notes.length > 0 ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  <table>
                    <thead>
                      <tr>
                        <th>Notes</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {doc.notes.map((n, i) => (
                        <tr key={i}>
                          <td>{n.content}</td>
                          <td>{n.type}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Tooltip>
              }
            >
              <i
                className="fa fa-comments mr-2 fa-lg green-color"
                style={{ color: "green" }}
                onClick={() => setShowNote(true)}
              />
            </OverlayTrigger>
          ) : !!doc._id && doc.notes && doc.notes.length === 0 ? (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>No Notes available...</Tooltip>}
            >
              <i
                className="fa fa-comments mr-2 fa-lg"
                style={{ color: "red" }}
                onClick={() => setShowNote(true)}
              />
            </OverlayTrigger>
          ) : null}

          {!!old_files && fileExists > 0 && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Click here to View Existing Files..</Tooltip>}
            >
              <span>
                <ImAttachment
                  className="mr-2"
                  style={{ color: "green", width: "24px", height: "24px" }}
                  onClick={() => setFiles(true)}
                  placeholder="Click here to View Existing Files.."
                />
                <span
                  style={{
                    borderRadius: "50%", // Set border-radius to create a round shape
                    width: "20px", // Adjust width and height as needed
                    height: "20px",
                    backgroundColor: "lightblue", // Set background color
                    padding: "5px",
                    textAlign: "center",
                    fontWeight: "bold", // Make the count text bold
                  }}
                >
                  <b>{fileExists}</b>
                </span>
              </span>
            </OverlayTrigger>
          )}
          {docsLastUpdatedDate !== undefined && docsLastUpdatedDate !== "" && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>Last Updated Date : {docsLastUpdatedDate}</Tooltip>
              }
            >
              <FaInfoCircle
                style={{ width: "24px", height: "24px", marginLeft: "5px" }}
              />
            </OverlayTrigger>
          )}
        </div>
        {/*<div className="col-md-12">
          Last Updated Date : {docsLastUpdatedDate}
            </div>*/}
      </div>

      {showNote && (
        <Modal show={true} size="lg" backdrop="static" keyboard={false}>
          <Notes
            module="docs"
            permissions={{ notes: { add: true, update: true } }}
            owner={doc._id}
            MI={MI}
          />
          <Modal.Footer>
            <button
              className="btn btn primary"
              onClick={() => setShowNote(false)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}

      {showFiles && (
        <Modal show={true} size="md" backdrop="static" keyboard={false}>
          <Modal.Header>
            <Modal.Title>Existing Files</Modal.Title>
          </Modal.Header>
          <Modal.Body>{!!old_files && fileExists > 0 && old_files}</Modal.Body>
          <Modal.Footer>
            <button className="btn btn primary" onClick={() => setFiles(false)}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
      {openReason && (
        <Modal show={true} size="md" backdrop="static" keyboard={false}>
          <Modal.Body>
            <div align="center">
              Please Enter the Reason for Re-Upload
              <textarea
                className="form-control"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
              {openReasonError && (
                <span id="reason_error" style={{ color: "red" }}>
                  Please Enter Valid Reason
                </span>
              )}
              {reasonLoader && <Spinner animation="border" variant="primary" />}{" "}
              <br />
              <br />
              <span className="mt-2">
                <button
                  className="btn btn-success mr-2"
                  onClick={() => onAgreeClickHandler([])}
                >
                  Send Email
                </button>
                <button
                  className="btn btn-danger mr-2"
                  onClick={cancellReasonPopup}
                >
                  Cancel
                </button>
              </span>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {openFileStatus && (
        <Modal show={true} size="md" backdrop="static" keyboard={false}>
          <Modal.Body>
            <div align="center">
              Do you want to revert the file status to 'Not Viewed'?
              <br />
              <span className="mt-2">
                <button
                  className="btn btn-success mr-2"
                  onClick={() => onAgreeFileStatusChangeHandler(false)}
                >
                  Yes
                </button>
                <button
                  className="btn btn-danger mr-2"
                  onClick={cancellFileStatusChange}
                >
                  Cancel
                </button>
              </span>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </FormGroup>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(FilePicker);
