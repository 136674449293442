import React from "react";
import Moment from "react-moment";
import { Modal } from "react-bootstrap";

export const backendApiUrl = (pathname) => {
  return process.env.REACT_APP_BACKEND_API_URL + pathname;
};

export const getDate = (date, startWith) => {
  if (date !== undefined && date !== "" && date !== null) {
    if (startWith === "Y") {
      return <Moment format="YYYY/MM/DD">{date}</Moment>;
    } else if (startWith === "D") {
      return <Moment format="DD/MM/YYYY">{date}</Moment>;
    } else {
      return <Moment format="MM/DD/YYYY">{date}</Moment>;
    }
  } else {
    return "";
  }
};

export const getYearMonth = (date) => {
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  return `${date.getFullYear()}-${month}`;
};

export const getMonthYear = (date) => {
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  return `${month}-${date.getFullYear()}`;
};

export const timezones = () => {
  return [
    "-- Select --",
    "Europe/Andorra",
    "Asia/Dubai",
    "Asia/Kabul",
    "Asia/Kolkata",
    "Asia/Calcutta",
    "Europe/Tirane",
    "Asia/Yerevan",
    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/DumontDUrville",
    "Antarctica/Mawson",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/Syowa",
    "Antarctica/Troll",
    "Antarctica/Vostok",
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Cordoba",
    "America/Argentina/Salta",
    "America/Argentina/Jujuy",
    "America/Argentina/Tucuman",
    "America/Argentina/Catamarca",
    "America/Argentina/La_Rioja",
    "America/Argentina/San_Juan",
    "America/Argentina/Mendoza",
    "America/Argentina/San_Luis",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Ushuaia",
    "Pacific/Pago_Pago",
    "Europe/Vienna",
    "Australia/Lord_Howe",
    "Antarctica/Macquarie",
    "Australia/Hobart",
    "Australia/Currie",
    "Australia/Melbourne",
    "Australia/Sydney",
    "Australia/Broken_Hill",
    "Australia/Brisbane",
    "Australia/Lindeman",
    "Australia/Adelaide",
    "Australia/Darwin",
    "Australia/Perth",
    "Australia/Eucla",
    "Asia/Baku",
    "America/Barbados",
    "Asia/Dhaka",
    "Europe/Brussels",
    "Europe/Sofia",
    "Atlantic/Bermuda",
    "Asia/Brunei",
    "America/La_Paz",
    "America/Noronha",
    "America/Belem",
    "America/Fortaleza",
    "America/Recife",
    "America/Araguaina",
    "America/Maceio",
    "America/Bahia",
    "America/Sao_Paulo",
    "America/Campo_Grande",
    "America/Cuiaba",
    "America/Santarem",
    "America/Porto_Velho",
    "America/Boa_Vista",
    "America/Manaus",
    "America/Eirunepe",
    "America/Rio_Branco",
    "America/Nassau",
    "Asia/Thimphu",
    "Europe/Minsk",
    "America/Belize",
    "America/St_Johns",
    "America/Halifax",
    "America/Glace_Bay",
    "America/Moncton",
    "America/Goose_Bay",
    "America/Blanc-Sablon",
    "America/Toronto",
    "America/Nipigon",
    "America/Thunder_Bay",
    "America/Iqaluit",
    "America/Pangnirtung",
    "America/Atikokan",
    "America/Winnipeg",
    "America/Rainy_River",
    "America/Resolute",
    "America/Rankin_Inlet",
    "America/Regina",
    "America/Swift_Current",
    "America/Edmonton",
    "America/Cambridge_Bay",
    "America/Yellowknife",
    "America/Inuvik",
    "America/Creston",
    "America/Dawson_Creek",
    "America/Fort_Nelson",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Dawson",
    "Indian/Cocos",
    "Europe/Zurich",
    "Africa/Abidjan",
    "Pacific/Rarotonga",
    "America/Santiago",
    "America/Punta_Arenas",
    "Pacific/Easter",
    "Asia/Shanghai",
    "Asia/Urumqi",
    "America/Bogota",
    "America/Costa_Rica",
    "America/Havana",
    "Atlantic/Cape_Verde",
    "America/Curacao",
    "Indian/Christmas",
    "Asia/Nicosia",
    "Asia/Famagusta",
    "Europe/Prague",
    "Europe/Berlin",
    "Europe/Copenhagen",
    "America/Santo_Domingo",
    "Africa/Algiers",
    "America/Guayaquil",
    "Pacific/Galapagos",
    "Europe/Tallinn",
    "Africa/Cairo",
    "Africa/El_Aaiun",
    "Europe/Madrid",
    "Africa/Ceuta",
    "Atlantic/Canary",
    "Europe/Helsinki",
    "Pacific/Fiji",
    "Atlantic/Stanley",
    "Pacific/Chuuk",
    "Pacific/Pohnpei",
    "Pacific/Kosrae",
    "Atlantic/Faroe",
    "Europe/Paris",
    "Europe/London",
    "Asia/Tbilisi",
    "America/Cayenne",
    "Africa/Accra",
    "Europe/Gibraltar",
    "America/Godthab",
    "America/Danmarkshavn",
    "America/Scoresbysund",
    "America/Thule",
    "Europe/Athens",
    "Atlantic/South_Georgia",
    "America/Guatemala",
    "Pacific/Guam",
    "Africa/Bissau",
    "America/Guyana",
    "Asia/Hong_Kong",
    "America/Tegucigalpa",
    "America/Port-au-Prince",
    "Europe/Budapest",
    "Asia/Jakarta",
    "Asia/Pontianak",
    "Asia/Makassar",
    "Asia/Jayapura",
    "Europe/Dublin",
    "Asia/Jerusalem",
    "Indian/Chagos",
    "Asia/Baghdad",
    "Asia/Tehran",
    "Atlantic/Reykjavik",
    "Europe/Rome",
    "America/Jamaica",
    "Asia/Amman",
    "Asia/Tokyo",
    "Africa/Nairobi",
    "Asia/Bishkek",
    "Pacific/Tarawa",
    "Pacific/Enderbury",
    "Pacific/Kiritimati",
    "Asia/Pyongyang",
    "Asia/Seoul",
    "Asia/Almaty",
    "Asia/Qyzylorda",
    "Asia/Qostanay",
    "Asia/Aqtobe",
    "Asia/Aqtau",
    "Asia/Atyrau",
    "Asia/Oral",
    "Asia/Beirut",
    "Asia/Colombo",
    "Africa/Monrovia",
    "Europe/Vilnius",
    "Europe/Luxembourg",
    "Europe/Riga",
    "Africa/Tripoli",
    "Africa/Casablanca",
    "Europe/Monaco",
    "Europe/Chisinau",
    "Pacific/Majuro",
    "Pacific/Kwajalein",
    "Asia/Yangon",
    "Asia/Ulaanbaatar",
    "Asia/Hovd",
    "Asia/Choibalsan",
    "Asia/Macau",
    "America/Martinique",
    "Europe/Malta",
    "Indian/Mauritius",
    "Indian/Maldives",
    "America/Mexico_City",
    "America/Cancun",
    "America/Merida",
    "America/Monterrey",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Chihuahua",
    "America/Ojinaga",
    "America/Hermosillo",
    "America/Tijuana",
    "America/Bahia_Banderas",
    "Asia/Kuala_Lumpur",
    "Asia/Kuching",
    "Africa/Maputo",
    "Africa/Windhoek",
    "Pacific/Noumea",
    "Pacific/Norfolk",
    "Africa/Lagos",
    "America/Managua",
    "Europe/Amsterdam",
    "Europe/Oslo",
    "Asia/Kathmandu",
    "Pacific/Nauru",
    "Pacific/Niue",
    "Pacific/Auckland",
    "Pacific/Chatham",
    "America/Panama",
    "America/Lima",
    "Pacific/Tahiti",
    "Pacific/Marquesas",
    "Pacific/Gambier",
    "Pacific/Port_Moresby",
    "Pacific/Bougainville",
    "Asia/Manila",
    "Asia/Karachi",
    "Europe/Warsaw",
    "America/Miquelon",
    "Pacific/Pitcairn",
    "America/Puerto_Rico",
    "Asia/Gaza",
    "Asia/Hebron",
    "Europe/Lisbon",
    "Atlantic/Madeira",
    "Atlantic/Azores",
    "Pacific/Palau",
    "America/Asuncion",
    "Asia/Qatar",
    "Indian/Reunion",
    "Europe/Bucharest",
    "Europe/Belgrade",
    "Europe/Kaliningrad",
    "Europe/Moscow",
    "Europe/Simferopol",
    "Europe/Kirov",
    "Europe/Astrakhan",
    "Europe/Volgograd",
    "Europe/Saratov",
    "Europe/Ulyanovsk",
    "Europe/Samara",
    "Asia/Yekaterinburg",
    "Asia/Omsk",
    "Asia/Novosibirsk",
    "Asia/Barnaul",
    "Asia/Tomsk",
    "Asia/Novokuznetsk",
    "Asia/Krasnoyarsk",
    "Asia/Irkutsk",
    "Asia/Chita",
    "Asia/Yakutsk",
    "Asia/Khandyga",
    "Asia/Vladivostok",
    "Asia/Ust-Nera",
    "Asia/Magadan",
    "Asia/Sakhalin",
    "Asia/Srednekolymsk",
    "Asia/Kamchatka",
    "Asia/Anadyr",
    "Asia/Riyadh",
    "Pacific/Guadalcanal",
    "Indian/Mahe",
    "Africa/Khartoum",
    "Europe/Stockholm",
    "Asia/Singapore",
    "America/Paramaribo",
    "Africa/Juba",
    "Africa/Sao_Tome",
    "America/El_Salvador",
    "Asia/Damascus",
    "America/Grand_Turk",
    "Africa/Ndjamena",
    "Indian/Kerguelen",
    "Asia/Bangkok",
    "Asia/Dushanbe",
    "Pacific/Fakaofo",
    "Asia/Dili",
    "Asia/Ashgabat",
    "Africa/Tunis",
    "Pacific/Tongatapu",
    "Europe/Istanbul",
    "America/Port_of_Spain",
    "Pacific/Funafuti",
    "Asia/Taipei",
    "Europe/Kiev",
    "Europe/Uzhgorod",
    "Europe/Zaporozhye",
    "Pacific/Wake",
    "America/New_York",
    "America/Detroit",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Indiana/Indianapolis",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Vevay",
    "America/Chicago",
    "America/Indiana/Tell_City",
    "America/Indiana/Knox",
    "America/Menominee",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/North_Dakota/Beulah",
    "America/Denver",
    "America/Boise",
    "America/Phoenix",
    "America/Los_Angeles",
    "America/Anchorage",
    "America/Juneau",
    "America/Sitka",
    "America/Metlakatla",
    "America/Yakutat",
    "America/Nome",
    "America/Adak",
    "Pacific/Honolulu",
    "America/Montevideo",
    "Asia/Samarkand",
    "Asia/Tashkent",
    "America/Caracas",
    "Asia/Ho_Chi_Minh",
    "Pacific/Efate",
    "Pacific/Wallis",
    "Pacific/Apia",
    "Africa/Johannesburg",
  ];
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const emailTemplateContent = {
  counters: { u_column: 1, u_row: 1, u_content_text: 1 },
  body: {
    rows: [
      {
        cells: [1],
        columns: [
          {
            contents: [
              {
                type: "text",
                values: {
                  containerPadding: "10px",
                  _meta: {
                    htmlID: "u_content_text_1",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  color: "#000000",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  hideMobile: false,
                  text:
                    '<p style="font-size: 14px; line-height: 140%;"><span style="font-size: 14px; line-height: 19.6px;">This is a new Text block. Change the text.</span></p>',
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              _meta: { htmlID: "u_column_1", htmlClassNames: "u_column" },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: "u_row_1", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
        },
      },
    ],
    values: {
      backgroundColor: "#ffffff",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: false,
        center: true,
        cover: false,
      },
      contentWidth: "1240px",
      fontFamily: { label: "Arial", value: "arial,helvetica,sans-serif" },
      preheaderText: "",
      linkStyle: {
        body: true,
        linkColor: "#0000ee",
        linkHoverColor: "#0000ee",
        linkUnderline: true,
        linkHoverUnderline: true,
      },
      _meta: { htmlID: "u_body", htmlClassNames: "u_body" },
    },
  },
  schemaVersion: 5,
};

export const getAllowedTabs = (MI, user) => {
  //let tabs = ["admin", "auth", "opportunities", "docs", "emails", "tasks", "notes", "history"];
  let allowed_tabs = [];
  let tabs = [];

  if (user) {
    if (
      !["Workforces", "Contacts", "Prospects"].includes(MI.form.title) &&
      MI.from !== "leads"
    ) {
      tabs = [...tabs, "admin"];
    }

    if (["Workforces", "Contacts", "Prospects"].includes(MI.form.title)) {
      tabs = [...tabs, "auth", "emails"];
    }

    if (!!MI.MDID) {
      if (["Prospects"].includes(MI.form.title)) {
        tabs = [...tabs, "opportunities"];
      }

      tabs = [...tabs, "docs", "tasks", "notes", "payments"];

      if (user.type === "Workforces") {
        tabs = [...tabs, "history"];
      }

      /**
       * Date : 10-06-2023
       * Task : Market Place
       * Company: FinLend, LLC
       * Requested By : Rajesh
       */
      if (
        user?.company?.products?.includes("6565bcad878d175588408378") &&
        MI.form.type === "opportunity"
      ) {
        tabs = [...tabs, "marketplace"];
      }
    }

    tabs.forEach((t) => {
      if (user.type === "Workforces" && ["admin", "marketplace"].includes(t)) {
        allowed_tabs = [...allowed_tabs, t];
      } else {
        if (
          MI.permissions[t] &&
          (MI.permissions[t].own ||
            MI.permissions[t].global ||
            MI.permissions[t].update)
        ) {
          allowed_tabs = [...allowed_tabs, t];
        }
      }
    });
  }

  return allowed_tabs;
};

export const getElementValue = (elements, element, data) => {
  let value = "";

  if (element && data) {
    if (element.type === "radio" || element.type === "select") {
      element.options &&
        element.options.forEach((e) => {
          if (e.id === data) value = e.text;
        });
    } else if (element.type === "multiselect") {
      data.forEach((e) => {
        value += value === "" ? e.label : ", " + e.label;
      });
    } else if (element.type === "status") {
      data.forEach((e) => {
        value += value === "" ? e.title : ", " + e.title;
      });
    } else if (element.type === "checkbox") {
      element.options &&
        element.options.forEach((e) => {
          if (data.includes(e.id)) {
            value += value === "" ? e.label : ", " + e.label;
          }
        });
    } else if (element.type === "section") {
      value =
        "<div class='table-responsive' style='width: 300px;'><table className='table table-hover'>" +
        data.map(
          (p, i) =>
            "<thead><tr>" + (i === 0) &&
            Object.keys(p).map(
              (k, j) =>
                j !== 0 &&
                ("<th>" + elements[k] ? elements[k].title : k + "</th>")
            ) +
            "</tr></thead><tbody><tr>" +
            Object.keys(p).map(
              (k, j) =>
                j !== 0 &&
                "<td>" + getElementValue(elements[k], p[k]) + "</td>"
            ) +
            "</tr></tbody>"
        ) +
        "</table></div>";
    } else {
      value = data;
    }
  }
  return value;
};

export const getFileName = (n) => {
  let arr = n.split("-");
  let name = "";
  if (arr.length > 1) {
    arr.forEach((a, i) => {
      if (i !== 0) name += !!name ? "_" + a : a;
    });
  } else {
    name = n;
  }
  return name;
};

export const getFields = (config) => {
  let fields = [];
  Array.isArray(config) &&
    config.forEach((c) => {
      if (!c.cloneable) {
        if (
          c.type !== "container" &&
          c.type !== "grid" &&
          c.type !== "item" &&
          c.type !== "tab" &&
          c.type !== "separator" &&
          c.type !== "file"
        ) {
          fields = [...fields, c];
        }
        if (c.childs && !c.cloneable)
          fields = [...fields, ...getFields(c.childs)];
      }
    });
  return fields;
};

export const getObjectVal = (obj, field, val) => {
  if (obj && obj[field]) {
    return obj[field];
  } else {
    return val;
  }
};

export const getKeyValue = (obj, field) => {
  if (obj && obj[field]) {
    return obj[field];
  } else {
    return "";
  }
};

export const getBounceStatusDescription = (obj) => {
  switch (obj.bounceType) {
    case "Undetermined":
      return "We couldn't determine the reason for the bounce. Check the bounce email for more details.";

    case "Permanent":
      switch (obj.bounceSubType) {
        case "General":
          return "The recipient's email provider returned a hard bounce message.";

        case "NoEmail":
          return "We couldn't retrieve the recipient's email address from the bounce message.";

        case "Suppressed":
          return "The recipient's email address is temporarily blocked due to recent hard bounces.";

        case "OnAccountSuppressionList":
          return "Sending to this address is restricted. It does not count toward your bounce rate metric.";

        default:
          return "Email delivery failed. Please verify your recipient information and try again.";
      }

    case "Transient":
      switch (obj.bounceSubType) {
        case "General":
          return "The recipient's email provider returned a general bounce message. You might succeed in the future if the issue is resolved.";

        case "MailboxFull":
          return "The recipient's inbox is full. Try again when the mailbox is no longer full.";

        case "MessageTooLarge":
          return "The message you sent was too large. Try sending a smaller message.";

        case "ContentRejected":
          return "The recipient's email provider rejected the message content. Consider modifying the message.";

        case "AttachmentRejected":
          return "The message contained an unacceptable attachment. Try sending a message with a different or removed attachment.";

        default:
          return "Email delivery failed. Please check your recipient information and try again.";
      }

    default:
      return "Email delivery failed. Please check your recipient information and try again.";
  }
};

export const ConfirmBox = ({ show, onHide, onConfirm, msg }) => {
  return (
    <Modal
      show={show}
      size="md"
      onHide={onHide}
      backdrop="static"
      keyboard={false}
    >
      <div align="center" className="p-5">
        <h4>{msg}</h4>
        <button
          className="btn btn-sm btn-success mr-2 mt-2"
          onClick={onConfirm}
        >
          Yes
        </button>
        <button className="btn btn-sm btn-danger mt-2" onClick={onHide}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};
