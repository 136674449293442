import React from "react";
import { Grid } from "@material-ui/core";

import Elements from ".";

const Container = (props) => {
  return (
    <Grid container spacing={2}>
      {props.element.childs.map((child, i) => (
        <Elements
          element={child}
          module={props.module}
          events={props.events}
          key={i}
        />
      ))}
    </Grid>
  );
};

export default Container;
