import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Spinner } from "react-bootstrap";

import FilePicker from "./FilePicker";

import { backendApiUrl, formatBytes, getFileName } from "../../utils";

const DocsAccordion = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === props.title}
      onChange={handleChange(props.title)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            width: "350px",
            maxHeight: "250px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {props.data &&
            props.data.map(
              (d, i) =>
                d.files && (
                  <span key={i}>
                    <h5 style={{ margin: "10px" }}>{d.title}</h5>
                    {d.files.map((f, j) => (
                      <Grid
                        container
                        spacing={1}
                        key={j}
                        style={{
                          borderBottom: "1px solid #e2e5ec",
                          padding: "10px",
                        }}
                      >
                        <Grid
                          item
                          xs={2}
                          style={{
                            borderRight: "1px solid #e2e5ec",
                            textAlign: "center",
                          }}
                        >
                          <input
                            type="checkbox"
                            className="attachment_docs"
                            value={f._id + " | " + getFileName(f.name)}
                          />
                        </Grid>
                        <Grid item xs={10}>
                          {getFileName(f.name)} - {formatBytes(f.size, 2)}
                        </Grid>
                      </Grid>
                    ))}
                  </span>
                )
            )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const ContractAccordion = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === props.title}
      onChange={handleChange(props.title)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            width: "350px",
            maxHeight: "250px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {props.data &&
            props.data.map((d, i) => (
              <Grid
                container
                spacing={1}
                key={i}
                style={{
                  borderBottom: "1px solid #e2e5ec",
                  padding: "10px",
                }}
              >
                <Grid
                  item
                  xs={2}
                  style={{
                    borderRight: "1px solid #e2e5ec",
                    textAlign: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    className="attachment_contracts"
                    value={d._id + " | " + d.title}
                  />
                </Grid>
                <Grid item xs={10}>
                  {d.title}
                </Grid>
              </Grid>
            ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const Docs = (props) => {
  const [state, setState] = useState({
    load: true,
  });

  useEffect(() => {
    if (state.load) {
      axios
        .post(
          backendApiUrl("docs/list"),
          { module: props.module, owner: props.owner, form: props.form },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let st = { ...state, ...res.data };
          st.load = false;
          setState(st);
        })
        .catch((err) => {
          setState({ load: false });
        });
    }
  }, [state, props.module, props.owner, props.form]);

  return state.load ? (
    <div style={{ textAlign: "center" }}>
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <Grid container spacing={1}>
      {state.docs && state.docs.length > 0 && (
        <Grid item xs={4}>
          <DocsAccordion data={state.docs} title="Uploaded Documents" />
        </Grid>
      )}

      {state.contracts && state.contracts.length > 0 && (
        <Grid item xs={4}>
          <ContractAccordion data={state.contracts} title="Signed Contracts" />
        </Grid>
      )}

      <Grid item xs={4}>
        <FilePicker
          handleFileRead={props.handleFileRead}
          deleteFile={props.deleteFile}
        />
      </Grid>
    </Grid>
  );
};

export default Docs;
