import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import axios from "axios";
import { Spinner } from "react-bootstrap";

import { backendApiUrl, getElementValue } from "../../utils";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";

const History = (props) => {
  const [state, setState] = useState({ load: true, data: [] });
  let MI = { ...props.module };

  const getFields = (config) => {
    let fields = {};
    Array.isArray(config) &&
      config.forEach((c) => {
        if (
          c.type !== "container" &&
          c.type !== "grid" &&
          c.type !== "item" &&
          c.type !== "tab" &&
          c.type !== "file"
        ) {
          fields = { ...fields, [c.id]: c };
        }
        if (c.childs) fields = { ...fields, ...getFields(c.childs) };
      });
    return fields;
  };

  let fields_sections = getFields(MI.form.content);
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  const acceptRejectClickHandler = async (type, _id, field, value) => {
    await axios
      .patch(
        backendApiUrl("action_history/" + _id),
        { type, field },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (type === "accept") {
          MI.load = true;
          MI.data = { ...MI.data, [field]: value };
          props.events.submit(MI);
        }

        let st = { ...state };
        st.load = true;
        setState(st);
      });
  };

  useEffect(() => {
    if (state.load) {
      let module = MI.form._id;
      if (MI.from === "opportunities") module = "Opportunities";
      if (MI.from === "leads") module = "Leads";

      axios
        .post(
          backendApiUrl("action_history"),
          {
            module,
            _id: MI.MDID,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let st = { ...state };
          st.load = false;
          st.data = res.data.action_history;
          setState(st);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [MI.MDID, MI.form._id, MI.from, state, state.load]);

  return state.load ? (
    <div style={{ textAlign: "center" }}>
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <Portlet>
      <PortletHeader title="Action History" />
      <PortletBody style={{ maxHeight: "500px" }}>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th nowrap="true">Field</th>
                <th nowrap="true">Previous Value</th>
                <th nowrap="true">Current Value</th>
                <th nowrap="true">Need Review</th>
                <th nowrap="true">Requested By</th>
                <th nowrap="true">Updated At</th>
                <th nowrap="true">Reviewed By</th>
                <th nowrap="true">Reviewed At</th>
                <th nowrap="true">Actions</th>
              </tr>
            </thead>
            <tbody>
              {state.data.map((h) =>
                h.info.map((f, j) => (
                  <tr key={j}>
                    <td nowrap="true">
                      {fields_sections[f.field]
                        ? fields_sections[f.field].title
                        : ""}
                    </td>
                    <td nowrap="true">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getElementValue(fields_sections,
                            fields_sections[f.field],
                            f.previous_value
                          ),
                        }}
                      ></div>
                    </td>
                    <td nowrap="true">
                    <div
                        dangerouslySetInnerHTML={{
                          __html: getElementValue(fields_sections,
                            fields_sections[f.field],
                            f.current_value
                          ),
                        }}
                      ></div>
                    </td>
                    <td nowrap="true">{f.reviewer ? "Yes" : "No"}</td>
                    <td nowrap="true">{h.created_by.user}</td>
                    <td nowrap="true">{h.createdAt}</td>
                    <td nowrap="true">
                      {!!f.reviewed_by ? f.reviewed_by.user : ""}
                    </td>
                    <td nowrap="true">{!!f.reviewedAt ? f.reviewedAt : ""}</td>
                    <td nowrap="true">
                      {f.accept ? (
                        <button className="btn btn-success btn-sm mr-2">
                          Reviewed
                        </button>
                      ) : f.reject ? (
                        <button className="btn btn-danger btn-sm mr-2">
                          Rejected
                        </button>
                      ) : f.reviewer && h.created_by.info.id === user._id ? (
                        <button className="btn btn-primary btn-sm mr-2">
                          Waiting for Review
                        </button>
                      ) : (
                        f.reviewer &&
                        f.reviewer.includes(user.role._id) && (
                          <span>
                            <button
                              className="btn btn-primary btn-sm mr-2"
                              onClick={() =>
                                acceptRejectClickHandler(
                                  "accept",
                                  h._id,
                                  f.field,
                                  f.current_value
                                )
                              }
                            >
                              Accept
                            </button>
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() =>
                                acceptRejectClickHandler(
                                  "reject",
                                  h._id,
                                  f.field
                                )
                              }
                            >
                              Reject
                            </button>
                          </span>
                        )
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </PortletBody>
    </Portlet>
  );
};

export default History;
