import React from "react";
import { Grid } from "@material-ui/core";

import Elements from "./";

const GridItem = (props) => {
  return (
    <Grid item xs={12} sm md lg>
      {props.element.childs.map((child, i) => (
        <Elements
          element={child}
          module={props.module}
          events={props.events}
          key={i}
        />
      ))}
    </Grid>
  );
};

export default GridItem;
