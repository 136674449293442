import React from "react";
import objectPath from "object-path";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import { useDispatch, shallowEqual, useSelector } from "react-redux";

import { metronic } from "../../../_metronic";
import ToolsItemText from "./ToolsItemText";
import ToolsSubmenu from "./ToolsSubmenu";

const ToolsItem = (props) => {
  const dispatch = useDispatch();

  /** Get builder configuration */
  const {
    moduleBuilderConfig,
    opportunityBuilderConfig,
    webformBuilderConfig,
    libraryBuilderConfig,
  } = useSelector(
    ({ builder }) => ({
      moduleBuilderConfig: builder.moduleBuilderConfig,
      opportunityBuilderConfig: builder.opportunityBuilderConfig,
      webformBuilderConfig: builder.webformBuilderConfig,
      libraryBuilderConfig: builder.libraryBuilderConfig,
    }),
    shallowEqual
  );

  var configData = {};
  if (window.location.pathname === "/builders/modules/form") {
    configData = { ...moduleBuilderConfig };
  } else if (window.location.pathname === "/builders/opportunity/form") {
    configData = { ...opportunityBuilderConfig };
  } else if (window.location.pathname === "/builders/webforms/form") {
    configData = { ...webformBuilderConfig };
  } else if (window.location.pathname === "/builders/libraries/form") {
    configData = { ...libraryBuilderConfig };
  }

  const setBuilderConfig = (config) => {
    if (window.location.pathname === "/builders/modules/form") {
      dispatch(metronic.builder.actions.setModuleBuilder(config));
    } else if (window.location.pathname === "/builders/opportunity/form") {
      dispatch(metronic.builder.actions.setOpportunityBuilder(config));
    } else if (window.location.pathname === "/builders/webforms/form") {
      dispatch(metronic.builder.actions.setWebformBuilder(config));
    } else if (window.location.pathname === "/builders/libraries/form") {
      dispatch(metronic.builder.actions.setWebformBuilder(config));
    }
  };

  /** Add new element */
  var containerInfo = {};
  const bindNewElement = (configs, selectedId, element) => {
    let bindedConfig = {};
    let configCnt = 0;

    configs.forEach((config, i) => {
      if (config.id === selectedId) {
        element.parent = config.id;
        containerInfo = {
          containerId: config.parent,
          element: element,
          gridId: selectedId,
        };

        if (config.childs && config.childs.length) {
          configCnt = configs.length - 1;
        } else {
          containerInfo = {};
          config.childs = [element];
        }
      }

      if (configCnt > 0) {
        if (config.childs && config.childs.length) {
        } else {
          containerInfo = {};
          configCnt = 0;
          element.parent = config.id;
          config.childs = [element];
          configData.selectedId = config.id;
        }
      }

      if (config.childs && config.childs.length)
        config.childs = bindNewElement(config.childs, selectedId, element);

      if (bindedConfig.length) bindedConfig = [...bindedConfig, config];
      else bindedConfig = [config];
    });
    return bindedConfig;
  };

  const addElemnetToContentArea = () => {
    let reactiveElementId = "";
    if (props.item.type === "grid") {
      let uConId = uuidv4(); // Unique id generation
      let uGridId = uuidv4(); // Unique id generation

      configData.content = [
        ...configData.content,
        {
          type: "container",
          id: "container_" + uConId,
          childs: [
            {
              type: "grid",
              id: "grid_" + uGridId,
              parent: "container_" + uConId,
              col: 12,
              childs: [],
            },
          ],
        },
      ];
      setBuilderConfig(configData);
    } else {
      let newElement = {};
      if (props.item.type === "section") {
        let sid = uuidv4();
        let cid = uuidv4();
        let gid = uuidv4();

        newElement = {
          type: "section",
          id: "section_" + sid,
          title: "",
          childs: [
            {
              type: "container",
              id: "container_" + cid,
              parent: "section_" + sid,
              childs: [
                {
                  type: "grid",
                  id: "grid_" + gid,
                  parent: "container_" + cid,
                  col: 12,
                  childs: [],
                },
              ],
            },
          ],
        };
      } else if (props.item.type === "tab") {
        let t1 = uuidv4();

        let i1 = uuidv4();
        let i2 = uuidv4();

        let c2 = uuidv4();
        let c3 = uuidv4();
        let c4 = uuidv4();
        let c5 = uuidv4();

        let g2 = uuidv4();
        let g3 = uuidv4();
        let g4 = uuidv4();
        let g5 = uuidv4();
        let g6 = uuidv4();
        let g7 = uuidv4();

        let s1 = uuidv4();
        let s2 = uuidv4();

        newElement = {
          type: "tab",
          title: "tab_" + t1,
          id: "tab_" + t1,
          default: "item_" + i1,
          childs: [
            {
              type: "item",
              title: "Item_" + i1,
              id: "item_" + i1,
              parent: "tab_" + t1,
              childs: [
                {
                  type: "container",
                  title: "container_" + c2,
                  id: "container_" + c2,
                  parent: "item_" + i1,
                  childs: [
                    {
                      type: "grid",
                      title: "grid_" + g2,
                      id: "grid_" + g2,
                      col: 12,
                      parent: "container_" + c2,
                      childs: [
                        {
                          type: "section",
                          title: "section_" + s1,
                          id: "section_" + s1,
                          parent: "grid_" + g2,
                          childs: [
                            {
                              type: "container",
                              title: "container_" + c3,
                              id: "container_" + c3,
                              parent: "section_" + s1,
                              childs: [
                                {
                                  type: "grid",
                                  title: "grid_" + g3,
                                  id: "grid_" + g3,
                                  col: 6,
                                  parent: "container_" + c3,
                                  childs: [],
                                },
                                {
                                  type: "grid",
                                  title: "grid_" + g4,
                                  id: "grid_" + g4,
                                  col: 6,
                                  parent: "container_" + c3,
                                  childs: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "item",
              title: "Item_" + i2,
              id: "item_" + i2,
              parent: "tab_" + t1,
              childs: [
                {
                  type: "container",
                  title: "container_" + c4,
                  id: "container_" + c4,
                  parent: "item_" + i2,
                  childs: [
                    {
                      type: "grid",
                      title: "grid_" + g5,
                      id: "grid_" + g5,
                      col: 12,
                      parent: "container_" + c4,
                      childs: [
                        {
                          type: "section",
                          title: "section_" + s2,
                          id: "section_" + s2,
                          parent: "grid_" + g5,
                          childs: [
                            {
                              type: "container",
                              title: "container_" + c5,
                              id: "container_" + c5,
                              parent: "section_" + s2,
                              childs: [
                                {
                                  type: "grid",
                                  title: "grid_" + g6,
                                  id: "grid_" + g6,
                                  col: 6,
                                  parent: "container_" + c5,
                                  childs: [],
                                },
                                {
                                  type: "grid",
                                  title: "grid_" + g7,
                                  id: "grid_" + g7,
                                  col: 6,
                                  parent: "container_" + c5,
                                  childs: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        };
      } else if (
        props.item.element === undefined ||
        props.item.element === null
      ) {
        newElement = {
          type: props.item.type,
          title: "",
          id: uuidv4(),
        };
      } else {
        newElement = props.item.element;
        reactiveElementId = newElement.id;
      }
      configData.content = bindNewElement(
        configData.content,
        configData.selectedId,
        newElement
      );

      /** Find parent id */
      const findParentId = (configs, containerInfo) => {
        configs.forEach((config) => {
          if (config.id === containerInfo.containerId)
            containerInfo.parentId = config.parent;
          if (config.childs && config.childs.length)
            findParentId(config.childs, containerInfo);
        });
      };

      if (containerInfo.containerId)
        findParentId(configData.content, containerInfo);

      const AddNewElementInParent = (configs) => {
        var bindedConfig = "";
        configs.forEach((config, i) => {
          if (i === configs.length - 1) {
            let uConId = uuidv4(); // Unique id generation

            let newContainer = {
              type: "container",
              title: "container_" + uConId,
              id: "container_" + uConId,
              parent: config.id,
              childs: [],
            };
            config.childs.forEach((child, i) => {
              let newChild = { ...child };

              let uGridId = uuidv4(); // Unique id generation

              newChild.title = "grid_" + uGridId;
              newChild.id = "grid_" + uGridId;
              newChild.parent = "container_" + uConId;
              if (i === 0) {
                newChild.childs = [containerInfo.element];
                configData.selectedId = "grid_" + uGridId;
              } else {
                newChild.childs = [];
              }
              if (newContainer.childs && newContainer.childs.length) {
                newContainer.childs = [...newContainer.childs, newChild];
              } else {
                newContainer.childs = [newChild];
              }
            });

            if (bindedConfig.length)
              bindedConfig = [...bindedConfig, config, newContainer];
            else bindedConfig = [config, newContainer];
          } else {
            if (bindedConfig.length) bindedConfig = [...bindedConfig, config];
            else bindedConfig = [config];
          }
        });
        return bindedConfig;
      };

      if (containerInfo.parentId === "")
        configData.content = AddNewElementInParent(configData.content);

      // Bind element to next container
      const bindElementToNextContainer = (configs) => {
        var bindedConfig = "";
        configs.forEach((config) => {
          if (
            containerInfo.parentId.toLowerCase() === config.id.toLowerCase()
          ) {
            let elementBinded = false;
            config.childs.forEach((container) => {
              let bindedChild = [];
              container.childs.forEach((grid, i) => {
                if (grid.childs && !grid.childs.length && !elementBinded) {
                  grid.childs = [containerInfo.element];
                  elementBinded = true;

                  configData.selectedId = grid.id;
                }
                if (bindedChild.length) {
                  bindedChild = [...bindedChild, grid];
                } else {
                  bindedChild = [grid];
                }
              });
              container.childs = bindedChild;
            });

            if (!elementBinded) {
              const containerChilds = config.childs[config.childs.length - 1];

              let uConId = uuidv4(); // Unique id generation

              let newContainer = {
                type: "container",
                title: "container_" + uConId,
                id: "container_" + uConId,
                parent: config.id,
                childs: [],
              };

              containerChilds.childs.forEach((child, i) => {
                let newChild = { ...child };

                let uGridId = uuidv4(); // Unique id generation

                newChild.title = "grid_" + uGridId;
                newChild.id = "grid_" + uGridId;
                newChild.parent = "container_" + uConId;
                if (i === 0) {
                  newChild.childs = [containerInfo.element];
                  configData.selectedId = "grid_" + uGridId;
                } else {
                  newChild.childs = [];
                }
                if (newContainer.childs && newContainer.childs.length) {
                  newContainer.childs = [...newContainer.childs, newChild];
                } else {
                  newContainer.childs = [newChild];
                }
              });
              config.childs = [...config.childs, newContainer];
              elementBinded = true;
            }
          } else if (config.childs && config.childs.length) {
            config.childs = bindElementToNextContainer(config.childs);
          }

          if (bindedConfig.length) bindedConfig = [...bindedConfig, config];
          else bindedConfig = [config];
        });
        return bindedConfig;
      };

      if (containerInfo.parentId)
        configData.content = bindElementToNextContainer(configData.content);
      setBuilderConfig(configData);
    }

    // Reactive element in builder
    if (!!reactiveElementId) {
      let history = [];
      configData.history.forEach((h) => {
        if (reactiveElementId !== h.id) {
          history = [...history, h];
        }
      });
      configData.history = history;
      setBuilderConfig(configData);
    }
  };

  const asideLeftLIRef = React.createRef();
  const isDropdown = document.body.classList.contains(
    "kt-aside-menu--dropdown"
  );

  const submenuToggle =
    props.item.toggle === "click"
      ? "click"
      : objectPath.get(props.item, "submenu.type") === "tabs"
      ? "tabs"
      : "hover";

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param event Event
   */
  const mouseEnter = (event) => {
    if (!isDropdown) {
      return;
    }

    if (props.item.submenu) {
      asideLeftLIRef.current.classList.add("kt-menu__item--hover");
      asideLeftLIRef.current.setAttribute(
        "data-ktmenu-submenu-toggle",
        submenuToggle
      );
    }
  };

  /**
   * Mouse Leave event
   * @param event: MouseEvent
   */
  const mouseLeave = (event) => {
    if (!isDropdown) {
      return;
    }

    if (props.item.submenu && submenuToggle === "hover") {
      asideLeftLIRef.current.classList.remove("kt-menu__item--hover");
      asideLeftLIRef.current.removeAttribute("data-ktmenu-submenu-toggle");
    }
  };

  const isMenuItemIsActive = (item) => {
    if (item.submenu) {
      return isMenuRootItemIsActive(item);
    }

    if (!item.page) {
      return false;
    }

    //return props.currentUrl.indexOf(item.page) !== -1;
  };

  const isMenuRootItemIsActive = (item) => {
    for (const subItem of item.submenu) {
      if (isMenuItemIsActive(subItem)) {
        return true;
      }
    }

    return false;
  };

  const { item, currentUrl, parentItem, layoutConfig } = props;
  const isActive = isMenuItemIsActive(item);
  return (
    <li
      ref={asideLeftLIRef}
      aria-haspopup="true"
      data-placement="right"
      data-ktmenu-submenu-mode={item.mode}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      className={clsx(
        "kt-menu__item",
        {
          "kt-menu__item--submenu": item.submenu,
          "kt-menu__item--open kt-menu__item--here": isActive && item.submenu,
          "kt-menu__item--active kt-menu__item--here":
            isActive && !item.submenu,
          "kt-menu__item--icon-only": item["icon-only"],
        },
        item["custom-class"]
      )}
      data-ktmenu-dropdown-toggle-class={item["dropdown-toggle-class"]}
    >
      {props.item.isDrag ? (
        <div className="kt-menu__link kt-menu__toggle" name={item.type}>
          <ToolsItemText
            item={item}
            parentItem={parentItem}
            onClick={addElemnetToContentArea}
          />
        </div>
      ) : (
        <div className="kt-menu__link kt-menu__toggle">
          <ToolsItemText item={item} parentItem={parentItem} />
        </div>
      )}

      {item.submenu && (
        <div className="kt-menu__submenu">
          <span className="kt-menu__arrow" />

          {item["custom-class"] === "kt-menu__item--submenu-fullheight" && (
            <div className="kt-menu__wrapper">
              <ToolsSubmenu
                item={item}
                parentItem={item}
                currentUrl={currentUrl}
              />
            </div>
          )}

          {item["custom-class"] !== "kt-menu__item--submenu-fullheight" && (
            <ToolsSubmenu
              item={item}
              parentItem={item}
              currentUrl={currentUrl}
              layoutConfig={layoutConfig}
            />
          )}
        </div>
      )}
    </li>
  );
};

export default ToolsItem;
