const Config = [
  { value: "text/csv", label: "csv" },
  { value: "application/msword", label: "doc" },
  {
    value:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    label: "docx",
  },
  { value: "ext/html", label: "html" },
  { value: "image/jpeg", label: "jpeg" },
  { value: "image/jpeg", label: "jpg" },
  { value: "application/json", label: "json" },
  { value: "audio/mpeg", label: "mp3" },
  { value: "video/mpeg", label: "mpeg" },
  { value: "image/png", label: "png" },
  { value: "application/pdf", label: "pdf" },
  { value: "application/vnd.ms-powerpoint", label: "ppt" },
  {
    value:
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    label: "pptx",
  },
  { value: "text/plain", label: "txt" },
  { value: "application/vnd.ms-excel", label: "xls" },
  {
    value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    label: "xlsx",
  },
];

export default Config;
