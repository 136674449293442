import React from "react";
import objectPath from "object-path";
import clsx from "clsx";
import { Link } from "react-router-dom";

import MenuItemText from "./MenuItemText";
import MenuSubmenu from "./MenuSubmenu";

const MenuItem = (props) => {
  const { item, currentUrl, layoutConfig } = props;

  const asideLeftLIRef = React.createRef();
  const isDropdown = document.body.classList.contains(
    "kt-aside-menu--dropdown"
  );

  const submenuToggle =
    props.item.toggle === "click"
      ? "click"
      : objectPath.get(props.item, "submenu.type") === "tabs"
      ? "tabs"
      : "hover";

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param event Event
   */
  const mouseEnter = (event) => {
    if (!isDropdown) {
      return;
    }

    if (props.item.submenu) {
      asideLeftLIRef.current.classList.add("kt-menu__item--hover");
      asideLeftLIRef.current.setAttribute(
        "data-ktmenu-submenu-toggle",
        submenuToggle
      );
    }
  };

  /**
   * Mouse Leave event
   * @param event: MouseEvent
   */
  const mouseLeave = (event) => {
    if (!isDropdown) {
      return;
    }

    if (props.item.submenu && submenuToggle === "hover") {
      asideLeftLIRef.current.classList.remove("kt-menu__item--hover");
      asideLeftLIRef.current.removeAttribute("data-ktmenu-submenu-toggle");
    }
  };

  const isActive = currentUrl.includes(item.page);

  return (
    <li
      ref={asideLeftLIRef}
      aria-haspopup="true"
      data-placement="right"
      data-ktmenu-submenu-mode={item.mode}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      className={clsx(
        "kt-menu__item",
        {
          "kt-menu__item--submenu": item.submenu,
          "kt-menu__item--open kt-menu__item--here": isActive && item.submenu,
          "kt-menu__item--active kt-menu__item--here":
            isActive && !item.submenu,
          "kt-menu__item--icon-only": item["icon-only"],
        },
        item["custom-class"]
      )}
      data-ktmenu-dropdown-toggle-class={item["dropdown-toggle-class"]}
    >
      {item.page ? (
        <Link to={`/${item.page}`} className="kt-menu__link kt-menu__toggle">
          <MenuItemText item={item} />
        </Link>
      ) : (
        <div className="kt-menu__link kt-menu__toggle">
          <MenuItemText item={item} />
        </div>
      )}

      {item.submenu && (
        <div className="kt-menu__submenu">
          <span className="kt-menu__arrow" />

          {/* {item["custom-class"] === "kt-menu__item--submenu-fullheight" && (
            <div className="kt-menu__wrapper">
              <MenuSubmenu
                item={item}
                parentItem={item}
                currentUrl={currentUrl}
              />
            </div>
          )} */}

          {item["custom-class"] !== "kt-menu__item--submenu-fullheight" && (
            <MenuSubmenu
              item={item}
              parentItem={item}
              currentUrl={currentUrl}
              layoutConfig={layoutConfig}
            />
          )}
        </div>
      )}
    </li>
  );
};

export default MenuItem;
