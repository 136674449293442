import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import axios from "axios";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import { toAbsoluteUrl } from "../../../_metronic";
import { Radio, FormControlLabel, RadioGroup } from "@material-ui/core";
import { backendApiUrl } from "../../utils";

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });
  const [loginUserInfo, setLoginUserInfo] = useState({});

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const handleRoleChange = (e) => {
    axios
      .post(
        backendApiUrl("auth/generate_accesstoken"),
        {
          uid: loginUserInfo._id,
          cid: loginUserInfo.company,
          rid: e.target.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        props.login(res.data);
      })
      .catch((err) => { });
  };

  let titleTxt = "";
  if (window.location.hostname === "getfunded.revcaplending.com") {
    titleTxt = "Welcome to Revolution Realty Capital";
  } else {
    titleTxt = "Welcome to LendCRM";
  }

  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div
            className="kt-grid__item"
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            <Link to="/" className="kt-login__logo">
              {window.location.hostname === "getfunded.revcaplending.com" ? (
                <img
                  className="mt-5"
                  alt="Logo"
                  width="180"
                  height="30"
                  src={toAbsoluteUrl("/media/logos/revcap.png")}
                />
              ) : window.location.hostname === "portal.branchlending.com" || window.location.hostname === "localhost" ?
                <img
                  className="mt-5"
                  alt="Logo"
                  width="270"
                  height="120"
                  src={toAbsoluteUrl("/media/logos/branchlendingfavicon.png")}
                /> : (
                  <img
                    alt="Logo"
                    width="180"
                    height="100"
                    src={toAbsoluteUrl("/media/logos/LendCRM_Logo.png")}
                  />
                )}
            </Link>
          </div>
          <div className="login_container">
            {Object.keys(loginUserInfo).length > 0 ? (
              <>
                <h3>Do you want to login as ?</h3>
                <RadioGroup
                  aria-label="Gender"
                  name="gender1"
                  onChange={handleRoleChange}
                >
                  {loginUserInfo.roles.map((role, i) => (
                    <FormControlLabel
                      key={i}
                      value={role._id}
                      control={<Radio />}
                      label={role.title}
                    />
                  ))}
                </RadioGroup>
              </>
            ) : (
              <>
                <div className="kt-login__title">
                  <h3>{titleTxt}</h3>
                </div>
                <Formik
                  initialValues={{
                    username: "",
                    password: "",
                  }}
                  validate={(values) => {
                    const errors = {};

                    if (!values.username) {
                      // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                      errors.username = intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD",
                      });
                    }

                    if (!values.password) {
                      errors.password = intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD",
                      });
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setStatus, setSubmitting }) => {
                    enableLoading();
                    setTimeout(() => {
                      login(values.username, values.password)
                        .then((res) => {
                          disableLoading();
                          if (res.data.accessToken) {
                            props.login(res.data.accessToken);
                          } else {
                            setLoginUserInfo(res.data);
                          }
                        })
                        .catch((err) => {
                          disableLoading();
                          setSubmitting(false);
                          setStatus(
                            err.response
                              ? err.response.data.message
                              : intl.formatMessage({
                                id: "AUTH.VALIDATION.INVALID_LOGIN",
                              })
                          );
                        });
                    }, 1000);
                  }}
                >
                  {({
                    values,
                    status,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form
                      noValidate={true}
                      autoComplete="off"
                      className="kt-form"
                      onSubmit={handleSubmit}
                    >
                      {status ? (
                        <div role="alert" className="alert alert-danger">
                          <div className="alert-text">{status}</div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="form-group" style={{ fontSize: "25px" }}>
                        <TextField
                          type="username"
                          label="Username"
                          margin="normal"
                          className="kt-width-full"
                          name="username"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.username}
                          helperText={touched.username && errors.username}
                          error={Boolean(touched.username && errors.username)}
                          inputProps={{ style: { fontSize: 15 } }} // font size of input text
                          InputLabelProps={{ style: { fontSize: 15 } }} // font size of input label
                        />
                      </div>

                      <div className="form-group">
                        <TextField
                          type="password"
                          margin="normal"
                          label="Password"
                          className="kt-width-full"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          helperText={touched.password && errors.password}
                          error={Boolean(touched.password && errors.password)}
                          inputProps={{ style: { fontSize: 15 } }} // font size of input text
                          InputLabelProps={{ style: { fontSize: 15 } }} // font size of input label
                        />
                      </div>

                      <div className="kt-login__actions">
                        <Link
                          to="/auth/forgot-password"
                          className="kt-link kt-login__link-forgot"
                          style={{ fontSize: "18px" }}
                        >
                          Forgot Password ?
                        </Link>

                        <button
                          id="kt_login_signin_submit"
                          type="submit"
                          disabled={isSubmitting}
                          className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                            {
                              "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                            }
                          )}`}
                          style={loadingButtonStyle}
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>

                {/* <div className="kt-login__divider">
                  <div className="kt-divider">
                    <span />
                    <span>Or Sign in with;</span>
                    <span />
                  </div>
                </div>

                <div className="kt-login__options">
                  <Link
                    to="google.com"
                    className="btn btn-default kt-btn social_btn"
                  >
                    <i className="fab fa-linkedin" />
                    LinkedIn
                  </Link>
                  <Link
                    to="google.com"
                    className="btn btn-default kt-btn social_btn"
                  >
                    <i className="fab fa-google" />
                    Google
                  </Link>
                  <Link
                    to="google.com"
                    className="btn btn-default kt-btn social_btn"
                  >
                    <i className="fab fa-facebook" />
                    Facebook
                  </Link>
                </div> */}
              </>
            )}
          </div>

          {/* <div className="kt-login__head">
            <span className="kt-login__signup-label">
              Don't have an account yet?
            </span>
            &nbsp;&nbsp;
            <a
              href="https://calendly.com/lendstacks"
              className="kt-link kt-login__signup-link"
              style={{ fontSize: "16px" }}
            >
              Sign Up!
            </a>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
