import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { TextField } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";

import Message from "../../utils/Message";
import { backendApiUrl } from "../../utils";

const ResetPassword = () => {
  let user_id = useParams().user_id;

  const [msg, setMsg] = useState({});

  const [state, setState] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isCompleted, setIsCompleted] = useState(false);

  const handleChange = (event) => {
    let s = { ...state };
    s[event.target.name] = event.target.value;
    setState(s);
  };

  const handleSubmitting = async () => {
    if (!state.password || state.password === undefined) {
      setMsg({
        status: 500,
        msg: "Please enter the password.",
        show: true,
      });
      return true;
    }
    if (!state.confirmPassword || state.confirmPassword === undefined) {
      setMsg({
        status: 500,
        msg: "Please enter the confirm password.",
        show: true,
      });
      return true;
    }
    if (state.password !== state.confirmPassword) {
      setMsg({
        status: 500,
        msg: "Password and Confirm Password didn't match.",
        show: true,
      });
      return true;
    }

    setIsSubmitting(true);

    await axios
      .post(
        backendApiUrl("auth/reset_password"),
        { user_id, password: state.password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setMsg({
          status: 200,
          msg: "Your password has been reset successfully.",
          show: true,
        });

        setTimeout(() => setIsCompleted(true), 3000);
      })
      .catch((e) => {
        setMsg({
          status: 200,
          msg: "Problem while reset password, please try again.",
          show: true,
        });
      });
  };

  if (isCompleted) {
    return <Redirect to="/auth" />;
  } else {
    return (
      <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
        <div className="kt-login__body">
          <div className="kt-login__form">
            {msg.show && (
              <Message status={msg.status} setMsg={setMsg} msg={msg.msg} />
            )}
            <div className="kt-login__title">
              <h3>Reset Password</h3>
            </div>

            <div className="form-group">
              <TextField
                type="text"
                label="New Password"
                margin="normal"
                fullWidth={true}
                name="password"
                onChange={handleChange}
                value={state.password}
              />
            </div>
            <div className="form-group">
              <TextField
                type="text"
                label="Confirm Password"
                margin="normal"
                fullWidth={true}
                name="confirmPassword"
                onChange={handleChange}
                value={state.confirmPassword}
              />
            </div>
            <div className="kt-login__actions">
              <Link to="/auth">
                <button
                  type="button"
                  className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                >
                  Back
                </button>
              </Link>
              <button
                type="button"
                onClick={handleSubmitting}
                className="btn btn-primary btn-elevate kt-login__btn-primary"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ResetPassword;
