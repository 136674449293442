import React from "react";
import { Badge } from "react-bootstrap";

import { getDate } from "../utils";

const AssignedTasks = (props) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th nowrap="true">Title</th>
            <th nowrap="true">Status</th>
            <th nowrap="true">Start Date</th>
            <th nowrap="true">Due Date</th>
            <th nowrap="true">Assigned Users</th>
            <th nowrap="true">Priority</th>
            <th nowrap="true">Module</th>
            <th nowrap="true">Created Date</th>
            <th nowrap="true">Created By</th>
            <th nowrap="true">Updated Date</th>
            <th nowrap="true">Updated By</th>
          </tr>
        </thead>
        <tbody>
          {props.data.length ? (
            props.data.map((t, i) => (
              <tr key={i}>
                <td>{1 + i}</td>
                <td>{t.title}</td>
                <td>
                  {t.status === "Not Started" && (
                    <Badge variant="danger">{t.status}</Badge>
                  )}
                  {t.status === "In Progress" && (
                    <Badge variant="primary">{t.status}</Badge>
                  )}
                  {t.status === "Awaiting Feedback" && (
                    <Badge variant="warning">{t.status}</Badge>
                  )}
                  {t.status === "On Hold" && (
                    <Badge variant="info">{t.status}</Badge>
                  )}
                  {t.status === "Completed" && (
                    <Badge variant="success">{t.status}</Badge>
                  )}
                </td>
                <td>{getDate(t.start_date)}</td>
                <td>{getDate(t.due_date)}</td>
                <td>
                  {t.users &&
                    t.users.map((w, i) => (
                      <span key={i}>
                        {w.label}
                        <br />
                      </span>
                    ))}
                </td>
                <td>
                  {t.priority === "Low" && (
                    <Badge variant="secondary">{t.priority}</Badge>
                  )}
                  {t.priority === "Medium" && (
                    <Badge variant="warning">{t.priority}</Badge>
                  )}
                  {t.priority === "High" && (
                    <Badge variant="primary">{t.priority}</Badge>
                  )}
                  {t.priority === "Urgent" && (
                    <Badge variant="danger">{t.priority}</Badge>
                  )}
                </td>
                <td>{t.module ? t.module.title + " (" + t.module.type + ")": ""}</td>
                <td>{t.createdAt}</td>
                <td>{t.created_by.user}</td>
                <td>{t.updatedAt}</td>
                <td>{t.updated_by.user}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={12}>
                <h5 className="text-primary text-center">
                  No tasks available, Create one!
                </h5>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AssignedTasks;
