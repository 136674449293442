import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { backendApiUrl } from ".";
import { PlaidLink } from "react-plaid-link";
import {
	Portlet,
	PortletBody,
	PortletHeader,
} from "../partials/content/Portlet";

export default function LinkPlaid({ prospectId, setMsg }) {

	const [token, setToken] = useState("");
	const onSuccess = (publicToken, metadata) => {
		const data = {
			public_token: publicToken,
			metadata: metadata,
			prospectId: prospectId,
		}
		axios
			.post(backendApiUrl("plaid/attach_tokens_to_prospect"), data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				setMsg({
					status: 200,
					msg: res.data.message,
					show: true,
				});
			})
			.catch((err) => {
				setMsg({
					status: 500,
					msg: err.message,
					show: true,
				});
			});
	}

	const onEvent = (eventName, metadata) => {
		console.log(eventName, metadata);
	}
	const onExit = () => {
		console.log("Exited");
	}

	const getPlaidLinkToken = useCallback(() => {
		axios
			.get(backendApiUrl("plaid/get_plaid_link_token"))
			.then((res) => {
				console.log(res.data.linkToken);
				setToken(res.data.linkToken);
			})
			.catch((err) => {
				setMsg({
					status: 500,
					msg: err.message,
					show: true,
				});
			});
	}, [setMsg]);

	useEffect(() => {
		getPlaidLinkToken();
	}, [getPlaidLinkToken]);
	return (
		<Portlet>
			<PortletHeader
				title="Assets Report"
			/>
			<PortletBody>
				<div style={{ width: "119px", margin: "0px auto" }}>
					<PlaidLink
						token={token}
						onSuccess={onSuccess}
						onEvent={onEvent}
						onExit={onExit}
						style={{ width: "100%", background: "rgb(45 50 111)", color: "white" }}
					>
						<span>Link Now</span>
					</PlaidLink>
				</div>
			</PortletBody>
		</Portlet>
	);
}