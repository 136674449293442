import React from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../partials/content/Portlet";
import Elements from "./";

const Section = (props) => {
  return (
    <>
      <Portlet>
        <PortletHeader
          title={
            props.element.id === props.element.title ? "" : props.element.title
          }
        />
        <PortletBody>
          {props.element.childs.map((child, i) => (
            <Elements
              element={child}
              module={props.module}
              events={props.events}
              key={i}
            />
          ))}
        </PortletBody>
      </Portlet>
      <br />
    </>
  );
};
export default Section;
