import React from "react";
import ToolsSection from "./ToolsSection";
import ToolsItemSeparator from "./ToolsItemSeparator";
import ToolsItem from "./ToolsItem";

export default class ToolsSubmenu extends React.Component {
  render() {
    const { item, currentUrl, layoutConfig } = this.props;

    return (
      <ul className="kt-menu__subnav">
        {item.submenu.map((child, index) => (
          <React.Fragment key={index}>
            {child.section && (
              <ToolsSection
                item={child}
                parentItem={item}
                currentUrl={currentUrl}
              />
            )}

            {child.separator && (
              <ToolsItemSeparator
                item={child}
                parentItem={item}
                currentUrl={currentUrl}
              />
            )}

            {child.title && (
              <>
                <ToolsItem
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                  layoutConfig={layoutConfig}
                />
              </>
            )}
          </React.Fragment>
        ))}
      </ul>
    );
  }
}
