import React, { useState, useEffect } from "react";
import axios from "axios";
// import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { Tab, Tabs } from "react-bootstrap";
import { Spinner } from "react-bootstrap";

import "../../_metronic/_assets/sass/app_tab.scss";
import { backendApiUrl } from "../utils";
import Counter from "./Counter";
import PieChart from "./PieChart";
import AssignedTasks from "./AssignedTasks";
import RecentOpportunities from "./RecentOpportunities";
import RecentLeads from "./RecentLeads";
import RecentProspects from "./RecentProspects";

import { Portlet, PortletBody } from "../partials/content/Portlet";
import { shallowEqual, useSelector } from "react-redux";

const Dashboard = () => {
  const [state, setState] = useState({ load: true });

  const [tab, setTab] = useState("recent_opportunities");

  let { menuConfig } = useSelector(
    ({ builder }) => ({
      menuConfig: builder.menuConfig,
    }),
    shallowEqual
  );
  let leadAccess = false;
  let loanAccess = false;
  let prospectAccess = false;
  let prospectLabel = "Prospects";
  let contactLabel = "Contacts";
  let workforceLabel = "Workfoces";
  menuConfig &&
    menuConfig.header.items.forEach((hi) => {
      if (hi.title === "Prospects") {
        prospectAccess = true;
        prospectLabel = hi.custom_title ? hi.custom_title : hi.title;
      } else if (hi.title === "Contacts") {
        contactLabel = hi.custom_title ? hi.custom_title : hi.title;
      } else if (hi.title === "Workforces") {
        workforceLabel = hi.custom_title ? hi.custom_title : hi.title;
      } else if (hi.title === "Leads") {
        leadAccess = true;
      } else if (hi.title === "Loans") {
        loanAccess = true;
      }
    });
  useEffect(() => {
    if (state.load) {
      axios
        .post(
          backendApiUrl("dashboard"),
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let st = { ...state, ...res.data };
          st.load = false;
          setState(st);
          if (
            res.data.company &&
            res.data.company._id === "639bf20fb276620c63063031"
          ) {
            setTab("recent_opportunities");
          }
        })
        .catch((err) => {
          let st = { ...state };
          st.load = false;
          setState(st);
        });
    }
  }, [state]);

  return state.load ? (
    <div style={{ textAlign: "center" }}>
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <Grid container spacing={2}>
      {state.lead_counts &&
        state.lead_counts.length > 0 &&
        state.company._id !== "64acf8bd1460171a5013575c" &&
        state.company._id !== "6462186cafb539718f836cfb" &&
        leadAccess === true && (
          <Grid item xs={12}>
            <Counter
              title="Leads"
              url={"/leads?webform="}
              data={state.lead_counts}
            />
          </Grid>
        )}
      {state.prospect_count &&
        state.prospect_count.length > 0 &&
        prospectAccess === true && (
          <Grid item xs={12}>
            <Counter
              title={prospectLabel}
              url={"/prospects?status="}
              data={state.prospect_count}
            />
          </Grid>
        )}
      {state.opportunity_count && state.opportunity_count.length > 0 && (
        <Grid item xs={12}>
          <Counter
            title="Loans"
            url={"/loans?status="}
            data={state.opportunity_count}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Portlet>
          <PortletBody className="p-2">
            <div className="tabs tabs-style-bar mb-3">
              <Tabs activeKey={tab} onSelect={(key) => setTab(key)}>
                {loanAccess && (
                  <Tab
                    eventKey="recent_opportunities"
                    title="Most Recent Loans"
                  >
                    <RecentOpportunities
                      data={state.recent_opportunities}
                      prospectLabel={prospectLabel}
                      workforceLabel={workforceLabel}
                      contactLabel={contactLabel}
                    />
                  </Tab>
                )}
                <Tab eventKey="tasks" title="Assigned Tasks">
                  <AssignedTasks data={state.tasks} />
                </Tab>
                {state.company._id !== "64acf8bd1460171a5013575c" &&
                  state.company._id !== "6462186cafb539718f836cfb" &&
                  leadAccess === true && (
                    <Tab eventKey="recent_leads" title="Most Recent Leads">
                      <RecentLeads data={state.recent_leads} />
                    </Tab>
                  )}
                {prospectAccess && (
                  <Tab
                    eventKey="recent_prospects"
                    title={
                      state.company._id === "64acf8bd1460171a5013575c"
                        ? "Most Recent Applicants"
                        : "Most Recent " + prospectLabel
                    }
                  >
                    <RecentProspects data={state.recent_prospects} />
                  </Tab>
                )}
              </Tabs>
            </div>
          </PortletBody>
        </Portlet>
      </Grid>

      {state.programs && state.programs.length > 0 && (
        <Grid item xs={6}>
          <PieChart
            title="Loan Programs"
            subTitle="Loans"
            data={state.programs}
          />
        </Grid>
      )}
      {state.lead_sources &&
        state.lead_sources.length > 0 &&
        state.company._id !== "64acf8bd1460171a5013575c" &&
        state.company._id !== "6462186cafb539718f836cfb" &&
        leadAccess === true && (
          <Grid item xs={6}>
            <PieChart
              title="Lead Sources"
              subTitle="Leads"
              data={state.lead_sources}
            />
          </Grid>
        )}
      <hr />
    </Grid>
  );
};

export default Dashboard;
