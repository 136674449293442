import React from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

import { Portlet, PortletBody } from "../partials/content/Portlet";

const StatusCounts = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h4 className="text-primary">
          {props.title}
          <hr />
        </h4>
      </Grid>
      {props.data &&
        props.data.map((s, i) => (
          <Grid item key={i} xs={2}>
            <Portlet>
              <PortletBody className="p-3">
                <Link to={props.url + s._id} style={{ cursor: "pointer" }}>
                  <h2>{s.count}</h2>
                </Link>
                {s.label}
              </PortletBody>
            </Portlet>
          </Grid>
        ))}
    </Grid>
  );
};

export default StatusCounts;
