import React, { useEffect, useCallback } from "react";

import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import '../style.css'
import { sendEmail } from "./services";
import { backendApiUrl } from "../../utils";
import { Accordion, AccordionDetails, AccordionSummary, Typography, FormControl, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from "axios";

export default function Card({ sumPayments, props, formatDollar, loadPayments, client_secret, paymentsData, updateMultipleFormData }) {
  // console.log(client_secret);
  const usestripe = useStripe();
  const elements = useElements();
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!usestripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      updateMultipleFormData([
        { key: "loader", value: true },
        { key: "msg", subKey: "show", value: true },
        { key: "msg", subKey: "msg", value: "payout failure please contact administator" },
        { key: "msg", subKey: "status", value: 500 },
      ])
      return;
    }

    const result = await usestripe.confirmCardPayment(client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: props.user.fullName,
        },
      }
    });

    if (result.error) {
      // Show error to your customer (for example, insufficient funds)
      console.log(result.error.message);
      updateMultipleFormData([
        { key: "msg", subKey: "showPaymentMethod", value: true },
        { key: "loader", value: false },
        { key: "msg", subKey: "msg", value: result.error.message },
        { key: "msg", subKey: "status", value: 500 },
      ])
    } else {
      console.log(result);
      // The payment has been processed!



      if (result.paymentIntent.status === 'succeeded') {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        const dataAttach = {
          customerId: paymentsData.formData.data.stripeCusId,
          stripeId: paymentsData.formData.stripeConnectedAcc,
          pmId: result.paymentIntent.payment_method
        }
        if (paymentsData.formData.saveCard) {
          axios
            .post(backendApiUrl("payments/attach_payment_to_customer"), dataAttach, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((res) => {

            })
            .catch((err) => {
              updateMultipleFormData([
                { key: "msg", subKey: "show", value: true },
                { key: "msg", subKey: "msg", value: err.message },
                { key: "msg", subKey: "status", value: 500 },
              ])
            });
        }

        setTimeout(() => {
          loadPayments();
          updateMultipleFormData([
            { key: "showPaymentMethod", value: false },
            { key: "loader", value: false },
            { key: "msg", subKey: "show", value: true },
            { key: "msg", subKey: "msg", value: "Payment processed successfully" },
            { key: "msg", subKey: "status", value: 200 },
          ])
        }, 1000);

        sendEmail({ paymentsData, amount: sumPayments(paymentsData.formData.selectedPayments.payments), paymentType: "CARD" });
      }
    }
  };

  const getStripeList = useCallback(() => {
    const getStripeCardListCard = {
      customerId: paymentsData.formData.data.stripeCusId,
      stripeId: paymentsData.formData.stripeConnectedAcc,
    }
    axios
      .post(backendApiUrl("payments/get_stripe_card_list"), getStripeCardListCard, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        updateMultipleFormData([
          { key: "cardList", value: res.data.cardList.data },
        ])
        // setToken(res.data.linkToken);
      })
      .catch((err) => {
        // updateMultipleFormData([
        //   { key: "msg", subKey: "show", value: true },
        //   { key: "msg", subKey: "msg", value: err.message },
        //   { key: "msg", subKey: "status", value: 500 },
        // ])
      });
  }, [paymentsData, updateMultipleFormData]);




  useEffect(() => {
    getStripeList();
  }, [getStripeList]);

  const StyledRadio = (props) => {
    return (
      <Radio
        disableRipple
        color="default"
        {...props}
      />
    );
  }

  const paymentSource = (d, e) => {
    const { id } = d;
    updateMultipleFormData([
      { key: "selectedSource", value: id },
    ])
  }

  const confirmCardPayment = () => {

    updateMultipleFormData([
      { key: "loader", value: true },
    ])
    const data = {
      customerId: paymentsData.formData.data.stripeCusId,
      amount: sumPayments(paymentsData.formData.selectedPayments.payments) * 100,
      selectedPayments: paymentsData.formData.selectedPayments,
      stripeId: paymentsData.formData.stripeConnectedAcc,
      source: paymentsData.formData.selectedSource,
    }
    axios
      .post(backendApiUrl("payments/charge_ach_payment"), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setTimeout(() => {
          loadPayments();
          updateMultipleFormData([
            { key: "achConfirmPopup", value: false },
            { key: "showPaymentMethod", value: false },
            { key: "loader", value: false },
            { key: "msg", subKey: "show", value: true },
            { key: "msg", subKey: "show", value: true },
            { key: "msg", subKey: "msg", value: "Payment processed successfully" },
            { key: "msg", subKey: "status", value: 200 },
          ])
        }, 1000);

        sendEmail({ paymentsData, amount: sumPayments(paymentsData.formData.selectedPayments.payments), paymentType: "ACH" });
      })
      .catch((err) => {
        updateMultipleFormData([
          { key: "msg", subKey: "show", value: true },
          { key: "loader", value: false },
          { key: "msg", subKey: "msg", value: err.message },
          { key: "msg", subKey: "status", value: 500 },
        ])
      });
  }

  return (
    <div className="card-section">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Payment Summary <span>{formatDollar(sumPayments(paymentsData.formData.selectedPayments.payments))}</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Item</th>
                <th nowrap="true">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {
                paymentsData.formData.selectedPayments.payments.map((n, i) => (
                  <tr key={i}>
                    <td>{n.item}</td>
                    <td>{formatDollar(n.amount.replace(/,/, ""))}</td>
                  </tr>
                ))
              }
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td>{formatDollar(sumPayments(paymentsData.formData.selectedPayments.payments))}</td>
              </tr>
            </tfoot>
          </table>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Saved Card Details</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: "block" }}>
          {paymentsData.formData.cardList.length ?
            (
              <>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="Bank Accounts" name="customized-radios">
                    {paymentsData.formData.cardList.map((d, i) => (
                      <FormControlLabel value={d.id} key={i} onChange={e => paymentSource(d, e)} control={<StyledRadio />} label={`**** **** **** ${d.last4}`} />
                    ))}
                  </RadioGroup>
                </FormControl>
                <div className="col-6">
                  <button type="button" onClick={(e) => confirmCardPayment(e)}>Pay Now</button>
                </div>
              </>
            ) : (
              <div>Saved card details are empty add a new card details below to make card payment.</div>
            )
          }
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Add New Card</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: "block" }}>
          <form onSubmit={handleSubmit}>

            {/* {paymentsData.formData.msg.show && (
              <Message
                status={paymentsData.formData.msg.status}
                setMsg={(e) =>
                  updateMultipleFormData([
                    { key: "msg", subKey: "show", value: false },
                    { key: "msg", subKey: "msg", value: "" },
                    { key: "msg", subKey: "status", value: 200 },
                  ])
                }
                msg={paymentsData.formData.msg.msg}
              />
            )} */}

            <label style={{ width: "100%" }}>
              Card details
              <CardElement options={CARD_ELEMENT_OPTIONS} />

              <div className="form-inline">
                <div className="form-check form-check-inline">
                  <input
                    type="checkbox"
                    id="saveCard"
                    name="saveCard"
                    value={paymentsData.formData.saveCard}
                    onChange={(e) =>
                      updateMultipleFormData([
                        { key: "saveCard", value: !paymentsData.formData.saveCard },
                      ])
                    }
                    className="sel_all PS_mandatory"
                  />
                  <label className="form-check-label" htmlFor="saveCard"> Save Card</label>
                </div>
              </div>
            </label>
            <button disabled={!usestripe}>Pay Now</button>

          </form>
        </AccordionDetails>
      </Accordion>
    </div>

  );
}