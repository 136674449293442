import React from "react";
import MenuItem from "./MenuItem";

const MenuList = ({ currentUrl, menus }) => {
  return menus.map(
    (child, index) =>
      child.show_menu !== false && (
        <React.Fragment key={`menuList${index}`}>
          {!!child.title && !child.section && (
            <MenuItem item={child} currentUrl={currentUrl} />
          )}
        </React.Fragment>
      )
  );
};

export default MenuList;
