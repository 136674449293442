import React from "react";
import SignatureCanvas from "react-signature-canvas";
import FormGroup from "./utils/FormGroup";

const Signature = (props) => {
  return (
    <FormGroup element={props.element} module={props.module}>
      <div style={{ border: "1px solid red" }}>
        <SignatureCanvas
          penColor="green"
          canvasProps={{ width: 500, height: 50, className: "sigCanvas" }}
        />
      </div>
    </FormGroup>
  );
};
export default Signature;
