import React from "react";
import Chart from "react-google-charts";
import { connect } from "react-redux";

import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../partials/content/Portlet";

const PieChart = (props) => {
  let options = {
    title: props.subTitle,
    is3D: true,
  };
  // Dark mode changes
  if (
    props.menuConfig.personalization &&
    props.menuConfig.personalization.mode === "Dark"
  ) {
    options.backgroundColor = "#1e1e1e";
    options.legend = { textStyle: { color: "#ffffff" } };
    options.titleTextStyle = {
      color: "#ffffff",
    };
  }

  return (
    <Portlet>
      <PortletHeader title={props.title} />
      <PortletBody className="p-2">
        <Chart
          width={"775px"}
          height={"500px"}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={props.data}
          options={options}
          rootProps={{ "data-testid": "2" }}
        />
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  menuConfig: store.builder.menuConfig,
});

export default connect(mapStateToProps)(PieChart);
