import React from "react";
import { Badge } from "react-bootstrap";
import store from "../../store/store";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";

const Templates = (props) => {
  const {
    auth: { authToken },
  } = store.getState();

  return (
    <>
      {props.templates && props.templates.length > 0 && (
        <div className="mt-3">
          <Portlet>
            <PortletHeader
              title="Contracts Templates"
            />
            <PortletBody>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th nowrap="true">Title</th>
                      <th nowrap="true">Subject</th>
                      <th nowrap="true">Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.templates.map((f, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{f.title}</td>
                        <td>{f.subject}</td>
                        <td>{f.description}</td>
                        <td>
                          {/* {f.doctype === "spreadsheet"?"":""} */}
                          {f.doctype !== "customdocs" && (
                            <a
                              href={`/builders/contracts/builder/preview/${
                                f._id
                              }/${props.MI.form._id}/${
                                props.MI.MDID
                              }/${encodeURIComponent(window.location.href)}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Badge variant="success">
                                <i className="fa fa-eye fa-lg mr-2" />
                                Preview Contract
                              </Badge>
                            </a>
                          )}
                          &nbsp;
                          {f.doctype !== "spreadsheet" && (
                            <a
                              href={
                                process.env.REACT_APP_DOC_URL +
                                "document/" +
                                f._id +
                                "?m=" +
                                props.MI.MDID +
                                "&f=" +
                                props.MI.form._id +
                                "&t=" +
                                authToken +
                                "&url=" +
                                window.location.href
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Badge variant="success">
                                <i className="fa fa-paper-plane fa-lg" />
                                &nbsp;&nbsp;Click to generate contract
                              </Badge>
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </PortletBody>
          </Portlet>
        </div>
      )}
    </>
  );
};

export default Templates;
