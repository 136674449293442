import { v4 as uuidv4 } from "uuid";
export const initialData = (props) => {
  return {
    formData: {
      loader: true,
      data: { payments: [], tokens: false, stripeCusId: "" },
      cardList: [],
      msg: {
        show: false,
        status: false,
        setMsg: "",
        msg: "",
      },
      amount: "",
      saveCard: false,
      paymentFrequency: "",
      applicationFeeAmt: 10,
      showPayments: false,
      achConfirmPopup: false,
      paymentMethod: { ach: false, card: false, },
      showPaymentMethod: "",
      showSubscriptions: false,
      selectedSource: "",
      client_secret: "",
      showStripeBankAccountModal: false,
      bank_routing_number: "",
      bank_account_number: "",
      bank_account_name: "",
      workforce: "",
      selectedPayments: [],
      reminderBefore: "5",
      deletePaymentId: "",
      includeExcludeProcessingFee: false,
      subscriptions: [],
      subscriptionsLoader: false,
      stripeConnectedAcc: "",
      paymentAmount: 0.00,
      paymentId: "",
      lateFeePercentage: 0.00,
      billingInterval: "month",
      subscriptionStartMonth: new Date(),
      subscriptionEndMonth: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
      recurringDate: "01",
      cappedAt: 10,
      openFilter: false,
      payments:
        props.payments && props.payments.length > 0
          ? props.payments
          : [
            {
              _id: uuidv4(),
              item: "",
              amount: "",
            },
          ],
    }
  }
};
