import React from "react";
import { shallowEqual, useSelector } from "react-redux";

const Logo = () => {
  let { menuConfig } = useSelector(
    ({ builder }) => ({
      menuConfig: builder.menuConfig,
    }),
    shallowEqual
  );

  return (
    <div className="kt-header-logo">
      {menuConfig.logo ? (
        <img
          alt="Company Logo"
          width="auto"
          height={window.location.hostname === "getfunded.revcaplending.com" ? "30" : "60"}
          src={`data:image/png;base64,${menuConfig.logo}`}
        />
      ) : (
        <h2>
        <span style={{color: "#fff"}}>Lend</span><span className="text-danger">CRM</span>
        </h2>
      )}
    </div>
  );
};

export default Logo;
