import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import axios from "axios";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import { toAbsoluteUrl } from "../../../_metronic";
import { Radio, FormControlLabel, RadioGroup } from "@material-ui/core";
import { backendApiUrl } from "../../utils";

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });
  const [loginUserInfo, setLoginUserInfo] = useState({});

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const handleRoleChange = (e) => {
    axios
      .post(
        backendApiUrl("auth/generate_accesstoken"),
        {
          uid: loginUserInfo._id,
          cid: loginUserInfo.company,
          rid: e.target.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        props.login(res.data);
      })
      .catch((err) => {});
  };

  let titleTxt = "Welcome to MIRS Group";

  return (
    <>
      <div className="row">
        <div className="col-md-4 pt-5">
          <h6 style={{ whiteSpace: "pre-wrap" }}>
            <a
              href="https://www.mirsgroupllc.com/work"
              rel="noopener noreferrer"
              className="text-primary mr-3"
              tabIndex="0"
            >
              Projects
            </a>
            <a
              href="https://www.mirsgroupllc.com/reviews"
              rel="noopener noreferrer"
              className="text-primary mr-3"
              tabIndex="0"
            >
              Review
            </a>
            <a
              href="https://www.mirsgroupllc.com/blog"
              rel="noopener noreferrer"
              className="text-primary mr-3"
              tabIndex="0"
            >
              Blog
            </a>
          </h6>
        </div>
        <div className="col-md-4" align="center">
          <Link to="/">
            <a href="https://www.mirsgroupllc.com/" rel="noopener noreferrer">
              <img
                alt="Logo"
                width="180"
                height="120"
                src={toAbsoluteUrl("/media/logos/MIRS_01.png")}
              />
            </a>
          </Link>
        </div>
        <div className="col-md-4 pt-5" align="right">
          <h6 style={{ whiteSpace: "pre-wrap" }} className="mt-2">
            <a
              href="https://quote.mirsgroupllc.com/auth/login"
              rel="noopener noreferrer"
              className="text-primary mr-3"
              tabIndex="0"
            >
              Quote
            </a>
            <a
              href="https://www.mirsgroupllc.com/info"
              rel="noopener noreferrer"
              className="text-primary mr-3"
              tabIndex="0"
            >
              Info
            </a>
            <a
              href="https://www.mirsgroupllc.com/about"
              rel="noopener noreferrer"
              className="text-primary mr-3"
              tabIndex="0"
            >
              About
            </a>
          </h6>
        </div>
        <div className="col-md-12 mt-3" align="center">
          <h1 style={{ fontSize: "40px", marginTop: "40px" }}>
            We're here to fund your Project
          </h1>
          <h4 style={{ marginTop: "40px" }}>
            MIRS Group, LLC Provides Commercial Loans nationwide for Real Estate
            investment, and renovation Projects.
          </h4>
        </div>
      </div>

      <div
        className="kt-login__body"
        style={{ height: "60%", marginTop: "40px" }}
      >
        <div className="kt-login__form">
          <div className="kt-login__options mb-3">
            <a
              href="https://quote.mirsgroupllc.com/forms/6270fd9caa90ec1ba2cbe597"
              style={{ borderRadius: "25px", fontWeight: 900 }}
              className="btn btn-primary btn-sm"
            >
              RENOVATION LOAN
            </a>
            <a
              href="https://quote.mirsgroupllc.com/forms/6266253942f28d01f26d2e6b"
              style={{ borderRadius: "25px", fontWeight: 900 }}
              className="btn btn-primary btn-sm"
            >
              TURNKEY RENTAL PURCHASE
            </a>
            <a
              href="https://quote.mirsgroupllc.com/forms/62661cf542f28d01f26d26bc"
              style={{ borderRadius: "25px", fontWeight: 900 }}
              className="btn btn-primary btn-sm"
            >
              RENTAL REFINANCE
            </a>
          </div>
          <div className="kt-login__options mb-3">
            <button
              href="#"
              style={{ borderRadius: "25px", fontWeight: 900 }}
              className="btn btn-primary btn-sm"
            >
              RENOVATE TO HOLD
            </button>
            <button
              href="#"
              style={{ borderRadius: "25px", fontWeight: 900 }}
              className="btn btn-primary btn-sm"
            >
              GROUND UP CONSTRUCTION
            </button>
            <button
              href="#"
              style={{ borderRadius: "25px", fontWeight: 900 }}
              className="btn btn-primary btn-sm"
            >
              OWNER OCCUPIED
            </button>
          </div>
          <div className="login_container">
            {Object.keys(loginUserInfo).length > 0 ? (
              <>
                <h3>Do you want to login as ?</h3>
                <RadioGroup
                  aria-label="Gender"
                  name="gender1"
                  onChange={handleRoleChange}
                >
                  {loginUserInfo.roles.map((role, i) => (
                    <FormControlLabel
                      key={i}
                      value={role._id}
                      control={<Radio />}
                      label={role.title}
                    />
                  ))}
                </RadioGroup>
              </>
            ) : (
              <>
                <div className="kt-login__title">
                  <h3>{titleTxt}</h3>
                </div>
                <Formik
                  initialValues={{
                    username: "",
                    password: "",
                  }}
                  validate={(values) => {
                    const errors = {};

                    if (!values.username) {
                      // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                      errors.username = intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD",
                      });
                    }

                    if (!values.password) {
                      errors.password = intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD",
                      });
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setStatus, setSubmitting }) => {
                    enableLoading();
                    setTimeout(() => {
                      login(values.username, values.password)
                        .then((res) => {
                          disableLoading();
                          if (res.data.accessToken) {
                            props.login(res.data.accessToken);
                          } else {
                            setLoginUserInfo(res.data);
                          }
                        })
                        .catch((err) => {
                          disableLoading();
                          setSubmitting(false);
                          setStatus(
                            err.response
                              ? err.response.data.message
                              : intl.formatMessage({
                                  id: "AUTH.VALIDATION.INVALID_LOGIN",
                                })
                          );
                        });
                    }, 1000);
                  }}
                >
                  {({
                    values,
                    status,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form
                      noValidate={true}
                      autoComplete="off"
                      className="kt-form"
                      onSubmit={handleSubmit}
                    >
                      {status ? (
                        <div role="alert" className="alert alert-danger">
                          <div className="alert-text">{status}</div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="form-group" style={{ fontSize: "25px" }}>
                        <TextField
                          type="username"
                          label="Username"
                          margin="normal"
                          className="kt-width-full"
                          name="username"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.username}
                          helperText={touched.username && errors.username}
                          error={Boolean(touched.username && errors.username)}
                          inputProps={{ style: { fontSize: 15 } }} // font size of input text
                          InputLabelProps={{ style: { fontSize: 15 } }} // font size of input label
                        />
                      </div>

                      <div className="form-group">
                        <TextField
                          type="password"
                          margin="normal"
                          label="Password"
                          className="kt-width-full"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          helperText={touched.password && errors.password}
                          error={Boolean(touched.password && errors.password)}
                          inputProps={{ style: { fontSize: 15 } }} // font size of input text
                          InputLabelProps={{ style: { fontSize: 15 } }} // font size of input label
                        />
                      </div>

                      <div className="kt-login__actions">
                        <Link
                          to="/auth/forgot-password"
                          className="kt-link kt-login__link-forgot"
                          style={{ fontSize: "18px" }}
                        >
                          Forgot Password ?
                        </Link>

                        <button
                          id="kt_login_signin_submit"
                          type="submit"
                          disabled={isSubmitting}
                          className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                            {
                              "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                            }
                          )}`}
                          style={loadingButtonStyle}
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </>
            )}
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center", fontSize: "12px" }}>
        <hr></hr>
        <div className="col-md-12">
          <p>
            <a
              href="https://www.mirsgroupllc.com/payoff"
              tabIndex="0"
              className="text-primary"
            >
              <b>Request Payoff</b>
            </a>{" "}
            |
            <a
              href="mailto:mail@mirsgroupllc.com"
              tabIndex="0"
              className="text-primary"
            >
              {" "}
              <b>mail@mirsgroupllc.com</b>
            </a>{" "}
            |
            <a
              href="https://calendly.com/mirsgroupllc"
              rel="noopener noreferrer"
              tabIndex="0"
              className="text-primary"
            >
              {" "}
              <b>schedule a call</b>
            </a>
          </p>
          <p>
            <a
              href="https://www.mirsgroupllc.com/courses"
              tabIndex="0"
              className="text-primary"
            >
              {" "}
              <b>Courses</b>
            </a>{" "}
            |
            <a
              href="https://www.mirsgroupllc.com/info"
              tabIndex="0"
              className="text-primary"
            >
              {" "}
              <b>Tools and Materials</b>
            </a>{" "}
            |
            <a
              href="https://www.mirsgroupllc.com/privacy-policy"
              rel="noopener noreferrer"
              className="text-primary"
              tabIndex="0"
            >
              {" "}
              <b>Disclaimers</b>
            </a>
          </p>
          <p>
            Mark Abramovich NMLS # 2274832 | Licensed in Florida |{" "}
            <a
              href="https://www.nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/Home.aspx/SubSearch"
              tabIndex="0"
              className="text-primary"
            >
              <b>NMLS consumer access page</b>
            </a>
          </p>
          <p>
            Residential Mortgages provided through Arco Financial NMLS # 2149692
            | Licensed in Florida<br/><br/>
          </p>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
