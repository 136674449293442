import React from "react";
import { Button } from "@material-ui/core";

const LsButton = (props) => {
  return (
    <Button variant="contained" id={props.element.id} color="primary">
      {props.element.title}
    </Button>
  );
};

export default LsButton;
