import React from "react";
import FormGroup from "./utils/FormGroup";
//import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import moment from "moment-timezone";

import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";

const DateTimePicker = (props) => {
  let value = props.element.value;

  if (!!props.element.value && props.element.value.includes("/")) {
    value = moment(props.element.value).format("MM-DD-YYYY");
  }

  return (
    <FormGroup element={props.element} module={props.module}>
      {props.module.permissions.update ? (
        <InputMask
          mask="99-99-9999"
          type="text"
          value={value}
          id={props.element.id}
          className={"form-control " + props.element.class}
          onChange={props.events.change}
          placeholder="MM-DD-YYYY"
        />
      ) : (
        <h6 className="text-primary">{props.element.value}</h6>
      )}
    </FormGroup>
  );
};

export default DateTimePicker;
