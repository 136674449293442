export const initReducer = (draft, action) => {
	const { type, payload } = action;

	switch (type) {
		case "updateMultipleFormData":
			// console.log(payload);
			payload.forEach((ele) => {
				// console.log(ele);
				if (ele.subKey) {
					// console.log("if");
					draft.formData[ele.key][ele.subKey] = ele.value;
				} else {
					// console.log("else");
					draft.formData[ele.key] = ele.value;
				}
			});
			// for (var key in payload) {
			//   console.log(key);
			//   draft.formData[key] = payload[key];
			// }
			return void draft;
		case "updateNestedLoopFormData":
			const { k, sK, val, i } = payload;
			draft.formData[k][i][sK] = val;
			return void draft;
		case "deleteNestedForm":
			const { d, _id } = payload;
			draft.formData[d] = draft.formData[d].filter(
				(item) => item._id !== _id
			);
			return void draft;
		default:
			break;
	}
};
