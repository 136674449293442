import React from "react";
import FormGroup from "./utils/FormGroup";
import States from "../config/States";

const UsStates = (props) => {
  return (
    <FormGroup element={props.element} module={props.module}>
      {props.module.permissions.update ? (
        <select
          id={props.element.id}
          className={"form-control " + props.element.class}
          onChange={props.events.change}
          disabled={props.element.readonly ? true : false}
          value={props.element.value}
        >
          {States.map((state, i) => (
            <option value={state.value} key={i}>
              {state.label}
            </option>
          ))}
        </select>
      ) : (
        <h6 className="text-primary">{props.element.value}</h6>
      )}
    </FormGroup>
  );
};

export default UsStates;
