import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { TablePagination } from "@material-ui/core";

import "./mailbox.css";
import { Tab, Tabs } from "react-bootstrap";
import { backendApiUrl, getDate } from "../utils";
import Sort from "../utils/Sort";
import TablePaginationActions from "../shared/TablePaginationActions";

const UserEmails = (props) => {
  const MI = props?.MI || {};

  const [state, setState] = useState({
    campaign_id: useParams().campaign_id,
    emails: [],
    counts: {
      send: 0,
      delivery: 0,
      open: 0,
      click: 0,
      bounce: 0,
      unsubscribed: 0,
    },
    filter: {},
    tab: "all_emails",
    load: true,
  });

  const setLoad = (status) => {
    let st = { ...state };
    st.load = status;
    setState(st);
  };

  const [sort, setSort] = useState({});

  const [totalDocumnetCount, setTotalDocumnetCount] = useState(0);

  const [currentRowStart, setCurrentRowStart] = useState(1);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    if (
      Math.max(0, Math.ceil(totalDocumnetCount / rowsPerPage) - 1) === newPage
    ) {
      //setCurrentRowStart(totalDocumnetCount - rowsPerPage + 1);
      setCurrentRowStart(rowsPerPage * newPage + 1);
    } else if (page < newPage) {
      setCurrentRowStart(currentRowStart + rowsPerPage);
    } else if (newPage === 0) {
      setCurrentRowStart(1);
    } else {
      setCurrentRowStart(currentRowStart - rowsPerPage);
    }
    setPage(newPage);
    setLoad(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentRowStart(1);
    setPage(0);
    setLoad(true);
  };

  const handleTabChange = (key) => {
    let st = { ...state };
    st.tab = key;
    st.load = true;
    setState(st);
  };

  useEffect(() => {
    if (state.load) {
      let data = {
        skip: currentRowStart - 1,
        limit: rowsPerPage,
      };

      if (sort.field) data.sort = sort;
      if (state.filter) data.search = state.filter;
      if (!!state.campaign_id) data._id = state.campaign_id;
      if (!!state.tab) data.tab = state.tab;
      if (!!MI?.dynamicFieldData?.email)
        data.email = MI?.dynamicFieldData?.email;

      // Inside module
      if (!!MI?.form?.title && !!MI?.MDID) {
        data.owner = MI.MDID;
        data.from = MI.form.title;
      }

      axios
        .post(
          backendApiUrl("emails/user_emails"),
          data,
          { _id: state.campaign_id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let st = { ...state, ...res.data };
          st.load = false;
          setState(st);
          setTotalDocumnetCount(st.count);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [state, currentRowStart, rowsPerPage, sort]);

  return state.load ? (
    <div style={{ textAlign: "center" }}>
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {!!props?.MI?.MDID && (
          <div className="tabs tabs-style-bar">
            <Tabs activeKey={state.tab} onSelect={handleTabChange}>
              <Tab eventKey="all_emails" title="All Emails"></Tab>
              {MI?.form?.title === "Workforces" && (
                <Tab eventKey="only_campaigns" title="Campaigns"></Tab>
              )}
            </Tabs>
          </div>
        )}

        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Sender</th>
                <th>Recipient</th>
                <th>Subject</th>
                <th>sent</th>
                <th>Delivery</th>
                <th>Open</th>
                <th>Click</th>
                <th>Bounce</th>
                <th>Unsubscribed</th>
                <th>Error</th>
                <th>Mail Type</th>
                <th>
                  Date
                  <Sort
                    data={sort}
                    actions={{ sort: setSort, load: setLoad }}
                    field="createdAt"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {state.emails.map((c, i) => (
                <tr key={i}>
                  <td>{currentRowStart + i}</td>
                  <td>
                    {c.general_mail && <>{c.general_mail.from_mail}</>}
                    {c.campaign &&
                      c.campaign.from_name +
                        " | " +
                        c.campaign.from_email.value}
                  </td>
                  <td>{c.recipient.email}</td>
                  <td>
                    {c.general_mail && <>{c.general_mail.subject}</>}
                    {c.campaign && c.campaign.subject}
                  </td>
                  <td>
                    {c.send && (
                      <>
                        <i className="fa fa-check mr-1" />
                        {getDate(c.send.mail.timestamp)}
                      </>
                    )}
                  </td>
                  <td>
                    {c.delivery && (
                      <>
                        <i className="fa fa-check-double mr-1" />
                        {getDate(c.delivery.mail.timestamp)}
                      </>
                    )}
                  </td>
                  <td>
                    {c.open && (
                      <>
                        <i className="fa fa-check-double mr-1" />
                        {getDate(c.open.mail.timestamp)}
                      </>
                    )}
                  </td>
                  <td>
                    {c.click && (
                      <>
                        <i className="fa fa-check-double mr-1" />
                        {getDate(c.click.mail.timestamp)}
                      </>
                    )}
                  </td>
                  <td>
                    {c.bounce && (
                      <>
                        <i className="fa fa-times mr-1" />
                        {c.bounce?.reason
                          ? c.bounce?.reason
                          : c?.bounce?.mail?.timestamp &&
                            getDate(c.bounce.mail.timestamp)}
                      </>
                    )}
                  </td>
                  <td>
                    {c.unsubscribed && (
                      <>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              {" "}
                              <div className="text-left">
                                {c.unsubscribed.reason && (
                                  <>
                                    <strong>Reason: </strong>{" "}
                                    {c.unsubscribed.reason}
                                    <br />
                                    {c.unsubscribed.reason === "others" && (
                                      <>
                                        <strong>Other Reason: </strong>{" "}
                                        {c.unsubscribed.customReason}
                                      </>
                                    )}
                                  </>
                                )}
                                {c.unsubscribed.selectedSenders && (
                                  <>
                                    <strong>Unsubscribed From: </strong>
                                    <ul>
                                      {c.unsubscribed.selectedSenders.map(
                                        (sen) => (
                                          <li key={sen}>{sen}</li>
                                        )
                                      )}
                                    </ul>{" "}
                                  </>
                                )}
                              </div>
                            </Tooltip>
                          }
                        >
                          <i className="fa fa-info-circle fa-lg text-primary mr-2" />
                        </OverlayTrigger>
                        {getDate(c.unsubscribed.date)}
                      </>
                    )}
                  </td>
                  <td>{c.error && <>{c.error.message}</>}</td>
                  <td>{c.campaign ? "Campaign" : "General"}</td>
                  <td>{c.createdAt}</td>
                </tr>
              ))}
              <tr>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  colSpan={13}
                  count={totalDocumnetCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "Rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </tr>
            </tbody>
          </table>
        </div>
      </Grid>
    </Grid>
  );
};

export default UserEmails;
