import { backendApiUrl } from "../../utils";
import axios from "axios";

export const sendEmail = ({ paymentsData, amount, paymentType }) => {
	const data = {
		workforce: paymentsData.formData.workforce,
		recipients: [],
		method: paymentType,
		amount: amount
	};

	axios
		.post(backendApiUrl("payments/send_email_company"), data, {
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((res) => {

		})
		.catch((err) => {

		});
}