import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Create from "./Create";
import List from "./List";

const Opportunities = (props) => {
  const [crudType, setCrudType] = useState("list");

  const { menuConfig } = useSelector(
    ({ builder: { menuConfig } }) => ({
      menuConfig,
    }),
    shallowEqual
  );
  let MDID = "";
  if (menuConfig.loggedInUserType === "prospect") {
    MDID = menuConfig.loggedInUserId;
  }

  return (
    <div>
      {crudType === "list" && (
        <List setCrud={setCrudType} MDID={MDID} menuConfig={menuConfig} />
      )}
      {crudType === "create" && (
        <Create setCrud={setCrudType} MDID={MDID} menuConfig={menuConfig} />
      )}
    </div>
  );
};

export default Opportunities;
