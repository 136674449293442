import React, { useState } from "react";
import axios from "axios";
import { Badge } from "react-bootstrap";

import FormGroup from "../utils/FormGroup";
import { backendApiUrl } from "../../utils";

const LoanNumber = (props) => {
  let MI = { ...props.module };
  const [value, setValue] = useState(
    !!props.element.value ? props.element.value : ""
  );
  let mid = MI.MDID;
  let prefix = "";
  if (
    MI.form.company &&
    MI.opportunity &&
    MI.form.company.prefix &&
    MI.form.company.prefix[MI.opportunity.program._id]
  ) {
    prefix = MI.form.company.prefix[MI.opportunity.program._id];
  }
  const generateLoanNumber = (mid) => {
    axios
      .post(
        backendApiUrl("opportunities/generate_unique_number"),
        { _id: mid },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setValue(res.data.auto_increment);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <FormGroup element={props.element} module={MI}>
      {!!mid && (
        <h6 className="text-primary">
          {!!value ? (
            prefix + value
          ) : (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => generateLoanNumber(mid)}
            >
              <Badge variant="info">Generate</Badge>
            </span>
          )}
        </h6>
      )}
    </FormGroup>
  );
};
export default LoanNumber;
