import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

import { formatBytes } from "../../utils";
import "./dropzone.css";

const FilePicker = (props) => {
  let mime_types = "image/*, .xlsx, .xls, .csv, .pdf, .pptx, .pptm, .ppt'";
  const [files, setFiles] = useState([]);
  const [size, setSize] = useState(0);

  const { getRootProps, getInputProps } = useDropzone({
    accept: mime_types,
    multiple: true,
    onDrop: (acceptedFiles) => {
      let fl = [...files];
      fl = [...fl, ...acceptedFiles];
      let totalSize = 0;
      fl.forEach((f) => {
        totalSize += f.size;
      });
      setSize(totalSize);
      props.handleFileRead(fl);
      setFiles(fl);
    },
  });

  const deleteNewFile = (file) => {
    let fl = [];
    let totalSize = 0;
    files.forEach((f) => {
      if (f.name === file.name) {
        props.deleteFile(f);
      } else {
        fl = [...fl, f];
        totalSize += f.size;
      }
    });
    setSize(totalSize);
    setFiles(fl);
  };

  let new_files = "";
  if (files.length > 0) {
    new_files = files.map((file) => (
      <li key={file.path}>
        {file.path} - {formatBytes(file.size, 2)}&nbsp;
        <i
          className="fa fa-trash fa-lg text-danger"
          title="Deactivate"
          onClick={() => deleteNewFile(file)}
        />
      </li>
    ));
  }

  return (
    <section className="container">
      {formatBytes(size, 2).includes("MB") &&
        parseInt(formatBytes(size, 2)) >= 10 && (
          <h3 className="text-danger">File size exceeds 10 MB</h3>
        )}

      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} name="test" />
        <h6 className="text-primary" style={{ textAlign: "center" }}>
        Drag & Drop files here
          {/*<br />
          <span className="text-danger">
            Max attachment file size up to 10 MB.
        </span>*/}
        </h6>
      </div>

      {!!new_files && (
        <aside className="mt-2">
          <div className="col-6 mb-1">
            <h6>
              Files <span className="text-info">{formatBytes(size, 2)}</span>
            </h6>
          </div>
          <ul>{new_files}</ul>
        </aside>
      )}
    </section>
  );
};

export default FilePicker;
