import React, { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../partials/content/Portlet";
import { Modal, Badge } from "react-bootstrap";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@material-ui/core";
import Elements from "./";
import ListSettings from "../utils/ListSettings";
import { backendApiUrl } from "../utils";

const DialogBox = (props) => {
  return (
    <Dialog
      open={true}
      onClose={() => props.setDel({}, false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setDel({}, false)} color="primary">
          Disagree
        </Button>
        <Button
          onClick={() => props.setDel(props.data, true)}
          color="primary"
          autoFocus
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CloneableSection = (props) => {
  let MI = { ...props.module };

  const [open, setOpen] = useState(false);
  const [reviewData, setReviewData] = useState({});
  const [subDatas, setSubDatas] = useState(
    props.element.value ? props.element.value : []
  );
  const [updateSubData, setUpdateSubData] = useState({});
  const [childContent, setChildContent] = useState(props.element.childs);
  const [delItem, setDelItem] = useState({});
  const [sectionFields, setSectionFields] = useState([]);
  const [load, setLoad] = useState(true);
  const [mandatory_fields, setMandatoryFields] = useState([]);
  const [requiredFields, setRequiredFields] = useState([]);

  const showAndHideFields = (content) => {
    let hideSectionsAndFields = [];
    const getHideSectionAndFields = (configs) => {
      configs.forEach((config) => {
        if (config.control_settings) {
          hideSectionsAndFields = [
            ...hideSectionsAndFields,
            ...checkControlSettings(config),
          ];
        }
        if (config.childs && config.childs.length) {
          getHideSectionAndFields(config.childs);
        }
      });
    };
    getHideSectionAndFields(content);

    if (Array.isArray(hideSectionsAndFields)) {
      content = removeElementFromFormData(content, hideSectionsAndFields);
    }
    setChildContent(content);
  };

  const onCreateHandler = () => {
    setUpdateSubData({ _id: uuidv4() });
    let tempChildContent = [...childContent];
    tempChildContent = bindValueToForm(tempChildContent, []);
    setChildContent(tempChildContent);
    setOpen(true);
  };

  const onUpdateHandler = (data) => {
    setUpdateSubData(data);
    let tempChildContent = [...childContent];
    tempChildContent = bindValueToForm(tempChildContent, data);
    showAndHideFields(tempChildContent);
    setOpen(true);
  };

  const bindValueToForm = (configs, values) => {
    let bindedConfigs = [];
    configs.forEach((config) => {
      if (values[config.id]) {
        config.value = values[config.id];
      } else {
        config.value = "";
        if (config.childs && config.childs.length)
          config.childs = bindValueToForm(config.childs, values);
      }
      bindedConfigs = [...bindedConfigs, config];
    });
    return bindedConfigs;
  };

  const onDeleteHandler = (data, confirm) => {
    if (delItem._id && confirm) {
      let tempSubDatas = [...subDatas];
      let temp = [];
      tempSubDatas.forEach((item) => {
        if (item._id !== delItem._id) temp = [...temp, item];
      });
      setSubDatas(temp);
      setDelItem({});

      let tempFormData = { ...MI.data };
      tempFormData[props.element.id] = temp;
      props.events.setData(tempFormData);
    } else {
      setDelItem(data);
    }
  };

  const onFieldChangeHandler = (event, elmId, directValue) => {
    let tempSubData = { ...updateSubData };
    let id = "";
    let value;

    if (directValue !== undefined) {
      id = elmId;
      value = directValue;
    } else if (event === null || !event.target) {
      id = elmId;
      value = event === null ? [] : event;
    } else if (event.target.type === "radio") {
      id = event.target.name;
      value = event.target.value;
    } else if (event.target.type === "checkbox") {
      value = [];
      id = event.target.name;
      let inputElements = document.getElementsByName(id);
      for (let i = 0; i < inputElements.length; i++) {
        if (inputElements[i].checked) {
          value = [...value, inputElements[i].id];
        }
      }
    } else {
      id = event.target.id;
      value = event.target.value;
    }
    tempSubData[id] = value;
    setUpdateSubData(tempSubData);
    let tempChildContent = [...childContent];
    tempChildContent = bindValueToForm(tempChildContent, tempSubData);

    showAndHideFields(tempChildContent);
  };

  const checkControlSettings = (element) => {
    let temp = [];
    element.control_settings.forEach((elm) => {
      let get_hide_fields = false;
      if (
        elm.values === null ||
        (Array.isArray(elm.values) &&
          elm.values.length === 0 &&
          (!element.value ||
            element.value === "" ||
            (Array.isArray(element.value) && element.value.length === 0)))
      ) {
        get_hide_fields = true;
      }
      if (!get_hide_fields) {
        if (element.type === "checkbox" || element.type === "multiselect") {
          if (!element.value) element.value = [];
          Array.isArray(elm.values) &&
            elm.values.forEach((v) => {
              element.value &&
                element.value.forEach((e) => {
                  let val = "";
                  if (e.value) {
                    val = e.value;
                  } else {
                    val = e;
                  }
                  if (elm.as === "=" && val === v.value) {
                    get_hide_fields = true;
                  } else if (
                    elm.as === ">" &&
                    parseFloat(val) > parseFloat(v.value)
                  ) {
                    get_hide_fields = true;
                  } else if (
                    elm.as === "<" &&
                    parseFloat(val) < parseFloat(v.value)
                  ) {
                    get_hide_fields = true;
                  } else if (
                    elm.as === ">=" &&
                    parseFloat(val) >= parseFloat(v.value)
                  ) {
                    get_hide_fields = true;
                  } else if (
                    elm.as === "<=" &&
                    parseFloat(val) >= parseFloat(v.value)
                  ) {
                    get_hide_fields = true;
                  }
                });
            });
        } else {
          Array.isArray(elm.values) &&
            elm.values.forEach((v) => {
              if (!element.value) element.value = "";
              if (elm.as === "=" && element.value === v.value) {
                get_hide_fields = true;
              } else if (
                elm.as === ">" &&
                parseFloat(element.value) > parseFloat(v.value)
              ) {
                get_hide_fields = true;
              } else if (
                elm.as === "<" &&
                parseFloat(element.value) < parseFloat(v.value)
              ) {
                get_hide_fields = true;
              } else if (
                elm.as === ">=" &&
                parseFloat(element.value) >= parseFloat(v.value)
              ) {
                get_hide_fields = true;
              } else if (
                elm.as === "<=" &&
                parseFloat(element.value) >= parseFloat(v.value)
              ) {
                get_hide_fields = true;
              }
            });
        }
      }
      if (get_hide_fields) {
        elm.hide_sections.forEach((s) => (temp = [...temp, s.value]));
        elm.hide_fields.forEach((f) => {
          temp = [...temp, f.value];
        });
      }
    });
    return temp;
  };

  // Binded value
  const removeElementFromFormData = (content, hide_sections_and_fields) => {
    let bindedData = [];
    content.forEach((config) => {
      config.show = true;
      if (hide_sections_and_fields.includes(config.id)) {
        config.show = false;
      }
      if (config.childs && config.childs.length) {
        config.childs = removeElementFromFormData(
          config.childs,
          hide_sections_and_fields
        );
      }
      bindedData = [...bindedData, config];
    });
    return bindedData;
  };

  const checkValidation = () => {
    let rf = [];
    mandatory_fields.forEach((m) => {
      if (!updateSubData[m]) rf = [...rf, m];
    });
    return rf;
  };

  const onSubmitHandler = () => {
    let rf = checkValidation();
    if (rf.length > 0) {
      setRequiredFields(rf);
      return true;
    }

    let tempSubDatas = [...subDatas];
    let pushed = true;
    let temp = [];
    tempSubDatas.forEach((data) => {
      if (data._id === updateSubData._id) {
        temp = [...temp, updateSubData];
        pushed = false;
      } else {
        temp = [...temp, data];
      }
    });
    if (pushed) {
      temp = [...temp, updateSubData];
    }
    setSubDatas(temp);

    let tempFormData = { ...MI.data };
    tempFormData[props.element.id] = temp;
    props.events.setData(tempFormData);

    setOpen(false);
  };

  const bindHideAndShowSettings = (configs, columns, fieldsIds) => {
    let bindedConfigs = [];
    configs.forEach((config) => {
      if (fieldsIds.includes(config.id)) config.listShowField = false;
      if (columns[config.id]) {
        config.order = columns[config.id].order;
        config.listShowField = true;
      } else if (config.childs && config.childs.length) {
        config.childs = bindHideAndShowSettings(
          config.childs,
          columns,
          fieldsIds
        );
      }
      bindedConfigs = [...bindedConfigs, config];
    });
    return bindedConfigs;
  };

  const saveListSettings = async (columns) => {
    let columnsKey = {};
    columns.forEach((column) => {
      columnsKey = { ...columnsKey, [column.id]: { order: column.order } };
    });
    let fieldsIds = [];
    sectionFields.forEach((s) => {
      fieldsIds = [...fieldsIds, s._id];
    });

    let form = { ...MI.form };
    form.content = bindHideAndShowSettings(form.content, columnsKey, fieldsIds);

    await axios
      .patch(backendApiUrl("prospects/forms/" + form._id), form, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        props.events.setForm(form);
        props.events.submit();
      });
    setSectionFields(columns);
  };

  useEffect(() => {
    if (load) {
      let fields = [];
      const getMandatory = (config) => {
        Array.isArray(config) &&
          config.forEach((c) => {
            if (c.mandatory) fields = [...fields, c.id];
            if (c.childs) getMandatory(c.childs);
          });
      };
      getMandatory(childContent);
      setMandatoryFields(fields);
      setLoad(false);
    }

    if (
      Object.keys(reviewData).length === 0 &&
      reviewData.constructor === Object &&
      MI.reviewData &&
      MI.reviewData.info[props.element.id]
    ) {
      let newData = [];
      let reviewData = {};
      MI.reviewData &&
        MI.reviewData.info[props.element.id] &&
        MI.reviewData.info[props.element.id].forEach((rd) => {
          reviewData = { ...reviewData, [rd._id]: rd };
          let trueCnt = 0;
          props.element.value &&
            props.element.value.forEach((s) => {
              if (s._id === rd._id) {
                trueCnt++;
              }
            });
          if (trueCnt === 0) newData = [...newData, { ...rd, new: true }];
        });
      setSubDatas([...subDatas, ...newData]);
      setReviewData(reviewData);
    }
  }, [
    MI.reviewData,
    props.element.childs,
    props.element.id,
    props.element.value,
    reviewData,
    subDatas,
    load,
    childContent,
  ]);

  useEffect(() => {
    let section_fields = [];
    const getSectionFields = (configs) => {
      configs.forEach((config) => {
        if (
          config.type !== "container" &&
          config.type !== "grid" &&
          config.type !== "item" &&
          config.type !== "tab" &&
          config.type !== "section"
        ) {
          let data = {
            id: config.id,
            title: config.title,
            type: config.type,
          };
          if (config.options && config.options !== undefined)
            data.options = config.options;
          if (config.show) data.show = config.show;
          if (config.listShowField) data.listShowField = config.listShowField;
          if (config.order) data.order = config.order;
          section_fields = [...section_fields, data];
        } else {
          getSectionFields(config.childs);
        }
      });
    };

    getSectionFields(props.element.childs);
    section_fields.sort(function(a, b) {
      return a.order - b.order;
    });
    setSectionFields(section_fields);
  }, [props.element.childs]);

  if (requiredFields.length > 0) {
    MI.requiredFields = requiredFields;
  } else {
    MI.requiredFields = [];
  }

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("-");
  }

  return (
    <>
      <Portlet>
        <PortletHeader
          title={
            props.element.id === props.element.title ? "" : props.element.title
          }
          toolbar={
            <PortletHeaderToolbar>
              {MI.permissions &&
                ((!!MI.MDID && MI.permissions.update) ||
                  (MI.MDID === "" && MI.permissions.add)) && (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={onCreateHandler}
                  >
                    {"Create " + props.element.title}
                  </button>
                )}
              &nbsp;
              {sectionFields.length > 0 && (
                <ListSettings
                  title="List Settings"
                  save={saveListSettings}
                  fields={sectionFields}
                  menuConfig={props.module}
                />
              )}
            </PortletHeaderToolbar>
          }
        />
        <PortletBody>
          {delItem._id && <DialogBox data={delItem} setDel={onDeleteHandler} />}
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  {sectionFields.map(
                    (field, i) =>
                      field.show &&
                      field.listShowField && <th key={i}>{field.title}</th>
                  )}
                  <th nowrap="true">Actions</th>
                </tr>
              </thead>
              <tbody>
                {subDatas.length > 0 ? (
                  subDatas.map((data, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      {sectionFields.map((field, j) => {
                        if (field.show && field.listShowField) {
                          if (Array.isArray(data[field.id])) {
                            return (
                              <td key={j}>
                                {data[field.id].map((val, k) => (
                                  <span key={k}>
                                    {val.text}
                                    <br />
                                  </span>
                                ))}
                              </td>
                            );
                          } else if (data[field.id] && field.options) {
                            let value = "";
                            field.options.forEach((option) => {
                              if (data[field.id] === option.id)
                                value = option.text;
                            });
                            return <td key={j}>{value}</td>;
                          } else if (data[field.id] && !data.options) {
                            return (
                              <td key={j}>
                                {field.type === "date"
                                  ? convert(data[field.id])
                                  : data[field.id]}
                              </td>
                            );
                          } else {
                            return <td key={j}></td>;
                          }
                        } else {
                          return "";
                        }
                      })}
                      <td>
                        <i
                          className="fa fa-pencil-alt fa-lg text-primary mr-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => onUpdateHandler(data)}
                        />

                        {MI.permissions && MI.permissions.delete && (
                          <i
                            className="fa fa-trash fa-lg text-danger mr-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => onDeleteHandler(data, false)}
                          />
                        )}

                        {reviewData[data._id] && (
                          <Badge key={i} variant="warning">
                            Need Review
                          </Badge>
                        )}

                        {data.new === true && (
                          <span className="ml-2">
                            <Badge key={i} variant="danger">
                              New Record
                            </Badge>
                          </span>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={sectionFields.length + 1}>
                      <h5 className="text-primary text-center">
                        No records available, Click to create{" "}
                        {props.element.id === props.element.title
                          ? ""
                          : props.element.title}
                      </h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </PortletBody>
      </Portlet>
      <Modal
        show={open}
        size="xl"
        onHide={() => setOpen(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.element.id === props.element.title
              ? ""
              : props.element.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {childContent.map((child, i) => (
            <Elements
              element={child}
              module={{
                ...MI,
                reviewData: {
                  info: reviewData[updateSubData._id]
                    ? reviewData[updateSubData._id]
                    : {},
                },
              }}
              events={{ ...props.events, change: onFieldChangeHandler }}
              key={i}
            />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="default"
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
            &nbsp;
            <Button
              variant="contained"
              color="primary"
              disabled={MI.permissions && MI.permissions.update ? false : true}
              onClick={onSubmitHandler}
            >
              {Object.keys(updateSubData).length > 1
                ? "Update & Close"
                : "Create & Close"}
            </Button>
          </Grid>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CloneableSection;
