import React from "react";

const FormGroup = (props) => {
  return (
    <div className="form-group">
      <label htmlFor={props.element.id}>
        {props.element.title === props.element.id
          ? "Untitled"
          : props.element.title}
        {props.element.mandatory ? <span className="text-danger"> *</span> : ""}
      </label>

      {props.element.type === "file" &&
        props.module.form.guidelines &&
        props.module.form.guidelines[props.element.id] && (
          <h6>
            <strong>{props.module.form.guidelines[props.element.id]}</strong>
          </h6>
        )}

      {props.children}

      {props.element.isInValid && (
        <div className="has-error">
          {!!props.element.validate_msg
            ? props.element.validate_msg
            : "Please provide a valid " + props.element.title + "."}
        </div>
      )}

      {props.element.type !== "file" &&
        props.module.form.guidelines &&
        props.module.form.guidelines[props.element.id] && (
          <div className="has-error" style={{ fontSize: "15px" }}>
            {props.module.form.guidelines[props.element.id]}
          </div>
        )}
    </div>
  );
};

export default FormGroup;
