import React from "react";
import { Link } from "react-router-dom";

const JumbLink = (props) => {
  let children = props.children ? props.children : "";

  return props.position === 0 ? (
    <Link to={props.url}>
      <i className="fa fa-pencil-alt fa-lg mr-2" />
      {children}
    </Link>
  ) : (
    children
  );
};

export default JumbLink;
