import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../partials/content/Portlet";
import { backendApiUrl } from "../utils";
import Message from "../utils/Message";

const DocumentLibrary = (props) => {
  const [state, setState] = useState({
    load: true,
    info: {},
    data: {
      form: props.MI.form._id,
      owner: props.MI.MDID,
    },
    msg: {},
    listData: [],
    requiredFields: [],
  });

  const setMsg = (msg) => {
    let st = { ...state };
    st.msg = msg;
    setState(st);
  };

  useEffect(() => {
    if (state.load) {
      axios
        .post(
          backendApiUrl("documentlibrary/document"),
          {
            programs: props.MI.form.programs,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let st = { ...state };
          st.listData = res.data.data;
          st.load = false;
          setState(st);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state, props.MI.MDID, props.MI.form._id, props.MI.form.programs]);
  const downloadFile = (data) => {
    axios
      .get(backendApiUrl("documentlibrary/view/" + data._id), {
        responseType: "blob",
      })
      .then((res) => {
        const file = new Blob([res.data], {
          type: res.headers["content-type"],
        });

        //Build a URL from the file
        const url = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", data.upload_name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Portlet>
      <PortletHeader title="Document Library" />
      <PortletBody>
        {state.msg && state.msg.show && (
          <Message
            status={state.msg.status}
            setMsg={setMsg}
            msg={state.msg.msg}
          />
        )}

        {state.load ? (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th nowrap="true">Title</th>
                    <th nowrap="true">File Name</th>
                    <th nowrap="true">Description</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {state.listData &&
                    state.listData.map((f, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{f.title}</td>
                        <td>{f.upload_name}</td>
                        <td>{f.description}</td>
                        <td>
                          <span
                            className="bg-success"
                            style={{
                              color: "white",
                              padding: "5px",
                              borderRadius: "2%",
                              cursor: "pointer",
                            }}
                            onClick={() => downloadFile(f)}
                          >
                            <i className="fa fa fa-download fa-lg mr-2"></i>{" "}
                            Click to Download the Docs
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </PortletBody>
    </Portlet>
  );
};

export default DocumentLibrary;
