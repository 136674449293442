import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { backendApiUrl } from "../utils";

const AssignedContacts = (props) => {
  const [edit, setEdit] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    let initialUsers = [];

    props.asUsers &&
      props.asUsers.forEach((u) => {
        let label = "";
        let value = "";

        if (u.user && u.role) value = `${u.user?._id}---${u.role?._id}`;
        if (u.user?.info?.first_name) label = u.user.info.first_name;
        if (u.user?.info?.last_name) label += ` ${u.user.info.last_name}`;
        if (u.role) label += ` (${u.role.title})`;

        initialUsers.push({ value, label });
      });

    setUsers(initialUsers);
  }, [props.asUsers]);

  const onSelectHandler = (selectedOptions) => {
    const updatedUsers = selectedOptions || [];
    setUsers(updatedUsers);

    const data = {
      adminInfo: {
        contacts: updatedUsers.map((u) => u.value),
      },
    };

    axios
      .patch(backendApiUrl(`opportunities/${props.data._id}`), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        props.setMsg({
          status: 200,
          msg: `Assigned ${props.contactTitle} has been updated successfully.`,
          show: true,
        });
      })
      .catch((err) => {
        props.setMsg({
          status: 500,
          msg: `Problem while updating assigned ${props.contactTitle}, please try again.`,
          show: true,
        });
      });
  };

  return (
    <div>
      <i
        className="fa fa-edit fa-lg text-primary mr-2"
        onClick={() => setEdit((prevEdit) => !prevEdit)}
        title="Click to update users"
      />
      {edit ? (
        <Select
          className="kt-width-full"
          name="users"
          options={props.users}
          isMulti
          onChange={onSelectHandler}
          value={users}
          isDisabled={props.data.readonly}
          placeholder="Please select"
          menuPortalTarget={document.body}
        />
      ) : (
        <span>
          {users.map((s, i) => (
            <span key={i}>
              {s.label}
              <br />
            </span>
          ))}
        </span>
      )}
    </div>
  );
};

export default AssignedContacts;
