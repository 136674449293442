import axios from "axios";
import { backendApiUrl } from "../utils";

export const LOGIN_URL = backendApiUrl("auth/login");
export const REGISTER_URL = backendApiUrl("auth/register");
export const REQUEST_PASSWORD_URL = backendApiUrl("auth/forgot_password");

export const ME_URL = backendApiUrl("auth/get_user_by_token");

export function login(username, password) {
  return axios.post(LOGIN_URL, { username, password });
}

export function register(data) {
  return axios.post(REGISTER_URL, data);
}

export function requestPassword(username) {
  return axios.post(REQUEST_PASSWORD_URL, { username });
}

export function getUserByToken() {
  return axios.get(ME_URL);
}
