import React from "react";
import FormGroup from "./utils/FormGroup";
import { Form } from "react-bootstrap";

const RadioButton = (props) => {
  let value = "";
  props.element.options && props.element.options.forEach(element => {
    if (props.element.value === element.text || props.element.value === element.id) {
      value = element.id;
    }
  });
  return (
    <FormGroup element={props.element} module={props.module}>
      {props.module.permissions.update ? (
        <Form>
          {props.element.options ? (
            props.element.options.map((option) => {
              return (
                <Form.Check
                  custom
                  inline
                  type="radio"
                  className={props.element.id + props.element.class}
                  label={option.text}
                  key={option.id}
                  value={option.id}
                  onChange={props.events.change}
                  disabled={props.element.readonly ? true : false}
                  name={props.element.id}
                  id={props.element.id + "_" + option.id}
                  checked={value === option.id ? true : false}
                />
              );
            })
          ) : (
            <>
              <Form.Check
                custom
                inline
                label="Option 1"
                type="radio"
                value="1"
                onChange={props.events.change}
                id={props.element.id}
                checked={value === 1 ? true : false}
              />
            </>
          )}
        </Form>
      ) : (
        <h6 className="text-primary">
          {props.element.options && props.element.options.map((o) =>
            props.element.value === o.id ? o.text : ""
          )}
        </h6>
      )}
    </FormGroup>
  );
};

export default RadioButton;
