import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { backendApiUrl } from "../../utils";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";

const StandardDocs = (props) => {
  const [state, setState] = useState({ load: true });
  useEffect(() => {
    if (state.load) {
      axios
        .post(
          backendApiUrl("standard_docs/module"),
          { form: props.MI.form._id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let st = { ...state, ...res.data, load: false };
          setState(st);
        })
        .catch((err) => {
          let st = { ...state };
          st.load = false;
          setState(st);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.load]);
  return state.docs && state.docs.length > 0 ? (
    <div className="mt-3">
      <Portlet>
        <PortletHeader title="Standard Docs" />
        <PortletBody>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th nowrap="true">Title</th>
                  <th nowrap="true">Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {state.load ? (
                  <tr>
                    <td colSpan="4">
                      <div style={{ textAlign: "center" }}>
                        <Spinner animation="border" variant="primary" />
                      </div>
                    </td>
                  </tr>
                ) : (
                  state.docs.map((f, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{f.doc.title}</td>
                      <td>{f.doc.description}</td>
                      <td className="pointer">
                        <Link
                          to={
                            "/standard_docs/view/" + f._id + "/" + props.MI.MDID
                          }
                          className="btn btn-sm btn-primary"
                        >
                          View Document
                        </Link>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </PortletBody>
      </Portlet>
    </div>
  ) : "";
};

export default StandardDocs;
