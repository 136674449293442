import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { backendApiUrl } from "../../utils";
import LinkPlaid from "../../utils/linkPlaid";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../partials/content/Portlet";
import { Modal, Button } from "react-bootstrap";
import { get } from "lodash";

const Assets = (props) => {
  const [showAsset, setAsset] = useState(false);
  const [report, setReport] = useState(false);
  const [base64ContentString, setBase64ContentString] = useState(false);

  const requestAssetReport = () => {
    const data = {
      prospectId: props.opportunity.prospect._id,
      owner: props.owner,
      recipients: [
        {
          email: props.opportunity.prospect.info.email,
          type: "Plaid",
        },
      ],
    };
    axios
      .post(backendApiUrl("plaid/create_asset_request_report"), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
        props.setMsg({
          status: 200,
          msg: res.data.message,
          show: true,
        });
      })
      .catch((err) => {
        props.setMsg({
          status: 200,
          msg: err.message,
          show: true,
        });
      });
  };
  const asset_report_token = get(
    props.opportunity.prospect,
    "plaid_info.tokens.asset_report_token.asset_report_token",
    ""
  );
  const setmsg = props.setMsg;
  const pullReportFromPlaid = useCallback(() => {
    if (asset_report_token) {
      axios
        .get(
          backendApiUrl(`plaid/pull_report_from_plaid/${asset_report_token}`)
        )
        .then((res) => {
          setBase64ContentString(res.data.pdf);
          setAsset(true);
        })
        .catch((err) => {
          setmsg({
            status: 500,
            msg: err.message,
            show: true,
          });
        });
    }
  }, [setmsg, asset_report_token]);

  useEffect(() => {
    pullReportFromPlaid();
  }, [pullReportFromPlaid]);

  return (
    <div className="mt-3 mb-3">
      {report && (
        <Modal
          show={true}
          size="xl"
          onHide={() => setReport(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Asset Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showAsset ? (
              <embed
                src={`data:application/pdf;base64,${base64ContentString}`}
                type="application/pdf"
                height="1000px"
                width="100%"
              ></embed>
            ) : (
              "nothing to show request asset report"
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setReport(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {props.user.type === "Workforces" ? (
        <Portlet>
          <PortletHeader
            title="Assets Report"
            toolbar={
              <PortletHeaderToolbar>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={(e) => requestAssetReport()}
                >
                  {showAsset ? "Refresh Asset Report" : "Request Asset Report"}
                </button>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody>
            <div className="d-flex justify-content-center">
              {showAsset ? (
                <button
                  className="btn btn-primary btn-sm"
                  onClick={(e) => setReport(true)}
                >
                  {"View Asset Report"}
                </button>
              ) : (
                "nothing to show request asset report"
              )}
            </div>
          </PortletBody>
        </Portlet>
      ) : (
        <LinkPlaid
          setMsg={props.setMsg}
          prospectId={props.opportunity.prospect._id}
        />
      )}
    </div>
  );
};

export default Assets;
