import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { GiSettingsKnobs } from "react-icons/gi";

import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import Menu from "./Menu";
import KTOffcanvas from "../../../_metronic/_assets/js/offcanvas";

const Tools = (props) => {
  const { menuConfig } = useSelector(
    ({ builder: { menuConfig }, auth }) => ({
      menuConfig,
      user: auth.user,
    }),
    shallowEqual
  );

  let headers = [...(menuConfig.static ? menuConfig.static : [])];

  let icons = {
    groups: "fa fa-users",
    builders: "fa fa-building",
    products_programs: "fab fa-product-hunt",
    data_import: "fa fa-file-import",
    security: "fa fa-lock",
    reports: "fa fa-file",
    integrations: "fab fa-pushed",
    teams: "fa fa-user",
  };

  useEffect(() => {
    const panel = document.getElementById("kt_quick_panel");

    // eslint-disable-next-line
    const offCanvas = new KTOffcanvas(panel, {
      overlay: true,
      baseClass: "kt-quick-panel",
      closeBy: "kt_quick_panel_close_btn",
      toggleBy: "kt_quick_panel_toggler_btn",
    });
  }, []);

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>THEME CUSTOMIZER</Tooltip>}
      >
        <Dropdown
          className="kt-header__topbar-item kt-header__topbar-item--langs"
          drop="down"
          alignRight
        >
          <span className="kt-header__topbar-icon">
            <GiSettingsKnobs
              size="30"
              color="#fff"
              id="kt_quick_panel_toggler_btn"
            />
          </span>
        </Dropdown>
      </OverlayTrigger>
      {headers.length > 0 && (
        <Dropdown
          className="kt-header__topbar-item kt-header__topbar-item--langs"
          drop="down"
          alignRight
        >
          <Dropdown.Toggle
            as={HeaderDropdownToggle}
            id="dropdown-toggle-my-cart"
          >
            <span className="kt-header__topbar-icon">
              <i className="fa fa-tools fa-2x" />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
            {headers.map((d, i) =>
              d.submenu && d.submenu.length > 0 ? (
                <Menu data={d} key={i} />
              ) : (
                <div
                  key={i}
                  style={{
                    padding: "16px",
                    minHeight: "48px",
                  }}
                >
                  {d.page === "workforces" ? (
                    <i className={"fa fa-user fa-lg mr-2 text-primary"} />
                  ) : (
                    <i className={icons[d._id] + " fa-lg mr-2 text-primary"} />
                  )}
                  <Link to={"/" + d.page}>
                    <span
                      style={{
                        fontSize: "1rem",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        lineHeight: "1.5",
                      }}
                    >
                      {d.custom_title || d.title}
                    </span>
                  </Link>
                </div>
              )
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

const mapStateToProps = ({ auth: { user }, builder }) => ({
  user,
  menuConfig: builder.menuConfig,
});

export default connect(mapStateToProps)(Tools);
