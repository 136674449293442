import React from "react";

const HeaderText = (props) => {
  return (
    <h4>
      {props.element.id === props.element.title ? "" : props.element.title}
    </h4>
  );
};

export default HeaderText;
