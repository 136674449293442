import logoImage from "../website/Images/logo.png";
import connection4 from "../website/Images/connection4.png"
import group18 from "../website/Images/Group-18.png"
import group68 from "../website/Images/Group-68.png"
import management1 from "../website/Images/management1.png"
import maskgroup from "../website/Images/Mask-group.png"
import moneybag2 from "../website/Images/money-bag2.png"
import rating3 from "../website/Images/rating3.png"
import LendCRMLogo from "../website/Images/LendCRM_Logo.png"
import LendCRMLogoscaled from "../website/Images/LendCRM_Logo-scaled.jpg"
import croppedWebpnetresizeimage32x32 from "../website/Images/cropped-Webp.net-resizeimage-32x32.png"
import croppedWebpnetresizeimage180x180 from "../website/Images/cropped-Webp.net-resizeimage-180x180.png"
import croppedWebpnetresizeimage192x192 from "../website/Images/cropped-Webp.net-resizeimage-192x192.png"
import croppedWebpnetresizeimage270x270 from "../website/Images/cropped-Webp.net-resizeimage-270x270.png"
import animateMin from "../website/css/animate.min.css"
import animationsMin from "../website/css/animations.min.css"
import bootstrapMin from "../website/css/bootstrap.min.css"
// import carousel from "../website/css/carousel.css"
import frontendLite from "../website/css/frontend-lite.min.css"
import hoverMin from "../website/css/hover-min.css"
import index from "../website/css/index.css"
// import lineaMin from "../website/css/linea.min.css"
// import loadersMin from "../website/css/loaders.min.css"
import meanMenu from "../website/css/meanmenu.min.css"
import niceSelectMin from "../website/css/nice-select.min.css"
import pageNavi from "../website/css/pagenavi-css.css"
import post4354 from "../website/css/post-4354.css"
import post4586 from "../website/css/post-4586.css"
import post4589 from "../website/css/post-4589.css"
import responsive from "../website/css/responsive.css"
import simpleBanner from "../website/css/simple-banner.css"
import style from "../website/css/style.css"
import styleMin from "../website/css/style.min.css"
import style1 from "../website/css/style1.css"
// import stylesCss from "../website/css/styles.css"
// import themeifyIcons from "../website/css/themify-icons.min.css"


const htmlContent = `<!DOCTYPE html>
<html lang="en-US">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="msapplication-TileColor" content="#ff3600">
    <meta name="theme-color" content="#ff3600">
    <link rel="stylesheet" id="font-awesome-css"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" type="text/css"
        media="all" />
    <link rel="stylesheet" type="text/css" href="${style}">
    <link rel="stylesheet" type="text/css" href="${style1}">
    <link rel="profile" href="//gmpg.org/xfn/11">
    <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
        rel="stylesheet">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
    <title>Loan Management Software with Inbuilt CRM - LendCRM</title>
    <meta name="description"
        content="LendCRM is not merely a loan management system. LendCRM unifies loan activities and tracks every step of the customer&#039;s journey from a single application." />
    <link rel="canonical" href="https://lendcrm.io/" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Loan Origination Software with very Strong CRM features." />
    <meta property="og:description"
        content="LendCRM is not merely a loan management system. LendCRM unifies loan activities and tracks every step of the customer&#039;s journey from a single application." />
    <meta property="og:url" content="https://lendcrm.io/" />
    <meta property="og:site_name" content="LendCRM" />
    <meta property="article:publisher" content="https://facebook.com/lendcrm" />
    <meta property="article:modified_time" content="2023-06-21T04:44:55+00:00" />
    <meta property="og:image" content="${LendCRMLogoscaled}" />
    <meta property="og:image:width" content="2560" />
    <meta property="og:image:height" content="1810" />
    <meta property="og:image:type" content="image/jpeg" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Loan Origination Software with very Strong CRM features." />
    <meta name="twitter:image" content="${LendCRMLogoscaled}" />
    <meta name="twitter:site" content="@lendcrm" />
    <meta name="twitter:label1" content="Est. reading time" />
    <meta name="twitter:data1" content="4 minutes" />
    <link rel="stylesheet" id="wp-block-library-css" href="${styleMin}" type="text/css" media="all" />
    <style id="global-styles-inline-css" type="text/css">
        body {
            --wp--preset--color--black: #000000;
            --wp--preset--color--cyan-bluish-gray: #abb8c3;
            --wp--preset--color--white: #ffffff;
            --wp--preset--color--pale-pink: #f78da7;
            --wp--preset--color--vivid-red: #cf2e2e;
            --wp--preset--color--luminous-vivid-orange: #ff6900;
            --wp--preset--color--luminous-vivid-amber: #fcb900;
            --wp--preset--color--light-green-cyan: #7bdcb5;
            --wp--preset--color--vivid-green-cyan: #00d084;
            --wp--preset--color--pale-cyan-blue: #8ed1fc;
            --wp--preset--color--vivid-cyan-blue: #0693e3;
            --wp--preset--color--vivid-purple: #9b51e0;
            --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, rgb(155, 81, 224) 100%);
            --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, rgb(122, 220, 180) 0%, rgb(0, 208, 130) 100%);
            --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg, rgba(252, 185, 0, 1) 0%, rgba(255, 105, 0, 1) 100%);
            --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, rgb(207, 46, 46) 100%);
            --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, rgb(238, 238, 238) 0%, rgb(169, 184, 195) 100%);
            --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
            --wp--preset--gradient--blush-light-purple: linear-gradient(135deg, rgb(255, 206, 236) 0%, rgb(152, 150, 240) 100%);
            --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, rgb(254, 205, 165) 0%, rgb(254, 45, 45) 50%, rgb(107, 0, 62) 100%);
            --wp--preset--gradient--luminous-dusk: linear-gradient(135deg, rgb(255, 203, 112) 0%, rgb(199, 81, 192) 50%, rgb(65, 88, 208) 100%);
            --wp--preset--gradient--pale-ocean: linear-gradient(135deg, rgb(255, 245, 203) 0%, rgb(182, 227, 212) 50%, rgb(51, 167, 181) 100%);
            --wp--preset--gradient--electric-grass: linear-gradient(135deg, rgb(202, 248, 128) 0%, rgb(113, 206, 126) 100%);
            --wp--preset--gradient--midnight: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
            --wp--preset--duotone--dark-grayscale: url('#wp-duotone-dark-grayscale');
            --wp--preset--duotone--grayscale: url('#wp-duotone-grayscale');
            --wp--preset--duotone--purple-yellow: url('#wp-duotone-purple-yellow');
            --wp--preset--duotone--blue-red: url('#wp-duotone-blue-red');
            --wp--preset--duotone--midnight: url('#wp-duotone-midnight');
            --wp--preset--duotone--magenta-yellow: url('#wp-duotone-magenta-yellow');
            --wp--preset--duotone--purple-green: url('#wp-duotone-purple-green');
            --wp--preset--duotone--blue-orange: url('#wp-duotone-blue-orange');
            --wp--preset--font-size--small: 13px;
            --wp--preset--font-size--medium: 20px;
            --wp--preset--font-size--large: 36px;
            --wp--preset--font-size--x-large: 42px;
        }

        .has-black-color {
            color: var(--wp--preset--color--black) !important;
        }

        .has-cyan-bluish-gray-color {
            color: var(--wp--preset--color--cyan-bluish-gray) !important;
        }

        .has-white-color {
            color: var(--wp--preset--color--white) !important;
        }

        .has-pale-pink-color {
            color: var(--wp--preset--color--pale-pink) !important;
        }

        .has-vivid-red-color {
            color: var(--wp--preset--color--vivid-red) !important;
        }

        .has-luminous-vivid-orange-color {
            color: var(--wp--preset--color--luminous-vivid-orange) !important;
        }

        .has-luminous-vivid-amber-color {
            color: var(--wp--preset--color--luminous-vivid-amber) !important;
        }

        .has-light-green-cyan-color {
            color: var(--wp--preset--color--light-green-cyan) !important;
        }

        .has-vivid-green-cyan-color {
            color: var(--wp--preset--color--vivid-green-cyan) !important;
        }

        .has-pale-cyan-blue-color {
            color: var(--wp--preset--color--pale-cyan-blue) !important;
        }

        .has-vivid-cyan-blue-color {
            color: var(--wp--preset--color--vivid-cyan-blue) !important;
        }

        .has-vivid-purple-color {
            color: var(--wp--preset--color--vivid-purple) !important;
        }

        .has-black-background-color {
            background-color: var(--wp--preset--color--black) !important;
        }

        .has-cyan-bluish-gray-background-color {
            background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
        }

        .has-white-background-color {
            background-color: var(--wp--preset--color--white) !important;
        }

        .has-pale-pink-background-color {
            background-color: var(--wp--preset--color--pale-pink) !important;
        }

        .has-vivid-red-background-color {
            background-color: var(--wp--preset--color--vivid-red) !important;
        }

        .has-luminous-vivid-orange-background-color {
            background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
        }

        .has-luminous-vivid-amber-background-color {
            background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
        }

        .has-light-green-cyan-background-color {
            background-color: var(--wp--preset--color--light-green-cyan) !important;
        }

        .has-vivid-green-cyan-background-color {
            background-color: var(--wp--preset--color--vivid-green-cyan) !important;
        }

        .has-pale-cyan-blue-background-color {
            background-color: var(--wp--preset--color--pale-cyan-blue) !important;
        }

        .has-vivid-cyan-blue-background-color {
            background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
        }

        .has-vivid-purple-background-color {
            background-color: var(--wp--preset--color--vivid-purple) !important;
        }

        .has-black-border-color {
            border-color: var(--wp--preset--color--black) !important;
        }

        .has-cyan-bluish-gray-border-color {
            border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
        }

        .has-white-border-color {
            border-color: var(--wp--preset--color--white) !important;
        }

        .has-pale-pink-border-color {
            border-color: var(--wp--preset--color--pale-pink) !important;
        }

        .has-vivid-red-border-color {
            border-color: var(--wp--preset--color--vivid-red) !important;
        }

        .has-luminous-vivid-orange-border-color {
            border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
        }

        .has-luminous-vivid-amber-border-color {
            border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
        }

        .has-light-green-cyan-border-color {
            border-color: var(--wp--preset--color--light-green-cyan) !important;
        }

        .has-vivid-green-cyan-border-color {
            border-color: var(--wp--preset--color--vivid-green-cyan) !important;
        }

        .has-pale-cyan-blue-border-color {
            border-color: var(--wp--preset--color--pale-cyan-blue) !important;
        }

        .has-vivid-cyan-blue-border-color {
            border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
        }

        .has-vivid-purple-border-color {
            border-color: var(--wp--preset--color--vivid-purple) !important;
        }

        .has-vivid-cyan-blue-to-vivid-purple-gradient-background {
            background: var(--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple) !important;
        }

        .has-light-green-cyan-to-vivid-green-cyan-gradient-background {
            background: var(--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan) !important;
        }

        .has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
            background: var(--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange) !important;
        }

        .has-luminous-vivid-orange-to-vivid-red-gradient-background {
            background: var(--wp--preset--gradient--luminous-vivid-orange-to-vivid-red) !important;
        }

        .has-very-light-gray-to-cyan-bluish-gray-gradient-background {
            background: var(--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray) !important;
        }

        .has-cool-to-warm-spectrum-gradient-background {
            background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
        }

        .has-blush-light-purple-gradient-background {
            background: var(--wp--preset--gradient--blush-light-purple) !important;
        }

        .has-blush-bordeaux-gradient-background {
            background: var(--wp--preset--gradient--blush-bordeaux) !important;
        }

        .has-luminous-dusk-gradient-background {
            background: var(--wp--preset--gradient--luminous-dusk) !important;
        }

        .has-pale-ocean-gradient-background {
            background: var(--wp--preset--gradient--pale-ocean) !important;
        }

        .has-electric-grass-gradient-background {
            background: var(--wp--preset--gradient--electric-grass) !important;
        }

        .has-midnight-gradient-background {
            background: var(--wp--preset--gradient--midnight) !important;
        }

        .has-small-font-size {
            font-size: var(--wp--preset--font-size--small) !important;
        }

        .has-medium-font-size {
            font-size: var(--wp--preset--font-size--medium) !important;
        }

        .has-large-font-size {
            font-size: var(--wp--preset--font-size--large) !important;
        }

        .has-x-large-font-size {
            font-size: var(--wp--preset--font-size--x-large) !important;
        }
    </style>
    <link rel="stylesheet" id="simple-banner-style-css" href="${simpleBanner}" type="text/css" media="all" />
    <link rel="stylesheet" id="wp-pagenavi-css" href="${pageNavi}" type="text/css" media="all" />
    <link rel="stylesheet" id="themify-icons-css" href="css/themify-icons.min.css" type="text/css" media="all" />
    <link rel="stylesheet" id="linea-css" href="css/linea.min.css" type="text/css" media="all" />
    <link rel="stylesheet" id="animate-css" href="${animateMin}" type="text/css" media="all" />
    <link rel="stylesheet" id="hover-css" href="${hoverMin}" type="text/css" media="all" />
    <link rel="stylesheet" id="nice-select-css" href="${niceSelectMin}" type="text/css" media="all" />
    <link rel="stylesheet" id="meanmenu-css" href="${meanMenu}" type="text/css" media="all" />
    <link rel="stylesheet" id="bootstrap-css" href="${bootstrapMin}" type="text/css" media="all" />
    <link rel="stylesheet" id="saashub-styles-css" href="css/styles.css" type="text/css" media="all" />
    <link rel="stylesheet" id="saashub-responsive-css" href="${responsive}" type="text/css" media="all" />
    <link rel="stylesheet" id="elementor-frontend-css" href="${frontendLite}" type="text/css" media="all" />
    <link rel="stylesheet" id="elementor-post-4354-css" href="${post4354}" type="text/css" media="all" />
    <link rel="stylesheet" id="elementor-post-4586-css" href="${post4586}" type="text/css" media="all" />
    <link rel="stylesheet" id="malinky-ajax-pagination-css" href="css/style.css" type="text/css" media="all" />
    <link rel="stylesheet" id="google-fonts-1-css"
        href="https://fonts.googleapis.com/css?family=Roboto%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CRoboto+Slab%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CMontserrat%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&#038;display=auto&#038;ver=6.0"
        type="text/css" media="all" />
    <link rel="icon" href="${croppedWebpnetresizeimage32x32}" sizes="32x32" />
    <link rel="icon" href="${croppedWebpnetresizeimage192x192}" sizes="192x192" />
    <link rel="apple-touch-icon" href="${croppedWebpnetresizeimage180x180}" />
    <meta name="msapplication-TileImage" content="${croppedWebpnetresizeimage270x270}" />
    <style type="text/css" id="wp-custom-css">
        img {
            max-width: 100%;
            height: auto;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }


        @media(max-width:1199px) {
            .mean-container .mean-nav ul li#menu-item-5387 {
                padding: 10px 25px;
            }

            .mean-container .mean-nav ul li#menu-item-5387 {
                text-align: center;
            }

            #menu-item-4681.menu-item-4681 {
                margin-left: 0;
            }
        }

        @media(max-width:767px) {
            .footer-widget p {
                margin-bottom: 0;
            }
        }
    </style>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.4/jquery.min.js">
    </script>
</head>

<body href="${index}"
    class="home page-template-default page page-id-4586 wp-custom-logo elementor-default elementor-kit-4354 elementor-page elementor-page-4586">
    <div class="sahub-main-wrap ">
        <div class="main-wrap-inner">
            <div class="sahub-mid-wrap sahub-page-wrap padding-default full-width" style>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div data-elementor-type="wp-page" data-elementor-id="4586" class="elementor elementor-4586"
                                data-elementor-settings="[]">
                                <div class="elementor-section-wrap">
                                    <section class="section section-relative " id="page-block-anptm40e1sb"
                                        data-at="section">
                                        <div class="section-holder-border item-block item-absolute"
                                            data-at="section-border"></div>
                                        <div class="section-holder-overlay item-block item-absolute"
                                            data-at="section-overlay"></div>
                                        <div class="section-block">
                                            <div class="section-inner section-fit section-relative">
                                                <div class="widget item-absolute  " id="element-134">
                                                    <div class="contents cropped item-block" data-at="image-cropp">
                                                        <img src="${logoImage}" alt="LendCRM" class="default-logo"
                                                            width="120" height="100">
                                                    </div>
                                                </div>
                                                <div class="widget item-absolute " id="element-78">
                                                    <div class="contents shape  box figure " data-at="shape"></div>
                                                </div>
                                                <div class="widget item-absolute  " id="element-76">
                                                    <form 
                                                        class="form email-form contents form-labels-outside-top"
                                                        id="myForm" method="post" data-id="element-76" data-at="form"
                                                        action-xhr="https://ct.capterra.com/capterra_tracker.gif?vid=2175883&vkey=461ae8ac24f37913826d42f173c7ee14">

                                                        <label class="form-label-title form-label-outside form-label"
                                                            for="field-dea4c2aa9aff1d39dbe74d94eadd665c-0"
                                                            data-at="form-text-title">Full Name *</label>
                                                        <input id="field-dea4c2aa9aff1d39dbe74d94eadd665c-0"
                                                            class="form-input form-input-text required  "
                                                            data-at="form-text" type="text" name="fullName"
                                                            data-describedby="form-validation-error-box-element-76"
                                                            value="" title="Full Name *" placeholder=""
                                                            data-label-inside="Full Name *" required
                                                            aria-required="true" />

                                                        <label class="form-label-title form-label-outside form-label"
                                                            for="field-dea4c2aa9aff1d39dbe74d94eadd665c-1"
                                                            data-at="form-email-title">Work Email *</label>
                                                        <input id="field-dea4c2aa9aff1d39dbe74d94eadd665c-1"
                                                            class="form-input form-input-text required  " type="email"
                                                            name="email"
                                                            data-describedby="form-validation-error-box-element-76"
                                                            value="" title="Work Email *"
                                                            data-label-inside="Work Email *" data-at="form-email"
                                                            placeholder="" required aria-required="true" />

                                                        <label class="form-label-title form-label-outside form-label"
                                                            for="field-dea4c2aa9aff1d39dbe74d94eadd665c-2"
                                                            data-at="form-text-title">Company * </label>
                                                        <input id="field-dea4c2aa9aff1d39dbe74d94eadd665c-2"
                                                            class="form-input form-input-text required  "
                                                            data-at="form-text" type="text" name="company"
                                                            data-describedby="form-validation-error-box-element-76"
                                                            value="" title="Company *" placeholder=""
                                                            data-label-inside="Company * " required
                                                            aria-required="true" />

                                                        <label class="form-label-title form-label-outside form-label"
                                                            for="field-dea4c2aa9aff1d39dbe74d94eadd665c-3"
                                                            data-at="form-text-title">Phone Number *</label>
                                                        <input id="field-dea4c2aa9aff1d39dbe74d94eadd665c-3"
                                                            class="form-input form-input-text required  "
                                                            data-at="form-text" type="text" name="phone"
                                                            data-describedby="form-validation-error-box-element-76"
                                                            value="" title="Phone Number *" placeholder=""
                                                            data-label-inside="Phone Number *" required
                                                            aria-required="true" />
                                                        <input type="hidden" name="ipAddress" id="ipAddress" value=""
                                                            data-at="form-hidden-input" />
                                                        <div class="item-absolute form-btn-geometry">
                                                            <button class="btn form-btn item-block  "
                                                                data-at="form-button">
                                                                Schedule Demo
                                                            </button>
                                                        </div>

                                                    </form>

                                                    <div id="form-validation-error-box-element-76"
                                                        class="item-cover item-absolute form-messagebox"
                                                        data-at="form-validation-box" style="display:none;">
                                                        <div class="form-messagebox-contents"
                                                            data-at="form-validation-box-message"></div>
                                                    </div>
                                                </div>

                                                <div class="widget item-absolute headline hidden-mobile "
                                                    id="element-173" data-at="headline">
                                                    <div class="contents">
                                                        <h1>
                                                            <span class="x_0a34168d"><span
                                                                    class="x_f929cec8">Cloud-based Loan Management
                                                                    Software</span></span>
                                                        </h1>
                                                    </div>
                                                </div>

                                                <div class="widget item-absolute hidden-mobile" id="element-172">
                                                    <div class="contents shape  line-horizontal line " data-at="shape">
                                                    </div>
                                                </div>

                                                <div class="widget item-absolute headline  " id="element-23"
                                                    data-at="headline">
                                                    <div class="contents">
                                                        <h1  " id="element-23">
                                                            <span class="x_1ea61c39 x_21a56353"><span
                                                                    class="x_f929cec8">Step into LendCRM's
                                                                    transformative blend of
                                                                    cloud-based loan management and CRM prowess.
                                                                </span></span>
                                                        </h1>
                                                    </div>
                                                </div>

                                                <div class="widget item-absolute paragraph  " id="element-24"
                                                    data-at="paragraph">
                                                    <div class="contents">
                                                        <p class="x_8ae9aa76 x_b6c3675a"><span
                                                                class="x_93908647">Discover LendCRM: Your all-in-one
                                                                solution for
                                                                cloud-based loan origination and powerful CRM
                                                                functionality. Tailored loan programs, automated sales
                                                                processes, and personalized borrower profiles—streamline
                                                                your lending operations effortlessly.</span>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="widget item-absolute paragraph  " id="element-209"
                                                    data-at="paragraph">
                                                    <div class="contents">
                                                        <p class="x_70ee4658"><span class="x_93908647">By proceeding,
                                                                you agree to provide your information to
                                                                LendCRM to be used in accordance with the <a
                                                                    href="https://lendcrm.com/privacy-policy/"
                                                                    id="link-lend-privacy" target="_blank"
                                                                    data-link-lend-privacy class="url-link"><u>LendCRM
                                                                        Privacy
                                                                        Policy</u></a>.</span></p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </section>
                                    <!-- Carousel section -->
                                    <section
                                        class="elementor-section elementor-top-section elementor-element elementor-element-24fa316 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                                        data-id="24fa316" data-element_type="section"
                                        data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7c41ecc"
                                                data-id="7c41ecc" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-579e281 elementor-widget elementor-widget-heading"
                                                        data-id="579e281" data-element_type="widget"
                                                        data-widget_type="heading.default">
                                                        <div class="elementor-widget-container">
                                                            <h2 class="elementor-heading-title elementor-size-default">
                                                                How does it Work</h2>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-4e0fa4c elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
                                                        data-id="4e0fa4c" data-element_type="widget"
                                                        data-widget_type="text-editor.default">
                                                        <div class="elementor-widget-container">
                                                            <p>Make your work easier with an integrated ecosystem that
                                                                lets your team work properly together to Manage Loan
                                                                applications, Process, Underwrite, Close and Service
                                                                loans.</p>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-db2b768 elementor-widget__width-inherit elementor-arrows-position-inside elementor-widget elementor-widget-image-carousel"
                                                        data-id="db2b768" data-element_type="widget"
                                                        data-settings="{&quot;slides_to_show&quot;:&quot;1&quot;,&quot;navigation&quot;:&quot;arrows&quot;,&quot;autoplay&quot;:&quot;yes&quot;,&quot;pause_on_hover&quot;:&quot;yes&quot;,&quot;pause_on_interaction&quot;:&quot;yes&quot;,&quot;autoplay_speed&quot;:5000,&quot;infinite&quot;:&quot;yes&quot;,&quot;effect&quot;:&quot;slide&quot;,&quot;speed&quot;:500}"
                                                        data-widget_type="image-carousel.default">
                                                        <div class="elementor-widget-container">
                                                            <style>
                                                                /*! elementor - v3.5.6 - 28-02-2022 */
                                                                .elementor-widget-image-carousel .swiper-container {
                                                                    position: static
                                                                }

                                                                .elementor-widget-image-carousel .swiper-container .swiper-slide figure {
                                                                    line-height: inherit
                                                                }

                                                                .elementor-widget-image-carousel .swiper-slide {
                                                                    text-align: center
                                                                }

                                                                .elementor-image-carousel-wrapper:not(.swiper-container-initialized) .swiper-slide {
                                                                    max-width: calc(100% / var(--e-image-carousel-slides-to-show, 3))
                                                                }
                                                            </style>
                                                            <div class="elementor-image-carousel-wrapper swiper-container"
                                                                dir="ltr">
                                                                <div class="elementor-image-carousel swiper-wrapper">
                                                                    <div class="swiper-slide">
                                                                        <figure class="swiper-slide-inner"><img
                                                                                class="swiper-slide-image"
                                                                                src="${group18}"
                                                                                alt="Group 18" /></figure>
                                                                    </div>
                                                                    <div class="swiper-slide">
                                                                        <figure class="swiper-slide-inner"><img
                                                                                class="swiper-slide-image"
                                                                                src="${group68}"
                                                                                alt="Group 68" /></figure>
                                                                    </div>
                                                                    <div class="swiper-slide">
                                                                        <figure class="swiper-slide-inner"><img
                                                                                class="swiper-slide-image"
                                                                                src="${maskgroup}"
                                                                                alt="Mask group" /></figure>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="elementor-swiper-button elementor-swiper-button-prev">
                                                                    <svg aria-hidden="true"
                                                                        class="e-font-icon-svg e-eicon-chevron-left">
                                                                        <use xlink:href="#eicon-chevron-left" />
                                                                    </svg> <span
                                                                        class="elementor-screen-only">Previous</span>
                                                                </div>
                                                                <div
                                                                    class="elementor-swiper-button elementor-swiper-button-next">
                                                                    <svg aria-hidden="true"
                                                                        class="e-font-icon-svg e-eicon-chevron-right">
                                                                        <use xlink:href="#eicon-chevron-right" />
                                                                    </svg> <span
                                                                        class="elementor-screen-only">Next</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section
                                        class="elementor-section elementor-top-section elementor-element elementor-element-b0debf9 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                        data-id="b0debf9" data-element_type="section">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b2908e4"
                                                data-id="b2908e4" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-7c5544f elementor-widget elementor-widget-heading"
                                                        data-id="7c5544f" data-element_type="widget"
                                                        data-widget_type="heading.default">
                                                        <div class="elementor-widget-container">
                                                            <style>
                                                                /*! elementor - v3.5.6 - 28-02-2022 */
                                                                .elementor-heading-title {
                                                                    padding: 0;
                                                                    margin: 0;
                                                                    line-height: 1
                                                                }

                                                                .elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a {
                                                                    color: inherit;
                                                                    font-size: inherit;
                                                                    line-height: inherit
                                                                }

                                                                .elementor-widget-heading .elementor-heading-title.elementor-size-small {
                                                                    font-size: 15px
                                                                }

                                                                .elementor-widget-heading .elementor-heading-title.elementor-size-medium {
                                                                    font-size: 19px
                                                                }

                                                                .elementor-widget-heading .elementor-heading-title.elementor-size-large {
                                                                    font-size: 29px
                                                                }

                                                                .elementor-widget-heading .elementor-heading-title.elementor-size-xl {
                                                                    font-size: 39px
                                                                }

                                                                .elementor-widget-heading .elementor-heading-title.elementor-size-xxl {
                                                                    font-size: 59px
                                                                }
                                                            </style>
                                                            <h2 class="elementor-heading-title elementor-size-default">
                                                                The Easiest Way to Originate &amp; Service Loans</h2>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-3a29523 elementor-widget elementor-widget-text-editor"
                                                        data-id="3a29523" data-element_type="widget"
                                                        data-widget_type="text-editor.default">
                                                        <div class="elementor-widget-container">
                                                            <style>
                                                                /*! elementor - v3.5.6 - 28-02-2022 */
                                                                .elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {
                                                                    background-color: #818a91;
                                                                    color: #fff
                                                                }

                                                                .elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {
                                                                    color: #818a91;
                                                                    border: 3px solid;
                                                                    background-color: transparent
                                                                }

                                                                .elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {
                                                                    margin-top: 8px
                                                                }

                                                                .elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {
                                                                    width: 1em;
                                                                    height: 1em
                                                                }

                                                                .elementor-widget-text-editor .elementor-drop-cap {
                                                                    float: left;
                                                                    text-align: center;
                                                                    line-height: 1;
                                                                    font-size: 50px
                                                                }

                                                                .elementor-widget-text-editor .elementor-drop-cap-letter {
                                                                    display: inline-block
                                                                }
                                                            </style>
                                                            <p>Create, customize, manage loan programs to manage Loan
                                                                Applications, Process, Underwrite, Close and Service
                                                                Loans.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section
                                        class="elementor-section elementor-top-section elementor-element elementor-element-bf8d60b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                        data-id="bf8d60b" data-element_type="section">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-e52920b"
                                                data-id="e52920b" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-3d1c75b elementor-widget elementor-widget-heading"
                                                        data-id="3d1c75b" data-element_type="widget"
                                                        data-widget_type="heading.default">
                                                        <div class="elementor-widget-container">
                                                            <h2 class="elementor-heading-title elementor-size-default">
                                                                01</h2>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-658918a elementor-widget elementor-widget-image"
                                                        data-id="658918a" data-element_type="widget"
                                                        data-widget_type="image.default">
                                                        <div class="elementor-widget-container">
                                                            <style>
                                                                /*! elementor - v3.5.6 - 28-02-2022 */
                                                                .elementor-widget-image {
                                                                    text-align: center
                                                                }

                                                                .elementor-widget-image a {
                                                                    display: inline-block
                                                                }

                                                                .elementor-widget-image a img[src$=".svg"] {
                                                                    width: 48px
                                                                }

                                                                .elementor-widget-image img {
                                                                    vertical-align: middle;
                                                                    display: inline-block
                                                                }
                                                            </style> <a href="https://lendcrm.com/crm/">
                                                                <img width="42" height="42"
                                                                    src="${management1}"
                                                                    class="attachment-large size-large" alt
                                                                    loading="lazy" /> </a>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-a4cd349 elementor-widget elementor-widget-heading"
                                                        data-id="a4cd349" data-element_type="widget"
                                                        data-widget_type="heading.default">
                                                        <div class="elementor-widget-container">
                                                            <h3 class="elementor-heading-title elementor-size-default">
                                                                CRM</h3>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-2ac9454 elementor-widget elementor-widget-text-editor"
                                                        data-id="2ac9454" data-element_type="widget"
                                                        data-widget_type="text-editor.default">
                                                        <div class="elementor-widget-container">
                                                            <p>Collect Loan Applications &amp; Automate all your sales
                                                                process to<br />nurture and build relationships with
                                                                Borrowers, Brokers &amp; Investors</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-93f3cf9"
                                                data-id="93f3cf9" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-c1ea3b7 elementor-widget elementor-widget-heading"
                                                        data-id="c1ea3b7" data-element_type="widget"
                                                        data-widget_type="heading.default">
                                                        <div class="elementor-widget-container">
                                                            <h2 class="elementor-heading-title elementor-size-default">
                                                                02
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-9799d14 elementor-widget elementor-widget-image"
                                                        data-id="9799d14" data-element_type="widget"
                                                        data-widget_type="image.default">
                                                        <div class="elementor-widget-container">
                                                            <a href="https://lendcrm.com/loan-origination/">
                                                                <img width="40" height="40"
                                                                    src="${moneybag2}"
                                                                    class="attachment-large size-large" alt
                                                                    loading="lazy" /> </a>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-c8c15c6 elementor-widget elementor-widget-heading"
                                                        data-id="c8c15c6" data-element_type="widget"
                                                        data-widget_type="heading.default">
                                                        <div class="elementor-widget-container">
                                                            <h3 class="elementor-heading-title elementor-size-default">
                                                                Loan
                                                                Origination</h3>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-0aadf49 elementor-widget elementor-widget-text-editor"
                                                        data-id="0aadf49" data-element_type="widget"
                                                        data-widget_type="text-editor.default">
                                                        <div class="elementor-widget-container">
                                                            <p>Tailor your lending process to support different loan
                                                                programs and originate<br />on the fly</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-a6749ba"
                                                data-id="a6749ba" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-39903b8 elementor-widget elementor-widget-heading"
                                                        data-id="39903b8" data-element_type="widget"
                                                        data-widget_type="heading.default">
                                                        <div class="elementor-widget-container">
                                                            <h2 class="elementor-heading-title elementor-size-default">
                                                                03</h2>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-8054ccb elementor-widget elementor-widget-image"
                                                        data-id="8054ccb" data-element_type="widget"
                                                        data-widget_type="image.default">
                                                        <div class="elementor-widget-container">
                                                            <a href="https://lendcrm.com/loan-origination/">
                                                                <img width="41" height="41" src="${rating3}"
                                                                    class="attachment-large size-large" alt="step 1"
                                                                    loading="lazy" /> </a>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-c70655c elementor-widget elementor-widget-heading"
                                                        data-id="c70655c" data-element_type="widget"
                                                        data-widget_type="heading.default">
                                                        <div class="elementor-widget-container">
                                                            <h3 class="elementor-heading-title elementor-size-default">
                                                                Loan Pricing &amp; Underwriting</h3>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-aa1e651 elementor-widget elementor-widget-text-editor"
                                                        data-id="aa1e651" data-element_type="widget"
                                                        data-widget_type="text-editor.default">
                                                        <div class="elementor-widget-container">
                                                            <p>Calculate Interest rate &amp; Monthly Payments based on
                                                                Borrower profile, Payment Schedule &amp; Loan Programs.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-42e6c6e"
                                                data-id="42e6c6e" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-fa59f60 elementor-widget elementor-widget-heading"
                                                        data-id="fa59f60" data-element_type="widget"
                                                        data-widget_type="heading.default">
                                                        <div class="elementor-widget-container">
                                                            <h2 class="elementor-heading-title elementor-size-default">
                                                                04</h2>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-63f61e8 elementor-widget elementor-widget-image"
                                                        data-id="63f61e8" data-element_type="widget"
                                                        data-widget_type="image.default">
                                                        <div class="elementor-widget-container">
                                                            <a href="https://lendcrm.com/loan-servicing/">
                                                                <img width="41" height="41"
                                                                    src="${connection4}"
                                                                    class="attachment-large size-large" alt
                                                                    loading="lazy" /> </a>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-cd29018 elementor-widget elementor-widget-heading"
                                                        data-id="cd29018" data-element_type="widget"
                                                        data-widget_type="heading.default">
                                                        <div class="elementor-widget-container">
                                                            <h3 class="elementor-heading-title elementor-size-default">
                                                                Loan <br>Servicing</h3>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-87f06a4 elementor-widget elementor-widget-text-editor"
                                                        data-id="87f06a4" data-element_type="widget"
                                                        data-widget_type="text-editor.default">
                                                        <div class="elementor-widget-container">
                                                            <p>Manage Loan tapes, Verify bank statements, Collect One
                                                                time Payments &amp; Monthly Payments</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footer class="sahub-footer footer-style-one">
            <div class="footer-wrap">
                <div class="container">

                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-6 segva-item " style="
                        display: flex;
                        flex-direction: column;
                        align-content: flex-start;
                        justify-content: flex-start;
                    ">
                            <div class="footer-widget widget_block widget_media_image" style="
                            align-self: flex-start;
                        ">
                                <figure class="wp-block-image size-full is-resized"><a
                                        href="https://lendcrm.subhadeeps.com/homepage/"><img
                                            src="${LendCRMLogo}" alt class="wp-image-3923" width="120"
                                            srcset="${LendCRMLogo}" 1025w, https://lendcrm.com/wp-content/uploads/2021/03/LendCRM_Logo-600x412.png 600w, https://lendcrm.com/wp-content/uploads/2021/03/LendCRM_Logo-300x206.png 300w, https://lendcrm.com/wp-content/uploads/2021/03/LendCRM_Logo-768x527.png 768w"
                                            sizes="(max-width: 1025px) 100vw, 1025px" /></a></figure>
                                <div class="clear"></div>
                            </div>
                            <div class="footer-widget widget_block widget_text">
                                <p>26077 Nelson way, Katy, TX, 77494, #702</p>
                                <div class="clear"></div>
                            </div>
                            <div class="footer-widget widget_block widget_text">
                                <p><a href="tel:+13463317420">+1 (346)-331-7420</a></p>
                                <div class="clear"></div>
                            </div>
                            <div class="footer-widget widget_block widget_text">
                                <p><a href="/cdn-cgi/l/email-protection#f69f989099b69a93989295849bd895999b"><span
                                            class="__cf_email__"
                                            data-cfemail="066f686069466a63686265746b2865696b">info@lendcrm.com</span></a>
                                </p>
                                <div class="clear"></div>
                            </div>
                            <div class="footer-widget widget_block">
                                <ul class="wp-container-1 wp-block-social-links is-style-default">
                                    <li class="wp-social-link wp-social-link-facebook wp-block-social-link"><a
                                            href="https://www.facebook.com/LendCRM/"
                                            class="wp-block-social-link-anchor"><svg width="24" height="24"
                                                viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true" focusable="false">
                                                <path
                                                    d="M12 2C6.5 2 2 6.5 2 12c0 5 3.7 9.1 8.4 9.9v-7H7.9V12h2.5V9.8c0-2.5 1.5-3.9 3.8-3.9 1.1 0 2.2.2 2.2.2v2.5h-1.3c-1.2 0-1.6.8-1.6 1.6V12h2.8l-.4 2.9h-2.3v7C18.3 21.1 22 17 22 12c0-5.5-4.5-10-10-10z">
                                                </path>
                                            </svg><span
                                                class="wp-block-social-link-label screen-reader-text">Facebook</span></a>
                                    </li>
                                    <li class="wp-social-link wp-social-link-linkedin wp-block-social-link"><a
                                            href="https://www.linkedin.com/company/76726097"
                                            class="wp-block-social-link-anchor"><svg width="24" height="24"
                                                viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true" focusable="false">
                                                <path
                                                    d="M19.7,3H4.3C3.582,3,3,3.582,3,4.3v15.4C3,20.418,3.582,21,4.3,21h15.4c0.718,0,1.3-0.582,1.3-1.3V4.3 C21,3.582,20.418,3,19.7,3z M8.339,18.338H5.667v-8.59h2.672V18.338z M7.004,8.574c-0.857,0-1.549-0.694-1.549-1.548 c0-0.855,0.691-1.548,1.549-1.548c0.854,0,1.547,0.694,1.547,1.548C8.551,7.881,7.858,8.574,7.004,8.574z M18.339,18.338h-2.669 v-4.177c0-0.996-0.017-2.278-1.387-2.278c-1.389,0-1.601,1.086-1.601,2.206v4.249h-2.667v-8.59h2.559v1.174h0.037 c0.356-0.675,1.227-1.387,2.526-1.387c2.703,0,3.203,1.779,3.203,4.092V18.338z">
                                                </path>
                                            </svg><span
                                                class="wp-block-social-link-label screen-reader-text">LinkedIn</span></a>
                                    </li>
                                    <li class="wp-social-link wp-social-link-instagram wp-block-social-link"><a
                                            href="https://www.instagram.com/lendcrm/"
                                            class="wp-block-social-link-anchor"><svg width="24" height="24"
                                                viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true" focusable="false">
                                                <path
                                                    d="M12,4.622c2.403,0,2.688,0.009,3.637,0.052c0.877,0.04,1.354,0.187,1.671,0.31c0.42,0.163,0.72,0.358,1.035,0.673 c0.315,0.315,0.51,0.615,0.673,1.035c0.123,0.317,0.27,0.794,0.31,1.671c0.043,0.949,0.052,1.234,0.052,3.637 s-0.009,2.688-0.052,3.637c-0.04,0.877-0.187,1.354-0.31,1.671c-0.163,0.42-0.358,0.72-0.673,1.035 c-0.315,0.315-0.615,0.51-1.035,0.673c-0.317,0.123-0.794,0.27-1.671,0.31c-0.949,0.043-1.233,0.052-3.637,0.052 s-2.688-0.009-3.637-0.052c-0.877-0.04-1.354-0.187-1.671-0.31c-0.42-0.163-0.72-0.358-1.035-0.673 c-0.315-0.315-0.51-0.615-0.673-1.035c-0.123-0.317-0.27-0.794-0.31-1.671C4.631,14.688,4.622,14.403,4.622,12 s0.009-2.688,0.052-3.637c0.04-0.877,0.187-1.354,0.31-1.671c0.163-0.42,0.358-0.72,0.673-1.035 c0.315-0.315,0.615-0.51,1.035-0.673c0.317-0.123,0.794-0.27,1.671-0.31C9.312,4.631,9.597,4.622,12,4.622 M12,3 C9.556,3,9.249,3.01,8.289,3.054C7.331,3.098,6.677,3.25,6.105,3.472C5.513,3.702,5.011,4.01,4.511,4.511 c-0.5,0.5-0.808,1.002-1.038,1.594C3.25,6.677,3.098,7.331,3.054,8.289C3.01,9.249,3,9.556,3,12c0,2.444,0.01,2.751,0.054,3.711 c0.044,0.958,0.196,1.612,0.418,2.185c0.23,0.592,0.538,1.094,1.038,1.594c0.5,0.5,1.002,0.808,1.594,1.038 c0.572,0.222,1.227,0.375,2.185,0.418C9.249,20.99,9.556,21,12,21s2.751-0.01,3.711-0.054c0.958-0.044,1.612-0.196,2.185-0.418 c0.592-0.23,1.094-0.538,1.594-1.038c0.5-0.5,0.808-1.002,1.038-1.594c0.222-0.572,0.375-1.227,0.418-2.185 C20.99,14.751,21,14.444,21,12s-0.01-2.751-0.054-3.711c-0.044-0.958-0.196-1.612-0.418-2.185c-0.23-0.592-0.538-1.094-1.038-1.594 c-0.5-0.5-1.002-0.808-1.594-1.038c-0.572-0.222-1.227-0.375-2.185-0.418C14.751,3.01,14.444,3,12,3L12,3z M12,7.378 c-2.552,0-4.622,2.069-4.622,4.622S9.448,16.622,12,16.622s4.622-2.069,4.622-4.622S14.552,7.378,12,7.378z M12,15 c-1.657,0-3-1.343-3-3s1.343-3,3-3s3,1.343,3,3S13.657,15,12,15z M16.804,6.116c-0.596,0-1.08,0.484-1.08,1.08 s0.484,1.08,1.08,1.08c0.596,0,1.08-0.484,1.08-1.08S17.401,6.116,16.804,6.116z">
                                                </path>
                                            </svg><span
                                                class="wp-block-social-link-label screen-reader-text">Instagram</span></a>
                                    </li>
                                    <li class="wp-social-link wp-social-link-twitter wp-block-social-link"><a
                                            href="https://twitter.com/lendcrm" class="wp-block-social-link-anchor"><svg
                                                width="24" height="24" viewBox="0 0 24 24" version="1.1"
                                                xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
                                                <path
                                                    d="M22.23,5.924c-0.736,0.326-1.527,0.547-2.357,0.646c0.847-0.508,1.498-1.312,1.804-2.27 c-0.793,0.47-1.671,0.812-2.606,0.996C18.324,4.498,17.257,4,16.077,4c-2.266,0-4.103,1.837-4.103,4.103 c0,0.322,0.036,0.635,0.106,0.935C8.67,8.867,5.647,7.234,3.623,4.751C3.27,5.357,3.067,6.062,3.067,6.814 c0,1.424,0.724,2.679,1.825,3.415c-0.673-0.021-1.305-0.206-1.859-0.513c0,0.017,0,0.034,0,0.052c0,1.988,1.414,3.647,3.292,4.023 c-0.344,0.094-0.707,0.144-1.081,0.144c-0.264,0-0.521-0.026-0.772-0.074c0.522,1.63,2.038,2.816,3.833,2.85 c-1.404,1.1-3.174,1.756-5.096,1.756c-0.331,0-0.658-0.019-0.979-0.057c1.816,1.164,3.973,1.843,6.29,1.843 c7.547,0,11.675-6.252,11.675-11.675c0-0.178-0.004-0.355-0.012-0.531C20.985,7.47,21.68,6.747,22.23,5.924z">
                                                </path>
                                            </svg><span
                                                class="wp-block-social-link-label screen-reader-text">Twitter</span></a>
                                    </li>
                                </ul>
                                <div class="clear"></div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 segva-item ">
                            <div class="footer-widget widget_block">
                                <div class="clear"></div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 segva-item ">
                            <div class="footer-widget widget_nav_menu">
                                </ul>
                                <div class="clear"></div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 segva-item ">
                            <div class="widget_text footer-widget widget_custom_html">
                                <h3 class="footer-widget-title">Site Information</h3>
                                <div class="textwidget custom-html-widget">
                                    <ul>
                                        <li><a href="https://lendcrm.com/privacy-policy/">Privacy Policy</a></li>
                                    </ul>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </footer>

    </div>

    <div class="sahub-back-top">
        <a href="javascript:void(0);">
            <span class="sahub-table-wrap">
                <span class="sahub-align-wrap">
                    <i class="fa fa-angle-up" aria-hidden="true"></i>
                </span>
            </span>
        </a>
    </div>
    <div class="simple-banner simple-banner-text" style="display:none !important"></div>
    <style>
        .wp-container-1 {
            display: flex;
            gap: 0.5em;
            flex-wrap: wrap;
            align-items: center;
        }

        .wp-container-1>* {
            margin: 0;
        }
    </style>
    <link rel="stylesheet" id="elementor-post-4589-css" href="${post4589}" type="text/css" media="all" />
    <link rel="stylesheet" id="e-animations-css" href="${animationsMin}" type="text/css" media="all" />
    <link rel="stylesheet" id="google-fonts-2-css"
        href="https://fonts.googleapis.com/css?family=Barlow%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CCaveat%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&#038;display=auto&#038;ver=6.0"
        type="text/css" media="all" />
    <script src="JS/html5shiv.min.js"></script>
   </body>

</html>`;

export default htmlContent;