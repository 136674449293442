import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const RequiredFields = (props) => {
  const [open, setPopupOpen] = useState(false);
  let form = [...props.state.form.content];

  const getFields = (config) => {
    let fields = [];
    Array.isArray(config) &&
      config.forEach((c) => {
        if (
          c.type !== "container" &&
          c.type !== "grid" &&
          c.type !== "item" &&
          c.type !== "tab" &&
          c.type !== "section" &&
          c.type !== "file"
        ) {
          if (props.state.requiredFields.includes(c.id)) {
            fields = [...fields, c];
          }
        }
        if (c.childs) fields = [...fields, ...getFields(c.childs)];
      });
    return fields;
  };

  const getSections = (config) => {
    let sec = [];
    Array.isArray(config) &&
      config.forEach((c) => {
        if (c.type === "section") {
          let fields = [];
          if (!c.cloneable) fields = getFields(c.childs);
          if (fields.length > 0) {
            c.fields = fields;
            sec = [...sec, c];
          }
        }
        if (c.childs) sec = [...sec, ...getSections(c.childs)];
      });
    return sec;
  };

  const getTabs = (config) => {
    let tabs = [];
    Array.isArray(config) &&
      config.forEach((c) => {
        if (c.type === "item") {
          let sections = [];
          sections = getSections(c.childs);
          if (sections.length > 0) {
            c.sections = sections;
            tabs = [...tabs, c];
          }
        }
        if (c.childs) tabs = [...tabs, ...getTabs(c.childs)];
      });
    return tabs;
  };

  let tabs = getTabs(form);

  const setActiveTab = (tab) => {
    let st = { ...props.state };
    st.form.activeTab = tab;
    props.events.setModuleInfo(st);
    setPopupOpen(false);
  };

  return (
    <div className="mb-3">
      <div className="col-md-12 text-danger">
        {props.state.isTabForm ? (
          <span>
            Please review all highlighted tabs and fill mandatory fields
            <i
              className="fa fa fa-comments fa-2x ml-3"
              aria-hidden="true"
              onClick={() => setPopupOpen(open ? false : true)}
            />
          </span>
        ) : (
          <span>Please fill all mandatory fields</span>
        )}
      </div>
      <Modal
        show={open}
        size="lg"
        onHide={() => setPopupOpen(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h4>Required Fields</h4>
        </Modal.Header>
        <Modal.Body>
          {tabs.map((t, i) => (
            <span key={i}>
              {i > 0 && <hr />}
              <span
                style={{
                  margin: "8px",
                  cursor: "pointer",
                }}
                onClick={() => setActiveTab(t.id)}
              >
                <span style={{ fontSize: "15px" }} className="mr-2 fa">
                  Tab: {t.title}
                </span>
              </span>
              <hr />
              {t.sections.map((s, j) => (
                <span
                  className="ml-2"
                  style={{
                    margin: "10px",
                  }}
                  key={j}
                >
                  <span style={{ fontSize: "15px" }} className="mr-2 fa">
                    Section: {s.title}
                  </span>
                  <br />
                  {s.fields.map((f, k) => (
                    <span className="ml-4" key={k}>
                      {f.title}
                      <br />
                    </span>
                  ))}
                </span>
              ))}
            </span>
          ))}
          {props.state.requiredFields.includes("terms") && (
            <span className="ml-2">
              Terms and conditions
              <br />
            </span>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RequiredFields;
