/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";

class UserProfile extends React.Component {
  render() {
    const { user } = this.props;
    return (
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--user"
        drop="down"
        alignRight
      >
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          <div className="kt-header__topbar-user">
            <span className="mr-2">
              <img
                alt="Pic"
                src={toAbsoluteUrl("/media/logos/user-icon.png")}
              />
            </span>

            <span className="kt-header__topbar-username kt-hidden-mobile">
              {user.fullName
                ? user.fullName + " (" + user.role.title + ")"
                : ""}
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-fit dropdown-menu-fit dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          <div
            className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`,
            }}
          >
            <h6>
              {`${user.fullName} (${user.role.title})`}
              <br />
              Type: {user.type}
              <br />
              Company: {user.company.name}
            </h6>
          </div>
          <div className="kt-notification">
            {user.profile && (
              <span className="kt-notification__item">
                <div className="kt-notification__item-icon">
                  <i className="flaticon2-calendar-3 kt-font-success" />
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    <Link to={user.profile} style={{ cursor: "pointer" }}>
                      My Profile
                    </Link>
                  </div>
                </div>
              </span>
            )}
            {user.cprofile && (
              <span className="kt-notification__item">
                <div className="kt-notification__item-icon">
                  <i className="flaticon2-calendar-3 kt-font-success" />
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    <Link to={user.cprofile} style={{ cursor: "pointer" }}>
                      Company Profile
                      <br />
                    </Link>
                  </div>
                  <div className="kt-notification__item-time">
                    Account settings and more
                  </div>
                </div>
              </span>
            )}
            <div className="kt-notification__custom">
              <Link
                to="/logout"
                style={{ color: "#fff" }}
                className="btn bg-danger btn-label-brand btn-sm btn-bold"
              >
                Sign Out
              </Link>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(UserProfile);
