import React, { useState, /*useEffect*/ } from "react";
import axios from "axios";
import { Grid } from "@material-ui/core";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { Spinner } from "react-bootstrap";

import { backendApiUrl } from "../utils";
import Message from "../utils/Message";
import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../partials/content/Portlet";

const SummaryDocs = (props) => {
    const [state, setState] = useState({
        load: false,
        msg: {},
    });

    const setMsg = (m) => {
        let st = { ...state };
        st.msg = m;
        setState(st);
    };
    /*
        useEffect(() => {
            if (state.load) {
                axios
                    .post(
                        backendApiUrl("reports/modules"),
                        {},
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    )
                    .then((res) => {
                        let rd = res.data;
                        let st = { ...state };
                        st.load = false;
                        st.status = rd.status;
                        st.modules = rd.modules;
                        setState(st);
                    })
                    .catch((err) => {
                        let st = { ...state };
                        st.load = false;
                        setState(st);
                    });
            }
        }, [state]);
    
    */
    // Submit data to API
    const viewSummaryDocsHandler = async (mi) => {

        let st = { ...state };
        st.load = true;
        setState(st);

        let data = { ...props.MI.dynamicFieldData };
        data.form_id = props.MI.form._id;
        data.companyId = props.MI.form.company;
        data.doctype = mi;
        await axios
            .post(
                backendApiUrl("docs/summary_docs"),
                { data, hideElements: props.MI.form.hideSectionsAndFields },
                {
                    responseType: "blob",
                }
            )
            .then(async (res) => {
                if (mi === "csv") {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    //Build a URL from the file
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", props.MI.dynamicFieldData.first_name + "_Summary.csv");
                    document.body.appendChild(link);
                    link.click();

                } else {
                    const blob = new Blob([res.data], { type: 'application/pdf' });
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = props.MI.dynamicFieldData.first_name + '_Summary.pdf'
                    link.click();
                }

                let st = { ...state };
                st.load = false;
                setState(st);

            })
            .catch((err) => {
                console.log("Something went wrong, unable to view Summary Document.");
            });
    };
    return (
        <>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Portlet style={{ marginTop: "20px" }}>
                        <PortletHeader title="Summary Document" />
                        <PortletBody>
                            {state.msg && state.msg.show && (
                                <Message
                                    status={state.msg.status}
                                    setMsg={setMsg}
                                    msg={state.msg.msg}
                                />
                            )}
                            {state.load ? (
                                <div style={{ textAlign: "center" }}>
                                    <Spinner animation="border" variant="primary" />
                                </div>
                            ) : (
                                <>

                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th nowrap="true">Title</th>
                                                    <th nowrap="true">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Summary Document</td>
                                                    <td>
                                                        {/*<Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={viewSummaryDocsHandler}
                                                    >
                                                        Download Document
                            </Button>*/}

                                                        <i className="fa fa-file-pdf fa-lg text-primary mr-2 ml-2" aria-hidden="true" onClick={() =>
                                                            viewSummaryDocsHandler("pdf")
                                                        }
                                                            title="Click to Download the PDF Summary Document" ></i>
                                                        <i
                                                            className="fa fa-file-excel fa-lg text-primary mr-2 ml-2" aria-hidden="true" onClick={() =>
                                                                viewSummaryDocsHandler("csv")
                                                            }
                                                            title="Click to Download the Excell Summary Document"
                                                        ></i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
                        </PortletBody>
                    </Portlet>
                </Grid>
            </Grid>
        </>
    );
};

export default SummaryDocs;
