/**
 * Date : 10-06-2023
 * Task : Market Place
 * Company: FinLend, LLC
 * Requested By : Rajesh
 */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Spinner, Modal, Button } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import FormElements from "../../form_elements";
import { Grid } from "@material-ui/core";
import ComposeEmail from "../../services/email/Compose";
import Message from "../../utils/Message";

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../partials/content/Portlet";
import { backendApiUrl, getKeyValue } from "../../utils";

const MarketPlace = ({ info, events }) => {
  const [state, setState] = useState({
    load: true,
    records: [],
  });
  const [lenderId, setlenderId] = useState("");
  const [lenderStatus, setLenderStatus] = useState("");
  const [lenderNotes, setLenderNotes] = useState("");
  const [msg, setMsg] = useState({});
  const [actionInfo, setActionInfo] = useState({});
  const lenderStatusOption = [
    "Application Sent",
    "Offer Received",
    "Accepted",
    "Rejected",
  ];

  const lenderStatusChangeHandler = (e) => {
    let st = { ...state };
    st.lender_status = e.target.value;
    setState(st);
  };
  const lenderNotesChangeHandler = (e) => {
    let st = { ...state };
    st.lender_notes = e.target.value;
    setState(st);
  };
  const lenderFields = [
    "property_type",
    "loan_program",
    "loan_amount",
    "property_state",
    "credit_score",
    "ltv",
    "down_payment",
  ];

  const composeEmailClickHandler = (opp, from) => {
    let data = { from, openCompose: true };
    data.owner = opp._id;
    let label = "";
    if (!!opp.prospect.info.first_name) label = opp.prospect.info.first_name;
    if (!!opp.prospect.info.last_name)
      label += " " + opp.prospect.info.last_name;
    label += " (" + opp.prospect.info.email + ")";
    data.from = label;

    data.recipients = [
      {
        label,
        email: opp.prospect.info.email,
        value: opp._id,
        type: "Opportunities",
      },
    ];
    data.url = backendApiUrl("emails/populate_tags");

    setActionInfo(data);
  };
  const getLenderStatus = (lender) => {
    let st = { ...state };
    if (
      info.opportunity.lender_details &&
      info.opportunity.lender_details[lender._id] &&
      info.opportunity.lender_details[lender._id].status !== ""
    ) {
      st.lender_status = info.opportunity.lender_details[lender._id].status;
    }
    setState(st);
    setLenderStatus(lender);
  };
  const submitLenderStatus = async (lender) => {
    let data = {};
    if (info.MDID) data.opp_id = info.MDID;
    if (lender._id) data.lenderId = lender._id;
    if (state.lender_status) data.value = state.lender_status;
    if (state.lender_status) data.field = "status";
    await axios
      .post(backendApiUrl("opportunities/lenderDetails"), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => {
        setMsg({
          status: 200,
          msg: "Status Saved Successfully",
          show: true,
        });
        setLenderStatus("");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        setMsg({
          status: 500,
          msg: "Something went wrong, unable to change status.",
          show: true,
        });
      });
  };
  const submitLenderNotes = async (lender) => {
    let data = {};
    if (info.MDID) data.opp_id = info.MDID;
    if (lender._id) data.lenderId = lender._id;
    if (state.lender_notes) data.value = state.lender_notes;
    if (state.lender_notes) data.field = "notes";
    await axios
      .post(backendApiUrl("opportunities/lenderDetails"), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => {
        setMsg({
          status: 200,
          msg: "Notes Saved Successfully",
          show: true,
        });
        setLenderNotes("");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        setMsg({
          status: 500,
          msg: "Something went wrong, unable to create notes.",
          show: true,
        });
      });
  };
  let form_fields = [];
  const getModuleFields = (configs) => {
    configs.forEach((config) => {
      if (lenderFields.includes(config.id)) {
        form_fields = [...form_fields, config];
      } else {
        if (Array.isArray(config.childs)) getModuleFields(config.childs);
      }
    });
  };
  getModuleFields(info.form.content);
  useEffect(() => {
    if (state.load) {
      axios
        .post(
          backendApiUrl("opportunities/lenders"),
          { loan: info.MDID },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let st = { ...state, load: false };
          if (res.data) st.records = res.data;
          setState(st);
          console.log(res.data);
        });
    }
  }, [state, info]);

  return (
    <Portlet>
      {msg.show && (
        <Message status={msg.status} setMsg={setMsg} msg={msg.msg} />
      )}
      <PortletHeader
        title="Lenders"
        toolbar={<PortletHeaderToolbar></PortletHeaderToolbar>}
      />
      <PortletBody>
        {state.load ? (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="table-responsive">
            <div className="row">
              {form_fields.map((element, i) => (
                <div className="col-md-4">
                  <FormElements
                    element={element}
                    module={info}
                    events={events}
                    key={i}
                  />
                </div>
              ))}
            </div>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th nowrap="true">Lender Company</th>
                  <th nowrap="true">Property Type</th>
                  <th nowrap="true">Loan Programs</th>
                  <th nowrap="true">Min Loan Amount</th>
                  <th nowrap="true">Max Loan Amount</th>
                  <th nowrap="true">State We Lend In</th>
                  <th nowrap="true">Min Credit Score Range</th>
                  <th nowrap="true">Max LTV</th>
                  <th nowrap="true">Min. Down Payment Required</th>
                  <th nowrap="true">Actions</th>
                </tr>
              </thead>
              <tbody>
                {state.records.length > 0 ? (
                  state.records.map((r, i) => (
                    <tr>
                      <th>{i + 1}</th>
                      <td>
                        <Link to={"/contacts/edit/" + r._id}>
                          {getKeyValue(r.info, "first_name")}
                        </Link>
                      </td>
                      <td>
                        {getKeyValue(r.info, "property_types") &&
                          getKeyValue(r.info, "property_types").map((pt) => (
                            <span>
                              {pt.label}
                              <br />
                            </span>
                          ))}
                      </td>
                      <td>
                        {getKeyValue(r.info, "loan_programs") &&
                          getKeyValue(r.info, "loan_programs").map((pt) => (
                            <span>
                              {pt.label}
                              <br />
                            </span>
                          ))}
                      </td>
                      <td>{getKeyValue(r.info, "min_loan_amount")}</td>
                      <td>{getKeyValue(r.info, "max_loan_amount")}</td>
                      <td>
                        {getKeyValue(r.info, "state_we_lend_in") &&
                          getKeyValue(r.info, "state_we_lend_in").map((pt) => (
                            <span>
                              {pt.label}
                              <br />
                            </span>
                          ))}
                      </td>
                      <td>{getKeyValue(r.info, "min_credit_score_range")}</td>
                      <td>{getKeyValue(r.info, "max_ltv")}</td>
                      <td>{getKeyValue(r.info, "min_down_payment")}</td>
                      <td nowrap="true">
                        <span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>Click here to change the status</Tooltip>
                            }
                          >
                            <i
                              className="fa fa-tag fa-lg mr-2"
                              aria-hidden="true"
                              onClick={() => getLenderStatus(r)}
                            />
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>Click here to Add the Notes</Tooltip>
                            }
                          >
                            <i
                              className="fa fa-sticky-note fa-lg mr-2"
                              aria-hidden="true"
                              onClick={() => setLenderNotes(r)}
                            />
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>Click here to send the email</Tooltip>
                            }
                          >
                            <i
                              className="fa fa-envelope fa-lg text-warning fa-lg mr-2"
                              aria-hidden="true"
                              onClick={() =>
                                composeEmailClickHandler(
                                  info.opportunity,
                                  "Email"
                                )
                              }
                            />
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                Click here to view the contact details
                              </Tooltip>
                            }
                          >
                            <i
                              className="fa fa-tasks fa-lg mr-2"
                              aria-hidden="true"
                              onClick={() => setlenderId(r)}
                            />
                          </OverlayTrigger>
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <h5 className="text-primary text-center">
                        No lenders available!
                      </h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </PortletBody>

      {lenderId && (
        <Modal
          show={true}
          size="md"
          onHide={() => setlenderId("")}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Call to Action </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table>
              <tbody>
                <tr>
                  <th>
                    Lender Company :{" "}
                    {lenderId.info.first_name + " " + lenderId.info.last_name}
                  </th>
                </tr>
                <tr>
                  <th>
                    Email :{" "}
                    {lenderId.info.email !== undefined
                      ? lenderId.info.email
                      : ""}
                  </th>
                </tr>
                <tr>
                  <th>
                    Phone :{" "}
                    {lenderId.info.phone !== undefined
                      ? lenderId.info.phone
                      : ""}
                  </th>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setlenderId("")}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {lenderNotes && (
        <Modal
          show={true}
          size="lg"
          onHide={() => setLenderNotes("")}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Notes </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <div className="form-group">
                  <div className="col-md-12">
                    <label className="form-label" htmlFor="content">
                      Notes
                    </label>
                  </div>
                  <textarea
                    name="lender_notes"
                    id="lender_notes"
                    onChange={lenderNotesChangeHandler}
                    value={state.lender_notes ? state.lender_notes : ""}
                    className="form-control"
                  />
                </div>
              </Grid>
              <Grid item md={1}>
                <button
                  className="btn btn-primary btn-sm"
                  style={{ marginTop: "25px" }}
                  onClick={() => submitLenderNotes(lenderNotes)}
                >
                  Save
                </button>
              </Grid>
            </Grid>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th nowrap="true" width="60%">
                    Notes
                  </th>
                </tr>
              </thead>
              <tbody>
                {info.opportunity.lender_details &&
                info.opportunity.lender_details[lenderNotes._id] &&
                info.opportunity.lender_details[lenderNotes._id].notes.length >
                  0 ? (
                  info.opportunity.lender_details[lenderNotes._id].notes.map(
                    (n, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{n}</td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <h5 className="text-primary text-center">
                        No notes available, create one!
                      </h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setLenderNotes("")}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {lenderStatus && (
        <Modal
          show={lenderStatus}
          size="md"
          onHide={() => setLenderStatus("")}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <select
                  className="form-control"
                  value={state.lender_status}
                  onChange={lenderStatusChangeHandler}
                >
                  <option value="" name="disposition_status">
                    Please select Status
                  </option>
                  {lenderStatusOption.map((w, i) => (
                    <option value={w} key={i}>
                      {w}
                    </option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={2}>
                {!!state.lender_status && (
                  <Button
                    className="btn btn-sm btn-primary mt-2"
                    variant="secondary"
                    onClick={() => submitLenderStatus(lenderStatus)}
                  >
                    Save
                  </Button>
                )}
              </Grid>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setLenderStatus("")}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {actionInfo.openCompose === true && (
        <ComposeEmail
          info={actionInfo}
          module="Opportunities"
          //filters={filters}
          actions={{ setMsg, setActionInfo }}
        />
      )}
    </Portlet>
  );
};

export default MarketPlace;
